import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import 'theme/global.css';

import { RouterProvider } from 'react-router-dom';
import { router } from './browserRouter';
import PageLoading from './components/PageLoading';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <RouterProvider router={router} fallbackElement={<PageLoading />} />
);
