import API from '../api';
import cleanApi from '../services/axiosClient';

export function getWorkspaces() {
  return cleanApi.get(`/workspaces?get_counts=true&get_users=true`);
}

export function createWorkspaces(payload) {
  return API.post(
    `/workspaces?get_counts=true&get_users=true&pivot=true`,
    payload
  );
}

export function deleteWorkspaces(id) {
  return API.trash(`/workspaces/${id}`);
}

export function editWorkspaces(id, payload) {
  return API.post(`/workspaces/patch/${id}`, payload);
}

// export function editWorkspaces(id, payload) {
//     return API.put(`/workspaces/${id}`, payload);
// }

export function getWorkspaceDetails(id) {
  return API.get(`/workspaces/${id}`);
}

export function getAccountUsers(pageNo, projectId) {
  return API.get(
    `/user/contributors?project_id=${projectId}&page_no=${pageNo}&limit=20`
  );
}

export function getNextAccountUsers(pageNo, limit, projectId) {
  return API.get(
    `/user/contributors?project_id=${projectId}&page_no=${pageNo}&limit=${20}`
  );
}

export function storeWorkspace(workspaceId) {
  return API.post(`/workspaces/change`, workspaceId);
}

export function WorkspaceOwners() {
  return API.get(`/workspaces/owners`);
}
