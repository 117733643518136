import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_FORM_SUBMISSION,
  DELETE_FORM,
  ERROR_ADD_FORM_SUBMISSION,
  ERROR_DELETE_FORM,
  ERROR_GET_ALL_FORMS,
  ERROR_GET_ALL_FORM_TEMPLATES,
  ERROR_GET_FORM_SUBMISSIONS,
  ERROR_GET_SINGLE_FORM,
  ERROR_SAVE_FORM,
  ERROR_SAVE_TEMPLATE,
  ERROR_SEARCH_FORMS,
  ERROR_UPDATE_FORM,
  ERROR_UPDATE_FORM_STATUS,
  GET_ALL_FORMS,
  GET_ALL_FORM_TEMPLATES,
  GET_FORM_SUBMISSIONS,
  GET_SINGLE_FORM,
  SAVE_FORM,
  SAVE_TEMPLATE,
  START_SEARCH_FORMS,
  SUCCESS_ADD_FORM_SUBMISSION,
  SUCCESS_DELETE_FORM,
  SUCCESS_GET_ALL_FORMS,
  SUCCESS_GET_ALL_FORM_TEMPLATES,
  SUCCESS_GET_FORM_SUBMISSIONS,
  SUCCESS_GET_SINGLE_FORM,
  SUCCESS_SAVE_FORM,
  SUCCESS_SAVE_TEMPLATE,
  SUCCESS_SEARCH_FORMS,
  SUCCESS_UPDATE_FORM,
  SUCCESS_UPDATE_FORM_STATUS,
  UPDATE_FORM,
  UPDATE_FORM_STATUS,
} from 'actions/discovery_flow';
import {
  createForm,
  createFormTemplate,
  destroyForm,
  fetchAllFormTemplates,
  fetchAllForms,
  fetchFormSubmission,
  fetchSingleForm,
  searchFormFunction,
  submitForm,
  updateForm,
  updateFormStatus,
} from '../api/discovery_flow';
// Utils
import { getSelectedWorkspace } from 'utils/functions';

// GET ALL FORMS
function* getAllForms() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(fetchAllForms, selectedWorkspace?.id);
  if (data.status) {
    yield put({
      type: SUCCESS_GET_ALL_FORMS,
      data: data.data,
    });
  } else {
    yield put({
      type: ERROR_GET_ALL_FORMS,
    });
  }
}

export function* fetchAllFormsWatcher() {
  yield takeEvery(GET_ALL_FORMS, getAllForms);
}

// GET SINGLE FORM
function* getSingleForm(body) {
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const data = yield call(fetchSingleForm, body, selectedWorkspace?.id);
  if (data.status) {
    yield put({
      type: SUCCESS_GET_SINGLE_FORM,
      data: data.data,
    });
  } else {
    yield put({
      type: ERROR_GET_SINGLE_FORM,
    });
  }
}

export function* fetchSingleFormWatcher() {
  yield takeEvery(GET_SINGLE_FORM, getSingleForm);
}

//SEARCH FORM
function* searchForm(body) {
  const data = yield call(searchFormFunction, body.data);
  if (data.status) {
    yield put({
      type: SUCCESS_SEARCH_FORMS,
      data: data.result,
    });
  } else {
    yield put({ type: ERROR_SEARCH_FORMS });
  }
}

export function* searchFormWatcher() {
  yield takeEvery(START_SEARCH_FORMS, searchForm);
}

// GET ALL FORM TEMPLATES
function* getAllFormTemplates() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(fetchAllFormTemplates, selectedWorkspace?.id);
  if (data.status) {
    yield put({
      type: SUCCESS_GET_ALL_FORM_TEMPLATES,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_GET_ALL_FORM_TEMPLATES });
  }
}

export function* fetchAllFormTemplatesWatcher() {
  yield takeEvery(GET_ALL_FORM_TEMPLATES, getAllFormTemplates);
}

function* saveForm(body) {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(createForm, body.data, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_SAVE_FORM,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_SAVE_FORM });
  }
}

export function* saveFormWatcher() {
  yield takeEvery(SAVE_FORM, saveForm);
}

//SAVE TEMPLATE
function* saveFormTemplate(body) {
  const data = yield call(createFormTemplate, body.data);
  if (data.status) {
    yield put({
      type: SUCCESS_SAVE_TEMPLATE,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_SAVE_TEMPLATE });
  }
}

export function* saveFormTemplateWatcher() {
  yield takeEvery(SAVE_TEMPLATE, saveFormTemplate);
}

function* editForm(body) {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(updateForm, body.data, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_FORM,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_UPDATE_FORM });
  }
}

export function* editFormWatcher() {
  yield takeEvery(UPDATE_FORM, editForm);
}

//UPDATE FORM STATUS
function* editFormStatus(body) {
  const data = yield call(updateFormStatus, body.data);
  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_FORM_STATUS,
      data: body.data,
    });
  } else {
    yield put({ type: ERROR_UPDATE_FORM_STATUS });
  }
}

export function* editFormStatusWatcher() {
  yield takeEvery(UPDATE_FORM_STATUS, editFormStatus);
}

//ADD SUBMISSION
function* addFormSubmission(body) {
  const data = yield call(submitForm, body.data);
  if (data.status) {
    yield put({
      type: SUCCESS_ADD_FORM_SUBMISSION,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_ADD_FORM_SUBMISSION });
  }
}

export function* addFormSubmissionWatcher() {
  yield takeEvery(ADD_FORM_SUBMISSION, addFormSubmission);
}

// GET FORM SUBMISSION
function* getFormSubmission(body) {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchFormSubmission,
    body.data,
    selectedWorkspace?.id
  );
  if (data.status) {
    yield put({
      type: SUCCESS_GET_FORM_SUBMISSIONS,
      data: data.data,
    });
  } else {
    yield put({ type: ERROR_GET_FORM_SUBMISSIONS });
  }
}

export function* getFormSubmissionWatcher() {
  yield takeEvery(GET_FORM_SUBMISSIONS, getFormSubmission);
}

//DELETE FORM
function* deleteForm(body) {
  const data = yield call(destroyForm, body.data);
  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_FORM,
      data: body.data,
    });
  } else {
    yield put({ type: ERROR_DELETE_FORM });
  }
}

export function* deleteFormWatcher() {
  yield takeEvery(DELETE_FORM, deleteForm);
}
