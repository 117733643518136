import { useState } from 'react';
import { useProductFruitsApi } from 'react-product-fruits';
import useChecklistInjection from './use-checklist-injection';

const useInviteChecklist = () => {
  const [api, setApi] = useState(null);
  const [isChecklistVisible, setIsChecklistVisible] = useState<boolean>(false);

  const { checklistRef, progress, markItemAsDone } = useChecklistInjection({
    checklistId: 2560,
    isChecklistVisible,
    api,
  });

  useProductFruitsApi((receivedApi) => {
    setApi(receivedApi);
  }, []);

  return {
    checklistRef,
    progress,
    markItemAsDone,
    api,
  };
};
export default useInviteChecklist;
