import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Timer {
  taskId: string;
  isRunning: boolean;
  startTime: number | null;
  elapsedTime: number;
  timeId: number | null;
}

export interface TimerState {
  timers: Timer[];
}

const initialState: TimerState = {
  timers: [],
};

const timerSlice = createSlice({
  name: 'time_entries_new',
  initialState,
  reducers: {
    startTimer: (
      state,
      action: PayloadAction<{
        taskId: string;
        initialElapsedTime: number;
        isRunning: boolean;
        timeId: number | null;
      }>
    ) => {
      const { taskId, initialElapsedTime, isRunning, timeId } = action.payload;
      const existingTimerIndex = state.timers.findIndex(
        (timer) => timer.taskId === taskId
      );

      if (existingTimerIndex !== -1) {
        state.timers[existingTimerIndex] = {
          ...state.timers[existingTimerIndex],
          isRunning,
          startTime: Date.now(),
          elapsedTime: initialElapsedTime,
          timeId,
        };
      } else {
        state.timers.push({
          taskId,
          isRunning,
          startTime: Date.now(),
          elapsedTime: initialElapsedTime,
          timeId,
        });
      }
    },
    stopTimer: (state, action: PayloadAction<number>) => {
      const timerIndex = state.timers.findIndex(
        (timer) => timer.timeId === action.payload
      );
      if (timerIndex !== -1) {
        state.timers[timerIndex].isRunning = false;
        state.timers[timerIndex].startTime = null;
      }
    },
    updateElapsedTime: (state, action: PayloadAction<number>) => {
      const timerIndex = state.timers.findIndex(
        (timer) => timer.timeId === action.payload
      );
      if (
        timerIndex !== -1 &&
        state.timers[timerIndex].isRunning &&
        state.timers[timerIndex].startTime
      ) {
        const now = Date.now();
        state.timers[timerIndex].elapsedTime +=
          (now - state.timers[timerIndex].startTime) / 1000;
        state.timers[timerIndex].startTime = now;
      }
    },
    removeTimer: (state, action: PayloadAction<number>) => {
      state.timers = state.timers.filter(
        (timer) => timer.timeId !== action.payload
      );
    },
  },
});

export const { startTimer, stopTimer, updateElapsedTime, removeTimer } =
  timerSlice.actions;
export default timerSlice.reducer;
