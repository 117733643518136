// Saga Utils
import { all, cancel, fork, take } from 'redux-saga/effects';
// Watchers
import {
  addProjectToUserWatcher,
  addSiteToUserWatcher,
  deleteUserWatcher,
  fetchAllUsersListWatcher,
  fetchFeedsWatcher,
  fetchInvitedUserWatcher,
  fetchNextUsersListWatcher,
  fetchProjectUserWatcher,
  fetchSiteListUserWatcher,
  inviteUserWatcher,
  newFetchSiteListUserWatcher,
  removeInvitedTeamWatcher,
  startReInvitationUserWatcher,
  updateUserNotificationWatcher,
  updateUserProfileWatcher,
  uploadAvatarWatcher,
} from './ActivitySagas';
import {
  deleteBoardCommentFilesWatcher,
  deleteTaskInBoardWatcher,
  fetchAllBoardTasksWatcher,
  fetchBoardColumnWatcher,
  fetchNextBoardTaskStatusWatcher,
  fetchNextBoardTaskUrgencyWatcher,
  manageBoardTaskFlow,
  saveNewBoardTaskWatcher,
  // startUpdateSiteOrderWatcher,
  searchBoardTasksWatcher,
  updateBoardTaskSagas,
  updateBoardTasksOrderWatcher,
  updateTaskAttributesBoardSagas,
} from './BoardSagas';
import {
  addGraphicVersionWatcher,
  addNewCustomSiteWatcher,
  addNewSiteWatcher,
  addPageWatcher,
  addStandardFlowSiteWatcher,
  approvePageWatcher,
  attachUploadFileWatcher,
  commentEditHistoryWatcher,
  countOpenTaskWatcher,
  createAnnotatedTaskWatcher,
  createGraphicTaskWatcher,
  createHotspotWatcher,
  createSiteTaskWatcher,
  createTaskCommentWatcher,
  deleteCollaborateCommentWatcher,
  deleteCommentFileWatcher,
  deleteDesignWatcher,
  deleteHotspotWatcher,
  deletePageWatcher,
  // inviteUsersWatcher,
  deleteTaskWatcher,
  deleteVersionWatcher,
  editCollaborateCommentWatcher,
  extensionMetricsWatcher,
  fetchDummyTasksWatcher,
  fetchInvitedCollabUserWatcher,
  fetchNextSiteTasksWatcher,
  fetchNextThisPageTaskWatcher,
  fetchPagesWatcher,
  fetchTasksCollaborateAttributeSaga,
  getGraphicThisPageTaskWatcher,
  getGraphicVersionsWatcher,
  getItemDetailsWatcher,
  getSiteTasksWatcher,
  getSiteThisPageTaskWatcher,
  inviteCollabWatcher,
  reinvitedCollabUserWatcher,
  removeInvitedCollabUserWatcher,
  updateGodaddyWatcher,
  updateGraphicTitleWatcher,
  updateHotspotWatcher,
  updateSiteURLWatcher,
  updateStatusAttributeWatcher,
  upgradeAccountWatcher,
  userRegistrationWatcher,
  verifyCollabCodeWatcher,
} from './CollaborateSagas';
import {
  activateArchivedSiteWatcher,
  addGraphicSiteWatcher,
  addMilestoneWatcher,
  archivedSitesWatcher,
  completeMilestoneWatcher,
  createInstawpSiteWatcher,
  createRocketSiteWatcher,
  deleteMilestoneWatcher,
  deleteSiteWatcher,
  fetchContributorsListWatcher,
  fetchDestinationSiteWatcher,
  fetchMilestonesWatcher,
  fetchNextDestinationSiteWatcher,
  fetchNextProjectSitesWatcher,
  fetchNextSourceSiteWatcher,
  fetchNextWebWatcher,
  fetchProjectsBasedSitesWatcher,
  fetchProjectsForExportWatcher,
  fetchRocketLocationWatcher,
  fetchSiteSettingsWatcher,
  fetchSourceSiteWatcher,
  mergeWebsiteWatcher,
  // sortProjectsBasedSitesWatcher,
  nextArchivedSitesWatcher,
  rootDashboard,
  searchArchivedWatcher,
  softDeleteSiteWatcher,
  syncSiteImageWatcher,
  updateContributorStatusWatcher,
  updateMilestoneWatcher,
  updateSiteSettingsWatcher,
} from './DashboardSagas';
import {
  addFormSubmissionWatcher,
  deleteFormWatcher,
  editFormStatusWatcher,
  editFormWatcher,
  fetchAllFormTemplatesWatcher,
  fetchAllFormsWatcher,
  fetchSingleFormWatcher,
  getFormSubmissionWatcher,
  saveFormTemplateWatcher,
  saveFormWatcher,
  searchFormWatcher,
} from './DiscoveryFlowSagas';
import {
  assignEmailToSiteWatcher,
  composeEmailWatcher,
  fetchBoardEmailColumnWatcher,
  fetchEmailsWatcher,
  fetchNextEmailTaskForBoardWatcher,
  fetchNextEmailTasksWatcher,
  replyEmailWatcher,
  verifyMailboxWatcher,
} from './EmailSagas';
import {
  asanaGetProjectsSettingsWatcher,
  asanaGetSectionSettingWatcher,
  asanaGetTeamWatcher,
  asanaGetWorkspacesWatcher,
  asanaStoreTeamsWatcher,
  asanaStoreWorkspaceWatcher,
  basecampGetProjectsSettingsWatcher,
  basecampGetTaskSettingWatcher,
  changeAutomaticSettingWatcher,
  changeCustomWebhookWatcher,
  changeInstaWpSettingWatcher,
  changeIntegrationSettingWatcher,
  changeMainWpSettingWatcher,
  changeMondaySettingWatcher,
  changeSlackSettingWatcher,
  changeWebhookStatusWatcher,
  clickupCompleteAuthWatcher,
  clickupGetFoldersSettingsWatcher,
  clickupGetTaskListSettingWatcher,
  clickupGetTeamsWatcher,
  clickupGetWorkspacesWatcher,
  clickupStoreTeamWatcher,
  clickupStoreWorkspaceWatcher,
  connectRocketWatcher,
  createSnippetTemplateWatcher,
  createSnippetWatcher,
  deleteSnippetWatcher,
  fetchAllSnippetTemplateWatcher,
  fetchAllSnippetWatcher,
  fetchGlobalSettingsWatcher,
  fetchSnippetDetailWatcher,
  importSnippetTemplateWatcher,
  jiraGetProjectsSettingsWatcher,
  jiraGetWorkspacesWatcher,
  jiraStoreWebsitesWatcher,
  mondayGetBoardsSettingWatcher,
  mondayGetGroupWorkerWatcher,
  mondayGetWorkspacesWatcher,
  mondayStoreWorkspaceWatcher,
  refreshWebhookTokenWatcher,
  resetSettingsWatcher,
  storeIntegrationRequirementWatcher,
  teamworkGetProjectsSettingsWatcher,
  teamworkGetTaskListSettingWatcher,
  trelloGetListSettingWatcher,
  trelloGetProjectsSettingsWatcher,
  updateGlobalSettingsWatcher,
  updateSnippetWatcher,
  validateEmailWatcher,
  verifyDomainWatcher,
} from './GlobalSettingsSagas';
import { fetchHomeDataWatcher, fetchHomeDataWeeksWatcher } from './HomeSagas';
import {
  createProjectWatcher,
  deleteProjectWatcher,
  fetchProjectsWatcher,
  updateProjectWatcher,
} from './ProjectSagas';
import {
  asanaCreateTaskWatcher,
  asanaDeployTasksWatcher,
  asanaGetProjectsWatcher,
  asanaGetSectionWatcher,
  basecampCreateTaskWatcher,
  basecampDeployTasksWatcher,
  basecampGetProjectsWatcher,
  basecampGetTodoWatcher,
  changeAutomationStatusWatcher,
  clickupCreateTaskWatcher,
  clickupDeployTasksWatcher,
  clickupGetFoldersWatcher,
  clickupGetTaskListWatcher,
  clickupGetTasksListWatcher,
  createAutomationTemplateWatcher,
  createCustomAutomationWatcher,
  deleteCommentFilesWatcher,
  deleteCommentWatcher,
  deleteS3Watcher,
  deleteTaskSaga,
  deleteWorkflowWatcher,
  editCommentWatcher,
  editCustomAutomationWatcher,
  exportTaskWatcher,
  fetchAllTaskSnippetWatcher,
  fetchAllTasksFilterWatcher,
  fetchAllTasksWatcher,
  fetchAssignProjectsWatcher,
  fetchAutomationActionWatcher,
  fetchAutomationConditionWatcher,
  fetchAutomationDetailWatcher,
  fetchAutomationTagWatcher,
  fetchAutomationTemplateDetailWatcher,
  fetchAutomationTemplateWatcher,
  fetchAutomationWorkflowWatcher,
  fetchCreateNewTaskDetailWatcher,
  fetchNextAssignProjectsWatcher,
  fetchNextTasksWatcher,
  fetchTagsSuggestionWatcher,
  fetchTaskSnippetDetailWatcher,
  fetchTasksAttributeSaga,
  fetchTriggersWatcher,
  jiraCreateTaskWatcher,
  jiraDeployTasksWatcher,
  jiraGetProjectsWatcher,
  // selectDefaultTask,
  manageTaskFlow,
  mondayCreateTaskWatcher,
  mondayDeployTasksWatcher,
  mondayGetBoardsWatcher,
  mondayGetGroupListWatcher,
  saveNewTaskWatcher,
  searchTasksCategoryWatcher,
  searchTasksWatcher,
  sendMessageSaga,
  sendMessageWithoutPostWatcher,
  sendToMediaWatcher,
  startFilteringWatcher,
  teamWorkCreateTaskWatcher,
  teamWorkDeployTasksWatcher,
  teamWorkGetProjectsWatcher,
  teamWorkGetTaskListWatcher,
  teamWorkGetTaskWatcher,
  trelloCreateTaskWatcher,
  trelloDeployTasksWatcher,
  trelloGetBoardsWatcher,
  trelloGetTaskListWatcher,
  updateEstimatedTimeWatcher,
  updateInternalTaskWathcer,
  updateNewlyArrivedUserWatcher,
  updateTaskAttributesSagas,
  uploadFileWatcher,
} from './TasksSagas';
import {
  addNewEntryWatcher,
  clickCreateTimeEntriesWatcher,
  clickupCreateTimeEntryWatcher,
  clickupGetTasksWatcher,
  customDateFilterWatcher,
  deleteEntryWatcher,
  editEntryWatcher,
  fetchAllEntriesWatcher,
  fetchNextEntriesWatcher,
  fetchNextFilteringWatcher,
  filteringWatcher,
  nextCustomDateFilteringWatcher,
  notLoggedEntriesWatcher,
  taskFilterWatcher,
  teamWorkCreateTimeEntriesWatcher,
  teamWorkCreateTimeEntryWatcher,
  // timerspentTimeWatcher,
  updateNotLoggedWatcher,
} from './TimeEntriesSagas';
import {
  activateTrialWatcher,
  authenticateWatcher,
  checkAllNotificationsWatcher,
  closeWizardWatcher,
  confirmUserTwoFaWatcher,
  createPortalSessionWatcher,
  fetchExistingSiteUrlWatcher,
  fetchProfileData,
  forgotPassword,
  getBillingInfoWatcher,
  getFiltredNotificationsWatcher,
  getInvoicesWatcher,
  getNotificaionDetailsWatcher,
  getReadNotificationsWatcher,
  getSubscriptionDetailsWatcher,
  getUnreadNotificationsWatcher,
  getUpgradePlansWatcher,
  licenseActivationWatcher,
  muteUnmuteNotificationsWatcher,
  openCheckoutWatcher,
  portalSessionWatcher,
  postTrailWatcher,
  rejectTrialWatcher,
  // resetPasswordSaga,
  revokeAccessWatcher,
  sendTwoFactorWatcher,
  unReadNotificationsCountWatcher,
  updateNotificationWatcher,
  userChangePasswordWatcher,
  verifyCodeWatcher,
} from './UserSagas';
import {
  createWorkspaceWatcher,
  deleteWorkspaceWatcher,
  editWorkspacesWatcher,
  fetchAccountUsersWatcher,
  fetchNextAccountUsersWatcher,
  fetchWorkspaceOwnersWatcher,
  fetchWorkspacesDetailsWatcher,
  fetchWorkspacesWatcher,
  storeWorkspaceWatcher,
} from './WorkspaceSagas.js';
// Actions
import { LOGOUT } from 'actions/user';
// APIs

export default function* rootSaga() {
  while (true) {
    const allTasks = yield all([
      fork(rootDashboard),
      fork(fetchNextWebWatcher),
      // fork(resetPasswordSaga),
      fork(forgotPassword),
      fork(fetchProfileData),
      fork(manageTaskFlow),
      fork(fetchTasksAttributeSaga),
      fork(updateTaskAttributesSagas),
      fork(deleteTaskSaga),
      fork(sendMessageSaga),
      fork(startFilteringWatcher),
      fork(fetchAllTasksWatcher),
      fork(fetchAllTasksFilterWatcher),
      fork(updateEstimatedTimeWatcher),
      fork(fetchCreateNewTaskDetailWatcher),
      fork(saveNewTaskWatcher),
      fork(inviteUserWatcher),
      fork(fetchContributorsListWatcher),
      fork(updateContributorStatusWatcher),
      fork(fetchAllUsersListWatcher),
      fork(fetchNextUsersListWatcher),
      fork(updateUserNotificationWatcher),
      fork(updateNewlyArrivedUserWatcher),
      fork(fetchFeedsWatcher),
      fork(fetchSiteListUserWatcher),
      fork(addSiteToUserWatcher),
      fork(uploadAvatarWatcher),
      fork(updateUserProfileWatcher),
      fork(fetchNextTasksWatcher),
      fork(fetchGlobalSettingsWatcher),
      fork(updateGlobalSettingsWatcher),
      fork(resetSettingsWatcher),
      // fork(deleteSiteWatcher),
      fork(searchTasksWatcher),
      fork(updateGraphicTitleWatcher),
      fork(deleteUserWatcher),
      fork(fetchTagsSuggestionWatcher),
      fork(fetchAllBoardTasksWatcher),
      fork(updateBoardTaskSagas),
      fork(manageBoardTaskFlow),
      fork(updateTaskAttributesBoardSagas),
      fork(getGraphicVersionsWatcher),
      fork(deleteTaskInBoardWatcher),
      fork(startReInvitationUserWatcher),
      fork(saveNewBoardTaskWatcher),
      fork(searchBoardTasksWatcher),
      fork(searchTasksCategoryWatcher),
      fork(userChangePasswordWatcher),
      fork(softDeleteSiteWatcher),
      fork(updateBoardTasksOrderWatcher),
      fork(fetchNextEmailTaskForBoardWatcher),
      fork(exportTaskWatcher),
      fork(fetchHomeDataWatcher),
      fork(uploadFileWatcher),
      fork(addGraphicVersionWatcher),
      fork(deleteS3Watcher),
      fork(archivedSitesWatcher),
      fork(activateArchivedSiteWatcher),
      fork(syncSiteImageWatcher),
      fork(sendToMediaWatcher),
      fork(fetchEmailsWatcher),
      fork(fetchNextBoardTaskStatusWatcher),
      fork(verifyMailboxWatcher),
      fork(fetchAllEntriesWatcher),
      fork(filteringWatcher),
      fork(addNewEntryWatcher),
      fork(customDateFilterWatcher),
      fork(editEntryWatcher),
      fork(notLoggedEntriesWatcher),
      fork(deleteEntryWatcher),
      // fork(timerspentTimeWatcher),
      fork(updateNotLoggedWatcher),
      fork(taskFilterWatcher),
      fork(sendMessageWithoutPostWatcher),
      fork(refreshWebhookTokenWatcher),
      fork(changeWebhookStatusWatcher),
      fork(assignEmailToSiteWatcher),
      fork(replyEmailWatcher),
      fork(composeEmailWatcher),
      fork(verifyDomainWatcher),
      fork(updateInternalTaskWathcer),
      fork(createProjectWatcher),
      fork(fetchProjectsWatcher),
      fork(fetchProjectUserWatcher),
      fork(updateProjectWatcher),
      fork(addProjectToUserWatcher),
      fork(editCommentWatcher),
      fork(deleteCommentWatcher),
      // clickup integration
      fork(clickupCompleteAuthWatcher),
      fork(clickupGetTeamsWatcher),
      fork(clickupStoreTeamWatcher),
      fork(clickupGetWorkspacesWatcher),
      fork(clickupStoreWorkspaceWatcher),
      fork(clickupGetFoldersWatcher),
      fork(clickupGetTaskListWatcher),
      fork(clickupCreateTaskWatcher),
      fork(clickupCreateTimeEntryWatcher),
      fork(clickCreateTimeEntriesWatcher),
      fork(clickupDeployTasksWatcher),
      fork(clickupGetTasksWatcher),
      fork(fetchBoardEmailColumnWatcher),
      // open to all
      fork(addGraphicSiteWatcher),
      fork(fetchSiteSettingsWatcher),
      fork(updateSiteSettingsWatcher),
      fork(getItemDetailsWatcher),
      fork(fetchProjectsBasedSitesWatcher),
      fork(createSiteTaskWatcher),
      fork(createGraphicTaskWatcher),
      fork(getSiteTasksWatcher),
      fork(addNewSiteWatcher),
      fork(getSiteThisPageTaskWatcher),
      fork(getGraphicThisPageTaskWatcher),
      fork(createTaskCommentWatcher),
      fork(updateStatusAttributeWatcher),
      fork(attachUploadFileWatcher),
      fork(fetchTasksCollaborateAttributeSaga),
      fork(deleteTaskWatcher),
      fork(approvePageWatcher),
      fork(userRegistrationWatcher),
      fork(deleteCollaborateCommentWatcher),
      fork(changeCustomWebhookWatcher),
      fork(fetchNextThisPageTaskWatcher),
      fork(fetchNextSiteTasksWatcher),
      fork(fetchMilestonesWatcher),
      fork(addMilestoneWatcher),
      fork(completeMilestoneWatcher),
      fork(updateMilestoneWatcher),
      fork(changeSlackSettingWatcher),
      fork(changeIntegrationSettingWatcher),
      fork(asanaGetWorkspacesWatcher),
      fork(asanaStoreWorkspaceWatcher),
      fork(asanaGetTeamWatcher),
      fork(asanaStoreTeamsWatcher),
      fork(asanaGetProjectsWatcher),
      fork(asanaGetSectionWatcher),
      fork(asanaCreateTaskWatcher),
      fork(asanaDeployTasksWatcher),
      fork(deleteMilestoneWatcher),
      fork(jiraGetWorkspacesWatcher),
      fork(jiraStoreWebsitesWatcher),
      fork(jiraGetProjectsWatcher),
      fork(jiraCreateTaskWatcher),
      fork(jiraDeployTasksWatcher),
      fork(teamWorkGetProjectsWatcher),
      fork(teamWorkGetTaskListWatcher),
      fork(teamWorkCreateTaskWatcher),
      fork(teamWorkDeployTasksWatcher),
      fork(teamWorkGetTaskWatcher),
      fork(teamWorkCreateTimeEntryWatcher),
      fork(teamWorkCreateTimeEntriesWatcher),
      fork(changeAutomaticSettingWatcher),
      fork(clickupGetFoldersSettingsWatcher),
      fork(clickupGetTaskListSettingWatcher),
      fork(storeIntegrationRequirementWatcher),
      fork(asanaGetProjectsSettingsWatcher),
      fork(asanaGetSectionSettingWatcher),
      fork(teamworkGetProjectsSettingsWatcher),
      fork(teamworkGetTaskListSettingWatcher),
      fork(jiraGetProjectsSettingsWatcher),
      fork(updateSiteURLWatcher),
      fork(upgradeAccountWatcher),
      fork(changeMainWpSettingWatcher),
      fork(mergeWebsiteWatcher),
      fork(connectRocketWatcher),
      fork(createRocketSiteWatcher),
      fork(fetchRocketLocationWatcher),
      fork(fetchSourceSiteWatcher),
      fork(fetchDestinationSiteWatcher),
      fork(fetchNextEmailTasksWatcher),
      fork(fetchNextProjectSitesWatcher),
      fork(basecampGetProjectsWatcher),
      fork(basecampGetTodoWatcher),
      fork(basecampCreateTaskWatcher),
      fork(basecampDeployTasksWatcher),
      fork(basecampGetProjectsSettingsWatcher),
      fork(basecampGetTaskSettingWatcher),
      fork(trelloGetBoardsWatcher),
      fork(trelloGetTaskListWatcher),
      fork(trelloCreateTaskWatcher),
      fork(trelloDeployTasksWatcher),
      fork(trelloGetProjectsSettingsWatcher),
      fork(trelloGetListSettingWatcher),
      fork(editCollaborateCommentWatcher),
      fork(createHotspotWatcher),
      fork(updateHotspotWatcher),
      fork(deleteHotspotWatcher),
      fork(updateGodaddyWatcher),
      fork(fetchTriggersWatcher),
      fork(fetchAutomationActionWatcher),
      fork(fetchAutomationConditionWatcher),
      fork(createCustomAutomationWatcher),
      fork(fetchAutomationWorkflowWatcher),
      fork(fetchAutomationTagWatcher),
      fork(changeAutomationStatusWatcher),
      fork(deleteWorkflowWatcher),
      fork(deleteDesignWatcher),
      fork(deleteVersionWatcher),
      fork(licenseActivationWatcher),
      fork(deleteProjectWatcher),
      fork(nextArchivedSitesWatcher),
      fork(newFetchSiteListUserWatcher),
      fork(fetchNextEntriesWatcher),
      fork(fetchAutomationDetailWatcher),
      fork(editCustomAutomationWatcher),
      fork(createAutomationTemplateWatcher),
      fork(fetchAutomationTemplateWatcher),
      fork(fetchAutomationTemplateDetailWatcher),
      fork(addNewCustomSiteWatcher),
      fork(createAnnotatedTaskWatcher),
      fork(fetchNextFilteringWatcher),
      fork(nextCustomDateFilteringWatcher),
      fork(changeInstaWpSettingWatcher),
      fork(createInstawpSiteWatcher),
      fork(inviteCollabWatcher),
      fork(fetchInvitedCollabUserWatcher),
      fork(removeInvitedCollabUserWatcher),
      fork(fetchAllFormsWatcher),
      fork(fetchAllFormTemplatesWatcher),
      fork(saveFormWatcher),
      fork(editFormWatcher),
      fork(editFormStatusWatcher),
      fork(fetchSingleFormWatcher),
      fork(addFormSubmissionWatcher),
      fork(getFormSubmissionWatcher),
      fork(deleteFormWatcher),
      fork(createSnippetWatcher),
      fork(fetchAllSnippetWatcher),
      fork(fetchSnippetDetailWatcher),
      fork(deleteSnippetWatcher),
      fork(updateSnippetWatcher),
      fork(fetchAllTaskSnippetWatcher),
      fork(fetchTaskSnippetDetailWatcher),
      fork(addStandardFlowSiteWatcher),
      fork(createSnippetTemplateWatcher),
      fork(fetchAllSnippetTemplateWatcher),
      fork(importSnippetTemplateWatcher),
      fork(searchArchivedWatcher),
      fork(getReadNotificationsWatcher),
      fork(getUnreadNotificationsWatcher),
      fork(getFiltredNotificationsWatcher),
      fork(getNotificaionDetailsWatcher),
      fork(updateNotificationWatcher),
      fork(checkAllNotificationsWatcher),
      fork(unReadNotificationsCountWatcher),
      fork(muteUnmuteNotificationsWatcher),
      fork(commentEditHistoryWatcher),
      fork(searchFormWatcher),
      fork(saveFormTemplateWatcher),
      fork(mondayGetWorkspacesWatcher),
      fork(mondayStoreWorkspaceWatcher),
      fork(mondayGetBoardsWatcher),
      fork(mondayGetGroupListWatcher),
      fork(mondayCreateTaskWatcher),
      fork(changeMondaySettingWatcher),
      fork(mondayGetBoardsSettingWatcher),
      fork(mondayGetGroupWorkerWatcher),
      fork(mondayDeployTasksWatcher),
      fork(fetchAssignProjectsWatcher),
      fork(fetchNextAssignProjectsWatcher),
      fork(fetchNextSourceSiteWatcher),
      fork(fetchNextDestinationSiteWatcher),
      fork(fetchNextBoardTaskUrgencyWatcher),
      fork(fetchBoardColumnWatcher),
      fork(fetchProjectsForExportWatcher),
      fork(reinvitedCollabUserWatcher),
      fork(getUpgradePlansWatcher),
      fork(portalSessionWatcher),
      fork(openCheckoutWatcher),
      fork(revokeAccessWatcher),
      fork(getSubscriptionDetailsWatcher),
      fork(getBillingInfoWatcher),
      fork(createPortalSessionWatcher),
      fork(getInvoicesWatcher),
      fork(addPageWatcher),
      fork(fetchPagesWatcher),
      fork(deletePageWatcher),
      fork(deleteCommentFileWatcher),
      fork(deleteCommentFilesWatcher),
      fork(deleteBoardCommentFilesWatcher),
      fork(fetchHomeDataWeeksWatcher),
      fork(postTrailWatcher),
      fork(activateTrialWatcher),
      fork(rejectTrialWatcher),
      fork(closeWizardWatcher),
      fork(countOpenTaskWatcher),
      fork(fetchExistingSiteUrlWatcher),
      fork(fetchInvitedUserWatcher),
      fork(deleteSiteWatcher),
      fork(extensionMetricsWatcher),
      fork(sendTwoFactorWatcher),
      fork(verifyCodeWatcher),
      fork(authenticateWatcher),
      fork(fetchDummyTasksWatcher),
      fork(removeInvitedTeamWatcher),
      fork(confirmUserTwoFaWatcher),
      fork(verifyCollabCodeWatcher),
      fork(fetchWorkspacesWatcher),
      fork(createWorkspaceWatcher),
      fork(deleteWorkspaceWatcher),
      fork(editWorkspacesWatcher),
      fork(fetchWorkspacesDetailsWatcher),
      fork(fetchAccountUsersWatcher),
      fork(storeWorkspaceWatcher),
      fork(validateEmailWatcher),
      fork(clickupGetTasksListWatcher),
      fork(fetchWorkspaceOwnersWatcher),
      fork(fetchNextAccountUsersWatcher),
    ]);
    yield take(LOGOUT);
    yield cancel(allTasks);
    // yield call(logout); // not storing the response of logout
    // yield call(API.clearLocalStorage);
  }
}
