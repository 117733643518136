import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';

import { cn } from 'utils/cn';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipPortal = TooltipPrimitive.Portal;

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  arrowClassName?: string;
  arrow?: boolean;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(
  (
    {
      className,
      arrowClassName,
      sideOffset = 4,
      side = 'bottom',
      arrow = false,
      ...props
    },
    ref
  ) => {
    return (
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        side={side}
        // TODO: Remove the ridiculous z-index once we refactor the sidebar
        className={cn(
          'bg-secondary animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-[11000] max-w-64 overflow-hidden rounded-md px-2 py-0.5 text-xs font-normal text-white shadow-md',
          className
        )}
        {...props}
      >
        {props.children}
        {arrow && (
          <TooltipArrow className={cn(arrowClassName, 'fill-secondary')} />
        )}
      </TooltipPrimitive.Content>
    );
  }
);

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TooltipProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> {
  children: React.ReactNode;
  content: React.ReactNode;
  portal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  delayDuration?: number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
  alignOffset?: number;
  arrow?: boolean;
  className?: string;
}

const TooltipTrigger = TooltipPrimitive.Trigger;

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  portal = false,
  open,
  onOpenChange,
  delayDuration,
  ...props
}) => {
  if (portal) {
    return (
      <TooltipPrimitive.Root
        open={open}
        onOpenChange={onOpenChange}
        delayDuration={delayDuration}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPortal container={document.getElementById('root-atarim')?.shadowRoot || document.body}>
          <TooltipContent {...props}>{content}</TooltipContent>
        </TooltipPortal>
      </TooltipPrimitive.Root>
    );
  }
  return (
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipContent {...props}>{content}</TooltipContent>
    </TooltipPrimitive.Root>
  );
};

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
export default Tooltip;
