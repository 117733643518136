import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as React from 'react';
import Tooltip, { TooltipTrigger } from '../Tooltip';

import { cn } from 'utils/cn';

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'inline-flex h-10 w-full items-center justify-center rounded-lg border p-1 text-secondary',
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    tooltip?: string;
    tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
    tooltipAlign?: 'start' | 'center' | 'end';
    tooltipSideOffset?: number;
    tooltipAlignOffset?: number;
    tooltipArrow?: boolean;
    toolTipPortal?: boolean;
  }
>(({ className, tooltip, tooltipSide, tooltipAlign, tooltipSideOffset, tooltipAlignOffset, tooltipArrow, toolTipPortal = false, ...props }, ref) => {
  const trigger = (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'ring-offset-background focus-visible:ring-ring inline-flex w-full items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-tertiary data-[state=active]:text-white data-[state=active]:shadow-sm',
        className
      )}
      {...props}
    />
  );

  if (tooltip) {
    return (
      <Tooltip
        content={tooltip}
        side={tooltipSide}
        arrow={tooltipArrow}
        portal={toolTipPortal}
        sideOffset={tooltipSideOffset}
        align={tooltipAlign}
        alignOffset={tooltipAlignOffset} >
        <TooltipTrigger>
          {trigger}
        </TooltipTrigger>
      </Tooltip>
    );
  }

  return trigger;
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-2 min-h-[200px] w-full rounded-lg border bg-white p-4 shadow-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

const Tabs = Object.assign(TabsPrimitive.Root, {
  Content: TabsContent,
  List: TabsList,
  Trigger: TabsTrigger,
});

export default Tabs;
