import {
  differenceInSeconds,
  formatDistanceToNowStrict,
  isValid,
  parseISO,
} from 'date-fns';

export const getLocaleDateTime = (utcDateTimeString = '') => {
  if (!utcDateTimeString) {
    return '';
  }
  let date = new Date(utcDateTimeString + ' UTC').toLocaleString([], {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  if (date === 'Invalid Date') {
    return utcDateTimeString + ' UTC';
  }
  return date;
};

export function getCompactRelativeTime(dateString: string): string {
  const date = parseISO(dateString);

  if (!isValid(date)) {
    console.error('Invalid date string:', dateString);
    return 'Invalid date';
  }

  const now = new Date();
  const diffSeconds = differenceInSeconds(now, date);

  if (isNaN(diffSeconds)) {
    console.error('Error calculating time difference');
    return 'Error';
  }

  if (diffSeconds < 0) {
    return 'Just now'; // for future dates or slight time discrepancies
  }

  if (diffSeconds < 60) return `${diffSeconds}s`;
  if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)}m`;
  if (diffSeconds < 86400) return `${Math.floor(diffSeconds / 3600)}hr`;
  if (diffSeconds < 604800) return `${Math.floor(diffSeconds / 86400)}dy`;
  if (diffSeconds < 2592000) return `${Math.floor(diffSeconds / 604800)}wk`;
  if (diffSeconds < 31536000) return `${Math.floor(diffSeconds / 2592000)}mo`;
  return `${Math.floor(diffSeconds / 31536000)}y`;
}

export function getRelativeTimeString(
  dateString: string,
  withPrefix = false
): string {
  try {
    // Parse the input date string
    const date = parseISO(dateString);

    // Check if the parsed date is valid
    if (!isValid(date)) {
      console.error('Invalid date string:', dateString);
      return 'Invalid date';
    }

    // Get the relative time string
    const distance = formatDistanceToNowStrict(date, { addSuffix: withPrefix });

    return distance;
  } catch (error) {
    console.error('Error formatting relative time:', error);
    return 'Error';
  }
}

export function calculateElapsedTime(startTime: string, spent_time: string) {
  // Convert the start time to a timestamp (milliseconds)
  const localTime = getLocaleDateTime(startTime);

  const startTimestamp = new Date(localTime).getTime(); // Convert start time to milliseconds

  // Get the current timestamp (milliseconds)
  const currentTimestamp = Date.now();

  // Calculate the difference in milliseconds
  const elapsedTimeMs = currentTimestamp - startTimestamp;

  // Convert milliseconds to seconds
  const elapsedTimeSec = elapsedTimeMs / 1000;
  const totalSpentTime = Number(spent_time) + elapsedTimeSec;

  // Return the elapsed time in seconds
  return Math.floor(totalSpentTime);
}
