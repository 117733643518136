import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, VariantProps } from 'class-variance-authority';
import React, { useEffect, useMemo, useState } from 'react';
import { cn } from 'utils/cn';
import { getRandomColor } from '../helper';

const buttonVariants = cva(
  'relative flex h-full w-full shrink-0 overflow-hidden font-normal border-none aspect-square',
  {
    variants: {
      size: {
        xs: 'size-5 text-[8px]',
        sm: 'size-6 text-[10px]',
        md: 'size-8 text-xs',
        lg: 'size-12 text-lg',
        xl: 'size-16 text-xl',
        fit: 'w-full h-full max-w-max max-h-max',
      },
      borderRadius: {
        sm: 'rounded-sm',
        md: 'rounded-[4px]',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      size: 'md',
      borderRadius: 'md',
    },
  }
);

export type AvatarProps = Omit<
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
  'id'
> &
  VariantProps<typeof buttonVariants> & {
    email?: string;
    src?: string;
    alt?: string;
    title?: string;
    placeholder?: string;
    colour?: string;
    id?: string | number;
  };

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size, borderRadius, id, style, ...props }, ref) => {
  return (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex shrink-0 overflow-hidden border',
        buttonVariants({ size, borderRadius }),
        className
      )}
      onClick={props.onClick}
      style={style}
    >
      <AvatarImage
        email={props.email}
        src={props.src}
        alt={props.alt}
        className={className}
      />
      <AvatarFallback
        title={props.title}
        id={id}
        placeholder={props.placeholder}
        colour={props.colour}
        className={className}
      />
    </AvatarPrimitive.Root>
  );
});
Avatar.displayName = AvatarPrimitive.Root.displayName;

type AvatarImageProps = {
  email?: string;
  alt?: string;
  src?: string;
  className?: string;
};

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  AvatarImageProps
>(({ email, alt, src, className }, ref) => {
  const gravatarUrl = useMemo(() => {
    if (!email) return undefined;

    const getGravatarUrl = async (email: string) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(email.toLowerCase().trim());
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
      return `https://gravatar.com/avatar/${hashHex}?d=404`;
    };

    return getGravatarUrl(email);
  }, [email]);

  const [cachedGravatarUrl, setCachedGravatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (gravatarUrl) {
      gravatarUrl.then(setCachedGravatarUrl);
    }
  }, [gravatarUrl]);

  const [imageSrc, setImageSrc] = useState<string | undefined>(src);

  useEffect(() => {
    if (src) {
      setImageSrc(src);
    } else if (cachedGravatarUrl) {
      setImageSrc(cachedGravatarUrl);
    }
  }, [src, cachedGravatarUrl]);

  const handleImageError = () => {
    setImageSrc(undefined);
  };

  return (
    <AvatarPrimitive.Image
      ref={ref}
      className={cn('h-full w-full object-cover', className)}
      src={imageSrc}
      alt={alt}
      onError={handleImageError}
    />
  );
});
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

type AvatarFallbackProps = {
  title?: string;
  colour?: string;
  id?: string | number;
  placeholder?: string;
  className?: string;
};

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  AvatarFallbackProps
>(({ title, colour, id, placeholder, className }, ref) => {
  const initials = title
    ?.split(' ')
    .map((name) => name.replace(/[^a-zA-Z@?]/g, ''))
    .map((name, _, array) => (array.length === 1 ? name.slice(0, 2) : name[0]))
    .slice(0, 2)
    .join('');

  return (
    <AvatarPrimitive.Fallback
      style={{
        backgroundColor: colour ?? getRandomColor(id ?? title ?? ""),
        color: 'white',
      }}
      ref={ref}
      className={cn(
        'flex h-full w-full items-center justify-center uppercase',
        className
      )}
    >
      {initials || placeholder}
    </AvatarPrimitive.Fallback>
  );
});
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarFallback, AvatarImage };

