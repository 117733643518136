import {
  ADD_GRAPHIC_VERSION,
  ADD_NEW_BUBBLE,
  ADD_NEW_CUSTOM_SITE,
  ADD_NEW_SITE,
  ADD_STAMDARD_FLOW_SITE,
  CLOSE_GENERAL_MODAL,
  COMMENT_EDIT_HISTORY,
  CONFIRM_SELECTED,
  CREATE_ANNOTATED_TASK,
  CREATE_GRAPHIC_TASK,
  CREATE_HOTSPOT,
  CREATE_SITE_TASK,
  CREATE_TASK_COMMENT,
  DELETE_COLLABORATE_COMMENT,
  DELETE_COMMENT_FILE,
  DELETE_HOTSPOT,
  DELETE_PAGE,
  DELETE_VERSION,
  EDIT_COLLABORATE_COMMENT,
  EMPTY_FAILED_TASK_DATA,
  ERROR_ADD_GRAPHIC_VERSION,
  ERROR_ADD_NEW_CUSTOM_SITE,
  ERROR_ADD_NEW_SITE,
  ERROR_APPROVE_PAGE,
  ERROR_ATTRIBUTES_FETCHING,
  ERROR_COMMENT_EDIT_HISTORY,
  ERROR_CREATE_ANNOTATED_TASK,
  ERROR_CREATE_HOTSPOT,
  ERROR_CREATE_SITE_TASK,
  ERROR_CREATE_TASK_COMMENT,
  ERROR_DELETE_COLLABORATE_TASK,
  ERROR_DELETE_COMMENT,
  ERROR_DELETE_DESIGN,
  ERROR_DELETE_HOTSPOT,
  ERROR_DELETE_VERSION,
  ERROR_FETCH_INVITED_COLLAB,
  ERROR_FETCH_PAGES,
  ERROR_GET_GRAPHIC_VERSIONS,
  ERROR_GET_ITEM_DETAILS,
  ERROR_GET_SITE_TASKS,
  ERROR_REMOVE_INVITED_COLLAB,
  ERROR_RESEND_COLLAB_INVITE,
  ERROR_START_INVITE_COLLAB,
  ERROR_UPDATE_GODADDY_DONE,
  ERROR_UPDATE_GRAPHIC_TITLE,
  ERROR_UPDATE_SITE_URL,
  ERROR_UPGRADE_ACCOUNT,
  ERROR_USER_LOGIN,
  ERROR_USER_REGISTRATION,
  FETCH_INVITED_COLLAB,
  FETCH_NEXT_THIS_PAGE_TASK,
  FETCH_PAGES,
  GET_GRAPHIC_CURRENT_GRAPHIC_TASK,
  GET_GRAPHIC_VERSIONS,
  GET_ITEM_DETAILS,
  GET_OPEN_TASK_NUMBER,
  GET_SITE_TASKS,
  GET_SITE_THIS_PAGE_TASK,
  MAKE_TAKE_SCREENSHOT_FALSE,
  NEW_COLLABORATE_TASK_SELECTED,
  OPEN_CLOSE_NAVBAR,
  OPEN_GENERAL_MODAL,
  REMOVE_BUBBLE,
  REMOVE_ITEM_DETAILS,
  REMOVE_SELECTED_TASK,
  SELECT_COLLABORATE_NOTIFY_USER,
  SELECT_GENERAL_MODAL,
  SET_2FA_COLLAB_STATUS,
  SET_HOTSPOT_ANNOTATIONS,
  SET_OPEN_LOGIN,
  SET_OPEN_REGISTER,
  SET_POPOVER_POSITION,
  SET_SCROLL_TOP,
  SET_SELECTED_GRAPHIC,
  SET_SITE_ID,
  SET_TASK_FOR_OPEN_TASK_BUTTON,
  // SUCCESS_DELETE_COMMENT_FILE,
  SHOW_SHARE_POPUP,
  SOCIAL_USER_REGISTRATION,
  START_INVITE_COLLAB,
  STORE_COLLAB_STATE,
  SUCCESS_ADD_GRAPHIC_VERSION,
  SUCCESS_ADD_NEW_CUSTOM_SITE,
  SUCCESS_ADD_NEW_SITE,
  SUCCESS_ADD_PAGE,
  SUCCESS_APPROVE_PAGE,
  SUCCESS_ATTACH_FILE_UPLOAD,
  SUCCESS_ATTRIBUTES_FETCHING,
  SUCCESS_COMMENT_EDIT_HISTORY,
  SUCCESS_CREATE_ANNOTATED_TASK,
  SUCCESS_CREATE_HOTSPOT,
  SUCCESS_CREATE_SITE_TASK,
  SUCCESS_CREATE_TASK_COMMENT,
  SUCCESS_DELETE_COLLABORATE_TASK,
  SUCCESS_DELETE_COMMENT,
  SUCCESS_DELETE_DESIGN,
  SUCCESS_DELETE_HOTSPOT,
  SUCCESS_DELETE_PAGE,
  SUCCESS_DELETE_VERSION,
  SUCCESS_EDIT_COMMENT,
  SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK,
  SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK,
  SUCCESS_FETCH_ALL_PAGES,
  SUCCESS_FETCH_DUMMY_TASKS,
  SUCCESS_FETCH_INVITED_COLLAB,
  SUCCESS_FETCH_NEXT_SITE_TASKS,
  SUCCESS_FETCH_PAGES,
  SUCCESS_GET_GRAPHIC_VERSIONS,
  SUCCESS_GET_ITEM_DETAILS,
  SUCCESS_GET_OPEN_TASK_NUMBER,
  SUCCESS_GET_SITE_TASKS,
  SUCCESS_REMOVE_INVITED_COLLAB,
  SUCCESS_RESEND_COLLAB_INVITE,
  SUCCESS_START_INVITE_COLLAB,
  SUCCESS_UPDATE_GRAPHIC_TITLE,
  SUCCESS_UPDATE_SITE_URL,
  SUCCESS_UPDATE_STATUS,
  SUCCESS_UPGRADE_ACCOUNT,
  SUCCESS_UPLOAD_IMAGE_GRAPHICS,
  SUCCESS_USER_LOGIN,
  SUCCESS_USER_REGISTRATION,
  UPDATE_CHANGE_URL_ON_LOGIN,
  UPDATE_DUMMY_TASKS,
  UPDATE_GODADDY_DONE,
  UPDATE_GRAPHIC_TASK_COUNT,
  UPDATE_ITEM_DETAILS_ROLE,
  UPDATE_PAGE_TASK_COUNT,
  UPDATE_SHOW_POPOVER,
  UPDATE_STATUS_ATTRIBUTE,
  UPDATE_TASK_ON_SCROLL,
  UPGRADE_ACCOUNT,
  USER_LOGIN,
  USER_REGISTRATION,
} from 'actions/collaborate';
import { SUCCESS_ADD_GRAPHIC_SITE } from 'actions/dashboard';
import {
  EMPTY_TASKS_MESSAGE,
  SUCCESS_UPDATE_INTERNAL_TASK,
} from 'actions/tasks';
import React from 'react';

const initState = {
  keepInitial: null,
  itemDetails: {},
  itemDetailsLoading: false,
  tasks: [],
  successAddSiteMessage: '',
  errorAddSiteMessage: '',
  siteCreated: false,
  createdSiteId: '',
  fromWizard: false,
  currentPageTasks: [],
  prevScrollTop: 0,
  createTasklsLoading: false,
  selectedTask: null,
  attributeIsLoading: false,
  attributes: {},
  newTask: false,
  addingNewSite: false,
  isPageApproved: false,
  selectedGeneralTask: null,
  userRegistrationLoading: false,
  userRegistrationSuccess: '',
  registrationStepTwo: false,
  userRegistrationError: '',
  userLoginLoading: false,
  userLoginSuccess: '',
  userLoginError: '',
  changeUrlOnLogin: false,
  openGeneralModal: false,
  bubblesRefs: [],
  popoverPosition: {
    x: 0,
    y: 0,
  },
  showPopover: false,
  errorAttributeMessage: '',
  successAttributeMessage: '',
  openLogin: false,
  intialCollaborator: null,
  currentPageCount: 0,
  takeScreenshot: false,
  screenshotX: 0,
  screenshotY: 0,
  taskIdForScreenshot: null,
  taskForOpenTaskButton: null,
  totalRecords: 0,
  totalRecordsAssignedToMe: 0,
  currentPageMoreRecords: false,
  allPageMoreRecords: false,
  isUpdatingStatus: false,
  siteIdFromMain: '',
  updateSiteSuccess: '',
  updateSiteError: '',
  upgradeAccountLoading: false,
  upgradeAccountSuccess: '',
  upgradeAccountError: '',
  urlSource: null,
  graphicTitleError: '',
  graphicVersions: [],
  graphicVersionsError: '',
  graphicVersionsSuccess: '',
  successCreateHotspot: '',
  errorCreateHotspot: '',
  loadingCreateHotspot: false,
  hotspotAnnotations: [],
  graphicVersionsLoading: false,
  selectedGraphic: null,
  currentPageTasksLoading: false,
  selectedGraphicVersion: null,
  ispushingToGodaddy: false,
  pushToGodaddyError: '',
  deleteCommentLoading: false,
  deleteCommentSuccess: '',
  deleteCommentError: '',
  displayHelp: false,
  successUploadImageGraphics: '',
  customSiteEmail: '',
  customEmailExist: false,
  isLoadingInviteCollab: false,
  invitedUsers: [],
  isLoadingInviteduser: false,
  invitedUserError: '',
  inviteRemovedSuccess: '',
  navInCollaborate: false,
  websiteDetail: {},
  pages: [],
  newlyAddedPage: {},
  isLoadingPages: false,
  showSharePopup: false,
  openTasks: null,
  failedTaskCreationData: {},
  failedCommentCreationData: {},
  dummyTasks: [],
  is2FAEnabled: false,
  openRegister: false,
  collaborateState: JSON.parse(localStorage.getItem('collabWizard')),
};
const collaborate = (previousState = initState, action) => {
  switch (action.type) {
    case GET_ITEM_DETAILS:
      return {
        ...previousState,
        itemDetails: {},
        itemDetailsLoading: true,
        siteCreated: false,
      };
    case SUCCESS_GET_ITEM_DETAILS:
      let targetGraphic;
      if (action.data.type === 'graphic') {
        // we have to select the required graphic instead of first graphic if there is a graphic id
        // i.e when URL is pasted/opened directly
        if (action.targetGraphic?.graphicId) {
          targetGraphic = action.data.graphics?.filter(
            (g) => g.id === action.targetGraphic?.graphicId
          )[0];
        }
        // we have to select the required graphic instead of first graphic if there is a graphic url
        // i.e when coming from tasks/boards page
        if (action.targetGraphic?.graphicUrl) {
          targetGraphic = action.data.graphics?.filter(
            (g) => g.image === action.targetGraphic?.graphicUrl
          )[0];
        }
      }
      return {
        ...previousState,
        itemDetails: action.data,
        itemDetailsLoading: false,
        selectedGraphic:
          action.data.type === 'graphic'
            ? targetGraphic
              ? targetGraphic
              : action.data.graphics[0]
            : null,
      };
    case ERROR_GET_ITEM_DETAILS:
      return {
        ...previousState,
        itemDetailsLoading: false,
        identifier: action.message,
      };
    case SUCCESS_UPLOAD_IMAGE_GRAPHICS: {
      const itemDetails = {
        ...previousState.itemDetails,
        graphics: [...previousState.itemDetails.graphics, ...action.graphics],
      };
      return {
        ...previousState,
        itemDetails: itemDetails,
        itemDetailsLoading: false,
        selectedGraphic: action.graphics[0],
        successUploadImageGraphics: 'Graphic Item uploaded successfully',
      };
    }
    case SELECT_COLLABORATE_NOTIFY_USER:
      return {
        ...previousState,
        attributes:
          Object.keys(previousState.attributes).length > 0
            ? {
                ...previousState.attributes,
                notify_wp_feedback_users:
                  previousState.attributes.notify_wp_feedback_users.map(
                    (user) =>
                      user.value === action.params.userId
                        ? { ...user, checked: action.params.checked }
                        : user
                  ),
              }
            : previousState.attributes,
        itemDetails: {
          ...previousState.itemDetails,
          users: previousState.itemDetails.users.map((user) =>
            user.value === action.params.userId
              ? { ...user, checked: action.params.checked }
              : user
          ),
        },
      };
    case GET_SITE_TASKS:
      return {
        ...previousState,
        tasks: action.applyingFilter ? previousState.tasks : [],
        successMessage: '',
        errorMessage: '',
        isPageApproved: false,
        allPageMoreRecords: false,
      };
    case SUCCESS_GET_SITE_TASKS:
      let createReferences = [];
      if (action.data?.tasks?.length > 0) {
        createReferences = action.data.tasks.reduce((acc, value) => {
          acc[value.id] = React.createRef();
          return acc;
        }, {});
      }
      return {
        ...previousState,
        tasks: action.data.tasks,
        isPageApproved: action.data.is_page_approved,
        totalRecords: action.total_records,
        totalRecordsAssignedToMe: action.totalRecordsAssignedToMe,
        bubblesRefs: createReferences,
        allPageMoreRecords: action.moreRecord,
      };
    case SUCCESS_FETCH_NEXT_SITE_TASKS:
      return {
        ...previousState,
        tasks: previousState.tasks.concat(action.data.tasks),
        isPageApproved: action.data.is_page_approved,
        totalRecords: action.total_records,
        totalRecordsAssignedToMe: action.totalRecordsAssignedToMe,
        // bubblesRefs: createReferences,
        allPageMoreRecords: action.moreRecord,
      };
    case ERROR_GET_SITE_TASKS:
      return {
        ...previousState,
        tasks: [],
        // errorMessage: action.message,
        // successMessage: "",
      };
    case ADD_NEW_BUBBLE:
      let incrementedId = 1;
      // when a new design is added / there is no tasks on the design so make the new temp task number greater then all
      if (previousState.tasks.length > 0) {
        incrementedId =
          previousState.tasks.sort(
            (a, b) => b.task_comment_id - a.task_comment_id
          )[0].task_comment_id + 1;
      }
      // when a new degign is added / there is no tasks on the design
      else {
        incrementedId = previousState.totalRecords + 1;
      }
      return {
        ...previousState,
        tasks: previousState.tasks.concat({
          task_comment_id: incrementedId,
          task_elementX: action.data.task_elementX,
          task_elementY: action.data.task_elementY,
          task_page_url: action.data.currentPageUrl,
          task_status: 'open',
          task_priority: 'low',
          task_type: [],
          graphic_id:
            previousState.selectedGraphic && previousState.selectedGraphic.id,
        }),
        currentPageTasks: previousState.currentPageTasks.concat({
          task_comment_id: incrementedId,
          task_elementX: action.data.task_elementX,
          task_elementY: action.data.task_elementY,
          task_page_url: action.data.currentPageUrl,
          task_status: 'open',
          task_priority: 'low',
          task_type: [],
          graphic_id:
            previousState.selectedGraphic && previousState.selectedGraphic.id,
        }),
        attributes: {},
        newTask: false,
      };
    case REMOVE_BUBBLE:
      let removeTasks = [];
      let removeCurrentTasks = [];
      if (previousState.tasks?.length > 0) {
        removeTasks = previousState.tasks.filter((task) => task.id);
      }
      if (previousState.currentPageTasks?.length > 0) {
        removeCurrentTasks = previousState.currentPageTasks.filter(
          (task) => task.id
        );
      }
      return {
        ...previousState,
        tasks: removeTasks,
        currentPageTasks: removeCurrentTasks,
        selectedTask: null,
      };
    case CREATE_SITE_TASK:
      if (Object.keys(previousState.failedTaskCreationData).length > 0) {
        // if there is a failed task creation data
        // here we will apply retry logic instead of creating new one
        return {
          ...previousState,
          failedTaskCreationData: {},
        };
      } else {
        const dataObj = action.params;
        if (dataObj instanceof FormData) {
          return {
            ...previousState,
          };
        } else {
          const commentFileObj = action.files.map((file) => {
            return {
              name: file.name,
              size: file.size,
              type: file.type,
              url: file.data,
            };
          });
          dataObj.comments = [
            {
              comments: dataObj['task_comment_message'],
              is_deleted: false,
              is_edited: false,
              user_id: action.params.task_config_author_id,
              user_name: dataObj['task_config_author_name'],
              is_note: 0,
              days: '1s',
              comment_type: 'normal_text',
              is_guest: false,
              files: commentFileObj || [],
            },
          ];
          const newTasks = [dataObj, ...previousState.tasks];
          const newCurrentPageTasks = [
            dataObj,
            ...previousState.currentPageTasks,
          ];
          return {
            ...previousState,
            // we have not to store the attributes if we are creating task in proxy
            attributes: dataObj.task_element_path ? {} : dataObj,
            tasks: newTasks,
            currentPageTasks: newCurrentPageTasks,
          };
        }
      }
    case CREATE_ANNOTATED_TASK:
      return {
        ...previousState,
        createTasklsLoading: true,
        attributeIsLoading: true,
      };
    case SUCCESS_CREATE_ANNOTATED_TASK:
    case SUCCESS_CREATE_SITE_TASK:
      const newTask = {
        ...action.data,
        task_elementY: action.data.task_elementY - previousState.prevScrollTop,
      };

      const updatedAllTasks = [
        newTask,
        ...(previousState.tasks || []).filter((task) => task.id !== 99999),
      ];
      const updatedCurrentTasks = [
        newTask,
        ...(previousState.currentPageTasks || []).filter(
          (task) => task.id !== 99999
        ),
      ];

      const newCreateReferences = updatedAllTasks.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {});

      const currentpagetaskCountInc =
        action.data.task_type[0] === 'General'
          ? previousState.currentPageCount
          : previousState.currentPageCount + 1;

      const assignedToMeTasksCountInc = action.data.collaborators.find(
        (c) => c.user_id === action.userId
      )
        ? previousState.totalRecordsAssignedToMe + 1
        : previousState.totalRecordsAssignedToMe;

      return {
        ...previousState,
        tasks: updatedAllTasks,
        currentPageTasks: updatedCurrentTasks,
        selectedTask: action.data.task_id,
        bubblesRefs: newCreateReferences,
        takeScreenshot: true,
        screenshotX: action.data.task_elementX,
        screenshotY: action.data.task_elementY,
        taskIdForScreenshot: action.data.task_id,
        createTasklsLoading: false,
        currentPageCount: currentpagetaskCountInc,
        totalRecords: previousState.totalRecords + 1,
        totalRecordsAssignedToMe: assignedToMeTasksCountInc,
        failedTaskCreationData: {},
      };
    case ERROR_CREATE_ANNOTATED_TASK:
      return {
        ...previousState,
        createTasklsLoading: false,
      };
    case ERROR_CREATE_SITE_TASK:
      return {
        ...previousState,
        createTasklsLoading: false,
        failedTaskCreationData: action.failedTaskData,
      };
    case MAKE_TAKE_SCREENSHOT_FALSE:
      return {
        ...previousState,
        takeScreenshot: false,
        screenshotX: 0,
        screenshotY: 0,
        taskIdForScreenshot: null,
      };
    case UPDATE_STATUS_ATTRIBUTE:
      let newTaskList = [];
      let newCurrenPageTaskList = [];
      if (action.value.internal !== undefined) {
        newTaskList = previousState.tasks.map((task) =>
          task.task_id === action.value.task_id
            ? {
                ...task,
                is_internal: !!action.value.internal,
              }
            : task
        );
        newCurrenPageTaskList = previousState.currentPageTasks.map((task) =>
          task.task_id === action.value.task_id
            ? {
                ...task,
                is_internal: !!action.value.internal,
              }
            : task
        );
      }
      return {
        ...previousState,
        createTasklsLoading: true,
        isUpdatingStatus: true,
        tasks:
          action.value.internal !== undefined
            ? newTaskList
            : previousState.tasks,
        currentPageTasks:
          action.value.internal !== undefined
            ? newCurrenPageTaskList
            : previousState.currentPageTasks,
      };
    case CREATE_TASK_COMMENT:
      if (Object.keys(previousState.failedCommentCreationData).length > 0) {
        // if there is a failed task creation data
        // here we will apply retry logic instead of creating new one
        return {
          ...previousState,
          createTasklsLoading: true,
          failedCommentCreationData: {},
        };
      }

      return {
        ...previousState,
        createTasklsLoading: true,
      };
    case SUCCESS_CREATE_TASK_COMMENT:
      let createdComment = action.data;
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: previousState.attributes?.comments
            ?.slice(0, -1)
            .concat(createdComment),
          temporaryKey: 'instant task creation',
        },
        createTasklsLoading: false,
        createdSuccess: action.message,
        newTask: true,
        attributeIsLoading: false,
        failedCommentCreationData: {},
      };
    case ERROR_CREATE_TASK_COMMENT:
      return {
        ...previousState,
        createTasklsLoading: false,
        failedCommentCreationData: action.failedCommentData,
      };
    case NEW_COLLABORATE_TASK_SELECTED:
      return {
        ...previousState,
        selectedTask: action.taskId,
        attributeIsLoading: true,
        errorAttributeMessage: '',
        newTask: true,
        attributes: {},
        failedCommentCreationData: {},
        failedTaskCreationData: {},
      };
    case CONFIRM_SELECTED:
      return {
        ...previousState,
        selectedTask: action.taskId,
        newTask: true,
      };
    case SELECT_GENERAL_MODAL:
      return {
        ...previousState,
        attributeIsLoading: false,
        errorAttributeMessage: '',
        newTask: true,
        attributes: {},
      };
    case SUCCESS_ATTRIBUTES_FETCHING:
      return {
        ...previousState,
        attributeIsLoading: false,
        attributes: action.attributes,
        createTasklsLoading: false,
        isUpdatingStatus: false,
        successAttributeMessage: action.message,
        errorAttributeMessage: '',
        newTask: true,
      };
    case ERROR_ATTRIBUTES_FETCHING:
      return {
        ...previousState,
        attributeIsLoading: false,
        createTasklsLoading: false,
        attributes: {},
        successAttributeMessage: '',
        errorAttributeMessage: action.message,
      };
    case SUCCESS_UPDATE_STATUS:
      return {
        ...previousState,
        tasks: previousState.tasks.map((task) =>
          task.task_id === action.taskId
            ? {
                ...task,
                task_status: action.status,
                is_complete: action.status === 'complete',
              }
            : task
        ),
        currentPageTasks: previousState.currentPageTasks.map((task) =>
          task.task_id === action.taskId
            ? {
                ...task,
                task_status:
                  action.method === 'status' ? action.status : task.task_status,
                task_priority:
                  action.method === 'priority'
                    ? action.status
                    : task.task_priority,
                is_complete: action.status === 'complete',
              }
            : task
        ),
      };
    case ADD_NEW_SITE:
      return {
        ...previousState,
        siteCreated: false,
        createdSiteId: '',
        addingNewSite: true,
        urlSource: null,
        errorAddSiteMessage: '',
        fromWizard: false,
      };
    case ADD_STAMDARD_FLOW_SITE:
      return {
        ...previousState,
        siteCreated: false,
        createdSiteId: '',
        addingNewSite: true,
        urlSource: null,
      };
    case SUCCESS_ADD_GRAPHIC_SITE: {
      return {
        ...previousState,
        siteCreated: action.isFromProjects,
        createdSiteId: action.data.id,
        addingNewSite: false,
      };
    }
    case SUCCESS_ADD_NEW_SITE:
      return {
        ...previousState,
        successAddSiteMessage: action.message,
        errorAddSiteMessage: '',
        createdSiteId: action.data.id,
        siteCreated: true,
        addingNewSite: false,
        urlSource: action.urlSource,
        websiteDetail: action.data,
        fromWizard: action.fromWizard,
      };
    case ERROR_ADD_NEW_SITE:
      return {
        ...previousState,
        errorAddSiteMessage: action.message,
        successAddSiteMessage: '',
        siteCreated: false,
        createdSiteId: '',
        addingNewSite: false,
        fromWizard: false,
      };
    case UPDATE_TASK_ON_SCROLL:
      if (previousState.showPopover) {
        const pos =
          action.direction === 'up'
            ? {
                x: previousState.popoverPosition.x,
                y:
                  previousState.popoverPosition.y -
                  (action.scrollTop - previousState.prevScrollTop),
              }
            : {
                x: previousState.popoverPosition.x,
                y:
                  previousState.popoverPosition.y +
                  (previousState.prevScrollTop - action.scrollTop),
              };
        return {
          ...previousState,
          tasks:
            previousState?.tasks.length > 0 &&
            previousState.tasks.map((task) =>
              action.direction === 'up'
                ? {
                    ...task,
                    task_elementY:
                      task.task_elementY -
                      (action.scrollTop - previousState.prevScrollTop),
                  }
                : {
                    ...task,
                    task_elementY:
                      task.task_elementY +
                      (previousState.prevScrollTop - action.scrollTop),
                  }
            ),
          popoverPosition: pos,
        };
      } else {
        return {
          ...previousState,
          // undefined or false
          tasks:
            previousState.tasks !== undefined &&
            previousState.tasks.length > 0 &&
            previousState.tasks.map((task) =>
              action.direction === 'up'
                ? {
                    ...task,
                    task_elementY:
                      task.task_elementY -
                      (action.scrollTop - previousState.prevScrollTop),
                  }
                : {
                    ...task,
                    task_elementY:
                      task.task_elementY +
                      (previousState.prevScrollTop - action.scrollTop),
                  }
            ),
        };
      }
    case GET_SITE_THIS_PAGE_TASK:
      return {
        ...previousState,
        currentPageTasksLoading: true,
        // currentPageTasksLoading: action.applyingFilter ? false : true,
        currentPageTasks: [],
        // currentPageTasks: action.applyingFilter ? previousState.currentPageTasks : [],
        // itemDetailsLoading: true,
        currentPageMoreRecords: false,
        isPageApproved: false,
      };
    case GET_GRAPHIC_CURRENT_GRAPHIC_TASK: {
      return {
        ...previousState,
        currentPageTasks: [],
        // itemDetailsLoading: true,
        currentPageMoreRecords: false,
        isPageApproved: false,
        currentPageTasksLoading: true,
      };
    }
    case FETCH_NEXT_THIS_PAGE_TASK: {
      return {
        ...previousState,
      };
    }
    case SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK:
      return {
        ...previousState,
        currentPageTasks: action.data.tasks,
        currentPageCount:
          action.data.tasks[0]?.task_type[0] !== 'General'
            ? action.currentPageCount
            : previousState.currentPageCount,
        // itemDetailsLoading: false,
        isPageApproved: action.data.is_page_approved,
        currentPageMoreRecords: action.moreRecords,
        currentPageTasksLoading: false,
      };
    case SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK:
      return {
        ...previousState,
        currentPageTasks: previousState.currentPageTasks.concat(
          action.data.tasks
        ),
        currentPageCount: action.currentPageCount,
        // itemDetailsLoading: false,
        isPageApproved: action.data.is_page_approved,
        currentPageMoreRecords: action.moreRecords,
        currentPageTasksLoading: false,
      };
    case SET_SCROLL_TOP:
      return {
        ...previousState,
        prevScrollTop: action.scrollTop,
      };
    case EMPTY_TASKS_MESSAGE:
      return {
        ...previousState,
        [action.keyName]: '',
        taskUpdateMessage: '',
      };
    case SUCCESS_ATTACH_FILE_UPLOAD:
      let createdFile = action.file;
      let newFile = previousState.attributes.comments?.concat(createdFile);
      return {
        ...previousState,
        attributes: { ...previousState.attributes, comments: newFile },
        createTasklsLoading: false,
        createdSuccess: action.message,
        newTask: true,
        attributeIsLoading: false,
      };
    case SUCCESS_DELETE_DESIGN:
      let deletedDesign = previousState.itemDetails.graphics.filter(
        (graphic) => graphic.id !== action.data.graphic_id
      );
      const deleteTasks = previousState.tasks.filter(
        (t) => t.graphic_id !== action.data.graphic_id
      );

      return {
        ...previousState,
        tasks: deleteTasks,
        itemDetails: {
          ...previousState.itemDetails,
          graphics: deletedDesign,
        },

        successMessage: 'Design deleted successfully',
      };
    case ERROR_DELETE_DESIGN:
      return {
        ...previousState,
        errorMessage: action.message,
        successMessage: '',
      };
    case DELETE_VERSION:
      return {
        ...previousState,
        graphicVersionsLoading: true,
        graphicVersionsError: '',
        graphicVersionsSuccess: '',
      };
    case SUCCESS_DELETE_VERSION:
      let FiltredVersions = previousState.graphicVersions.versions.filter(
        (ver) => ver.id !== action.data.version_id
      );
      let graphic_array = previousState.itemDetails.graphics.map((graphic) => {
        if (graphic.id === previousState.selectedGraphic.id) {
          return {
            ...graphic,
            total_versions: graphic.total_versions - 1,
            image: FiltredVersions[0].image,
            width: FiltredVersions[0].width,
            height: FiltredVersions[0].height,
          };
        } else return graphic;
      });
      return {
        ...previousState,
        itemDetails: {
          ...previousState.itemDetails,
          graphics: graphic_array,
        },
        graphicVersionsLoading: false,
        graphicVersions: {
          ...previousState.graphicVersions,
          versions: FiltredVersions,
        },
        successMessage: action.message,
      };
    case ERROR_DELETE_VERSION:
      return {
        ...previousState,
        graphicVersionsLoading: false,
        errorMessage: action.message,
        successMessage: '',
      };
    case ERROR_DELETE_COLLABORATE_TASK:
      return {
        ...previousState,
        errorMessage: action.message,
        successMessage: '',
      };
    case SUCCESS_DELETE_COLLABORATE_TASK:
      return {
        ...previousState,
        tasks: previousState.tasks.filter(
          (task) => task.task_id !== action.taskId
        ),
        currentPageTasks: previousState.currentPageTasks.filter(
          (task) => task.task_id !== action.taskId
        ),
        successMessage: action.message,
        selectedGeneralTask: null,
        showPopover: !previousState.showPopover,
        currentPageCount: previousState.currentPageCount - 1,
        totalRecords: previousState.totalRecords - 1,
      };
    case DELETE_COLLABORATE_COMMENT:
      return {
        ...previousState,
        deleteCommentLoading: true,
        deleteCommentSuccess: '',
        deleteCommentError: '',
      };
    case SUCCESS_DELETE_COMMENT:
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: previousState.attributes.comments.map((comment) =>
            comment.id === action.id
              ? {
                  ...comment,
                  ...action.data,
                  comment_type: 'task_update',
                  comments: 'Comment has been deleted',
                  is_deleted: action.is_deleted,
                  updated_at: action.updated_at,
                }
              : comment
          ),
        },
        deleteCommentLoading: false,
        deleteCommentSuccess: action.message,
        deleteCommentError: '',
      };
    case ERROR_DELETE_COMMENT:
      return {
        ...previousState,
        deleteCommentLoading: false,
        deleteCommentError: action.message,
        deleteCommentSuccess: '',
      };
    case SUCCESS_APPROVE_PAGE:
      return {
        ...previousState,
        tasks: previousState.tasks.map((each) =>
          each.task_page_url === action.pageUrl
            ? {
                ...each,
                is_complete: action.taskComplete,
                task_status: action.taskComplete
                  ? 'complete'
                  : each.task_status,
              }
            : each
        ),
        currentPageTasks: previousState.currentPageTasks.map((each) =>
          action.taskComplete
            ? {
                ...each,
                is_complete: action.taskComplete,
                task_status: 'complete',
              }
            : each
        ),
        errorMessage: '',
        successMessage: action.message,
        isPageApproved: true,
        ...(previousState.itemDetails.type === 'graphic' && {
          itemDetails: {
            ...previousState.itemDetails,
            graphics: previousState.itemDetails?.graphics.map((graphic) =>
              graphic.id === action.id
                ? {
                    ...graphic,
                    is_approved: true,
                  }
                : graphic
            ),
          },
        }),
      };
    case ERROR_APPROVE_PAGE:
      return {
        ...previousState,
        errorMessage: action.message,
        successMessage: '',
      };
    case USER_REGISTRATION:
      return {
        ...previousState,
        userRegistrationLoading: true,
        userRegistrationSuccess: '',
        userRegistrationError: '',
      };
    case SOCIAL_USER_REGISTRATION:
      return {
        ...previousState,
        userRegistrationLoading: true,
        userRegistrationSuccess: '',
        userRegistrationError: '',
      };
    case SUCCESS_USER_REGISTRATION:
      let value;
      if (action?.siteId) {
        value = {
          ...action?.data,
          value: action?.data?.user_id,
          responsible: false,
          label: action?.data?.name,
          role: 'admin',
        };
      }
      return {
        ...previousState,
        userRegistrationLoading: false,
        userRegistrationSuccess: action.message,
        userRegistrationError: '',
        registrationStepTwo: true,
        ...(previousState.itemDetails?.users?.length === 0 &&
          action?.siteId && {
            itemDetails: {
              ...previousState.itemDetails,
              users: previousState.itemDetails?.users.concat(value),
              role: 'admin',
            },
          }),
      };
    case ERROR_USER_REGISTRATION:
      return {
        ...previousState,
        userRegistrationLoading: false,
        userRegistrationSuccess: '',
        userRegistrationError: action.message,
      };
    case USER_LOGIN:
      return {
        ...previousState,
        userLoginLoading: true,
        userLoginSuccess: '',
        userLoginError: '',
      };
    case SUCCESS_USER_LOGIN:
      return {
        ...previousState,
        userLoginLoading: false,
        userLoginSuccess: action.message,
        userLoginError: '',
        openLogin: false,
        changeUrlOnLogin: true,
      };
    case ERROR_USER_LOGIN:
      return {
        ...previousState,
        userLoginLoading: false,
        userLoginSuccess: '',
        userLoginError: action.message,
      };
    case OPEN_GENERAL_MODAL:
      return {
        ...previousState,
        openGeneralModal: true,
      };
    case CLOSE_GENERAL_MODAL:
      return {
        ...previousState,
        openGeneralModal: false,
      };
    case SET_POPOVER_POSITION:
      const popPos = {
        x: action.x,
        y: action.y,
      };
      return {
        ...previousState,
        popoverPosition: popPos,
      };
    case UPDATE_SHOW_POPOVER:
      return {
        ...previousState,
        showPopover: !previousState.showPopover,
        newTask: false,
        failedCommentCreationData: {},
        failedTaskCreationData: {},
      };
    case SET_OPEN_LOGIN:
      return {
        ...previousState,
        openLogin: action.value,
      };
    case SET_TASK_FOR_OPEN_TASK_BUTTON:
      return {
        ...previousState,
        taskForOpenTaskButton: action.task,
      };
    case SET_SITE_ID:
      return {
        ...previousState,
        siteIdFromMain: action.siteId,
      };
    case SUCCESS_UPDATE_SITE_URL:
      return {
        ...previousState,
        itemDetails: action.data,
        updateSiteSuccess: action.message,
      };
    case ERROR_UPDATE_SITE_URL:
      return {
        ...previousState,
        updateSiteError: action.message,
      };
    case UPGRADE_ACCOUNT:
      return {
        ...previousState,
        upgradeAccountLoading: true,
        upgradeAccountSuccess: '',
        upgradeAccountError: '',
      };
    case SUCCESS_UPGRADE_ACCOUNT:
      return {
        ...previousState,
        upgradeAccountLoading: false,
        upgradeAccountSuccess: action.message,
        upgradeAccountError: '',
      };
    case ERROR_UPGRADE_ACCOUNT:
      return {
        ...previousState,
        upgradeAccountLoading: false,
        upgradeAccountSuccess: '',
        upgradeAccountError: action.message,
      };
    case EDIT_COLLABORATE_COMMENT: {
      const { params, files } = action;
      const commentsObj = previousState.attributes.comments.map((c) => {
        if (c.id === params.comment_id) {
          const removedFilesFromComment = c.files.filter(
            (f) => !params.delete_files.includes(f.id)
          );
          const newFiles = files.map((file) => {
            return {
              name: file.name,
              size: file.size,
              type: file.type,
              url: file.data,
            };
          });
          return {
            ...c,
            comments: params.comment_content,
            is_edited: true,
            files: [...removedFilesFromComment, ...newFiles],
          };
        } else {
          return c;
        }
      });
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: commentsObj,
        },
      };
    }
    case SUCCESS_EDIT_COMMENT:
      const comments = previousState.attributes.comments.map((t) =>
        t.id === action.id
          ? {
              ...t,
              comments: action.comment_content,
              is_edited: action.is_edited,
              files: action.files,
            }
          : t
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: comments,
        },
      };
    case DELETE_COMMENT_FILE:
      const deletedFileIds = action.params.ids;
      const deletedFilesInComment = previousState.attributes.comments.map(
        (comment) => {
          if (comment.id === action.commentId) {
            const removedFiles = comment.files.filter(
              (f) => !deletedFileIds.includes(f.id)
            );
            return {
              ...comment,
              files: removedFiles,
            };
          } else {
            return comment;
          }
        }
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: deletedFilesInComment,
        },
      };
    case SUCCESS_UPDATE_GRAPHIC_TITLE: {
      const { id, title } = action.data;
      let index = -1;
      const obj = previousState.itemDetails.graphics?.map((d, i) => {
        if (d.id === id) {
          index = i;
          return {
            ...d,
            title,
          };
        } else return d;
      });
      if (index !== -1) {
        return {
          ...previousState,
          itemDetails: {
            ...previousState.itemDetails,
            graphics: obj,
          },
        };
      } else {
        return {
          ...previousState,
        };
      }
    }
    case ERROR_UPDATE_GRAPHIC_TITLE: {
      return {
        ...previousState,
        graphicTitleError: action.message,
      };
    }
    case SUCCESS_UPDATE_INTERNAL_TASK:
      const tasks = previousState.tasks.map((t) =>
        t.task_id === action.task_id
          ? {
              ...t,
              is_internal: action.is_internal,
            }
          : t
      );
      const currenttasks = previousState.currentPageTasks.map((t) =>
        t.task_id === action.task_id
          ? {
              ...t,
              is_internal: action.is_internal,
            }
          : t
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          is_internal: action.is_internal,
        },
        tasks: tasks,
        currentPageTasks: currenttasks,
      };
    case GET_GRAPHIC_VERSIONS: {
      return {
        ...previousState,
        graphicVersions: [],
        graphicVersionsLoading: true,
        graphicVersionsError: '',
        graphicVersionsSuccess: '',
        currentPageTasks: [],
      };
    }
    case SUCCESS_GET_GRAPHIC_VERSIONS: {
      return {
        ...previousState,
        graphicVersions: action.data,
        hotspotAnnotations: action.data.hotspots,
        graphicVersionsLoading: false,
        graphicVersionsError: '',
        graphicVersionsSuccess: action.message,
        // selectedGraphic: action.data.versions[0]
      };
    }
    case ERROR_GET_GRAPHIC_VERSIONS: {
      return {
        ...previousState,
        graphicVersionsError: action.message,
        graphicVersionsLoading: false,
        graphicVersionsSuccess: '',
      };
    }
    case ADD_GRAPHIC_VERSION: {
      return {
        ...previousState,
        addGraphicVersionLoading: true,
        addGraphicVersionError: '',
        addGraphicVersionSuccess: '',
        //currentPageTasks: []
      };
    }
    case SUCCESS_ADD_GRAPHIC_VERSION: {
      let graphics_array = previousState.itemDetails.graphics.map((graphic) => {
        if (graphic.id === action.data.id) {
          return {
            ...graphic,
            total_versions: graphic.total_versions + 1,
            image: action.data.versions[0].image,
            width: action.data.versions[0].width,
            height: action.data.versions[0].height,
          };
        } else return graphic;
      });
      return {
        ...previousState,
        itemDetails: {
          ...previousState.itemDetails,
          graphics: graphics_array,
        },
        graphicVersions: {
          ...previousState.graphicVersions,
          // versions: [action.data.versions[0]].concat(previousState.graphicVersions.versions)
          versions: action.data.versions,
        },
        selectedGraphic: graphics_array.filter(
          (graphic) => graphic.id === action.data.id
        )[0],
        addGraphicVersionLoading: false,
        addGraphicVersionError: '',
        addGraphicVersionSuccess: action.message,
      };
    }
    case ERROR_ADD_GRAPHIC_VERSION: {
      return {
        ...previousState,
        addGraphicVersionLoading: false,
        addGraphicVersionError: action.message,
        addGraphicVersionSuccess: '',
      };
    }
    case CREATE_GRAPHIC_TASK: {
      if (Object.keys(previousState.failedTaskCreationData).length > 0) {
        // if there is a failed task creation data
        // here we will apply retry logic instead of creating new one
        return {
          ...previousState,
          createTasklsLoading: true,
          failedTaskCreationData: {},
        };
      } else {
        let graphicTaskdataObj = action.params;
        // insert instant files
        const creatingTaskFileObj = action.files.map((file) => {
          return {
            name: file.name,
            size: file.size,
            type: file.type,
            url: file.data,
          };
        });
        graphicTaskdataObj.comments = [
          {
            comments: graphicTaskdataObj['task_comment_message'],
            is_deleted: false,
            is_edited: false,
            user_id: action.params.task_config_author_id,
            user_name: graphicTaskdataObj['task_config_author_name'],
            is_note: 0,
            days: '1s',
            comment_type: 'normal_text',
            is_guest: false,
            files: creatingTaskFileObj || [],
          },
        ];
        const newTasks = [graphicTaskdataObj, ...previousState.tasks];
        const newCurrentPageTasks = [
          graphicTaskdataObj,
          ...previousState.currentPageTasks,
        ];
        return {
          ...previousState,
          attributes: graphicTaskdataObj,
          createTasklsLoading: true,
          tasks: newTasks,
          currentPageTasks: newCurrentPageTasks,
        };
      }
    }
    case CREATE_HOTSPOT: {
      return {
        ...previousState,
        successCreateHotspot: '',
        errorCreateHotspot: '',
        loadingCreateHotspot: true,
      };
    }
    case SUCCESS_CREATE_HOTSPOT: {
      return {
        ...previousState,
        successCreateHotspot: action.message,
        errorCreateHotspot: '',
        loadingCreateHotspot: false,
        hotspotAnnotations: [...previousState.hotspotAnnotations, action.data],
      };
    }
    case ERROR_CREATE_HOTSPOT: {
      return {
        ...previousState,
        successCreateHotspot: '',
        errorCreateHotspot: action.message,
        loadingCreateHotspot: false,
      };
    }
    case SET_HOTSPOT_ANNOTATIONS: {
      return {
        ...previousState,
        hotspotAnnotations: action.data,
      };
    }
    case DELETE_HOTSPOT: {
      return {
        ...previousState,
        successDeleteHotspot: '',
        errorDeleteHotspot: '',
        loadingDeleteHotspot: true,
      };
    }
    case SUCCESS_DELETE_HOTSPOT: {
      return {
        ...previousState,
        successDeleteHotspot: action.message,
        errorDeleteHotspot: '',
        loadingDeleteHotspot: false,
        hotspotAnnotations: previousState.hotspotAnnotations.filter(
          (h) => h.id !== action.id
        ),
      };
    }
    case ERROR_DELETE_HOTSPOT: {
      return {
        ...previousState,
        successDeleteHotspot: '',
        errorDeleteHotspot: action.message,
        loadingDeleteHotspot: false,
      };
    }
    case SET_SELECTED_GRAPHIC: {
      return {
        ...previousState,
        selectedGraphic: action.data,
      };
    }
    case UPDATE_GODADDY_DONE:
      return {
        ...previousState,
        ispushingToGodaddy: true,
        pushToGodaddyError: '',
      };
    case ERROR_UPDATE_GODADDY_DONE:
      return {
        ...previousState,
        ispushingToGodaddy: false,
        pushToGodaddyError: action.message,
      };
    case ADD_NEW_CUSTOM_SITE:
      return {
        ...previousState,
        siteCreated: false,
        createdSiteId: '',
        addingNewSite: true,
        urlSource: null,
      };
    case SUCCESS_ADD_NEW_CUSTOM_SITE:
      return {
        ...previousState,
        successAddSiteMessage: action.message,
        errorAddSiteMessage: '',
        createdSiteId: action.data.site_id,
        siteCreated: true,
        addingNewSite: false,
        urlSource: action.urlSource,
      };
    case ERROR_ADD_NEW_CUSTOM_SITE:
      return {
        ...previousState,
        errorAddSiteMessage: action.message,
        successAddSiteMessage: '',
        siteCreated: false,
        createdSiteId: '',
        addingNewSite: false,
      };
    case START_INVITE_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteCollab: true,
        inviteCollabError: '',
        inviteCollabSuccess: '',
      };
    }
    case SUCCESS_START_INVITE_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteCollab: false,
        inviteCollabSuccess: action.message,
        inviteCollabError: '',
      };
    }
    case ERROR_START_INVITE_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteCollab: false,
        inviteCollabSuccess: '',
        inviteCollabError: action.message,
      };
    }
    case FETCH_INVITED_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteduser: true,
        invitedUserError: '',
        invitedUsers: [],
        // inviteCollabSuccess: "",
      };
    }
    case SUCCESS_FETCH_INVITED_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteduser: true,
        invitedUserError: '',
        invitedUsers: action.data,
      };
    }
    case ERROR_FETCH_INVITED_COLLAB: {
      return {
        ...previousState,
        isLoadingInviteduser: true,
        invitedUserError: action.message,
        invitedUsers: [],
      };
    }
    case SUCCESS_REMOVE_INVITED_COLLAB: {
      let newInvited = previousState.invitedUsers.filter(
        (each) => each.id !== action.userId
      );
      return {
        ...previousState,
        invitedUserError: '',
        invitedUsers: newInvited,
        inviteRemovedSuccess: action.message,
      };
    }
    case SUCCESS_RESEND_COLLAB_INVITE: {
      return {
        ...previousState,
        invitedUserError: '',
        inviteRemovedSuccess: action.message,
      };
    }
    case ERROR_REMOVE_INVITED_COLLAB: {
      return {
        ...previousState,
        invitedUserError: action.message,
        inviteRemovedSuccess: '',
      };
    }
    case ERROR_RESEND_COLLAB_INVITE: {
      return {
        ...previousState,
        invitedUserError: action.message,
        inviteRemovedSuccess: '',
      };
    }
    case OPEN_CLOSE_NAVBAR: {
      return {
        ...previousState,
        navInCollaborate: action.boolean,
      };
    }
    case COMMENT_EDIT_HISTORY: {
      return {
        ...previousState,
        commentHistoryLoading: true,
        commentEditHistory: [],
      };
    }
    case SUCCESS_COMMENT_EDIT_HISTORY: {
      return {
        ...previousState,
        commentHistoryLoading: false,
        commentEditHistory: action.data,
      };
    }
    case ERROR_COMMENT_EDIT_HISTORY: {
      return {
        ...previousState,
        commentHistoryLoading: false,
        commentEditHistory: [],
      };
    }
    case REMOVE_SELECTED_TASK: {
      return {
        ...previousState,
        selectedTask: null,
      };
    }
    case REMOVE_ITEM_DETAILS:
      return {
        ...previousState,
        // itemDetails: {},
        // itemDetailsLoading: true,
        siteCreated: false,
      };
    case SUCCESS_FETCH_PAGES:
      return {
        ...previousState,
        pages: action.pages,
      };
    case SUCCESS_ADD_PAGE:
      return {
        ...previousState,
        newlyAddedPage: action.data,
        pages: previousState.pages.concat(action.data),
      };
    case FETCH_PAGES:
      return {
        ...previousState,
        isLoadingPages: true,
        pages: [],
      };
    case SUCCESS_FETCH_ALL_PAGES:
      return {
        ...previousState,
        isLoadingPages: false,
        pages: action.pages,
      };
    case ERROR_FETCH_PAGES:
      return {
        ...previousState,
        isLoadingPages: false,
      };
    case DELETE_PAGE:
      const { pageId } = action;
      const deletedPageUpdatedData = previousState.pages.filter(
        (page) => page.id !== pageId
      );
      return {
        ...previousState,
        pages: deletedPageUpdatedData,
      };
    case SUCCESS_DELETE_PAGE:
      return {
        ...previousState,
        successDeletePage: action.message,
        errorDeletePage: '',
      };
    case SHOW_SHARE_POPUP:
      return {
        ...previousState,
        showSharePopup: action.value,
      };
    case GET_OPEN_TASK_NUMBER:
      return {
        ...previousState,
        openTasks: null,
      };
    case SUCCESS_GET_OPEN_TASK_NUMBER:
      return {
        ...previousState,
        openTasks: action.data,
      };
    case EMPTY_FAILED_TASK_DATA:
      return {
        ...previousState,
        failedCommentCreationData: {},
        failedTaskCreationData: {},
      };
    case SUCCESS_FETCH_DUMMY_TASKS:
      return {
        ...previousState,
        dummyTasks: action.data,
      };
    case UPDATE_DUMMY_TASKS:
      return {
        ...previousState,
        dummyTasks: previousState.dummyTasks.map((task) =>
          task.task_comment_id === action.taskCommentId
            ? {
                ...task,
                task_elementX: action.taskElementX,
                task_elementY: action.taskElementY,
              }
            : task
        ),
      };
    case UPDATE_CHANGE_URL_ON_LOGIN:
      return {
        ...previousState,
        changeUrlOnLogin: false,
      };
    case SET_2FA_COLLAB_STATUS:
      return {
        ...previousState,
        is2FAEnabled: action.status,
      };
    case SET_OPEN_REGISTER:
      return {
        ...previousState,
        openRegister: action.value,
        tasks: previousState.tasks.filter((task) => task.hasOwnProperty('id')),
        currentPageTasks: previousState.currentPageTasks.filter((task) =>
          task.hasOwnProperty('id')
        ),
      };
    case UPDATE_ITEM_DETAILS_ROLE:
      // update item details role when role is a guest
      return {
        ...previousState,
        itemDetails: {
          ...previousState.itemDetails,
          role:
            previousState.itemDetails.role === 'Guest'
              ? 'collaborator'
              : previousState.itemDetails.role,
        },
      };
    case STORE_COLLAB_STATE:
      return {
        ...previousState,
        collaborateState: action.params,
      };
    case UPDATE_GRAPHIC_TASK_COUNT: {
      const updatedGraphics = previousState?.itemDetails?.graphics.map(
        (graphic) => {
          if (graphic.id === previousState?.selectedGraphic?.id) {
            return {
              ...graphic,
              total_tasks: graphic.total_tasks + 1,
            };
          } else return graphic;
        }
      );
      const itemDetails = {
        ...previousState.itemDetails,
        graphics: updatedGraphics,
      };
      return {
        ...previousState,
        itemDetails: itemDetails,
      };
    }
    case UPDATE_PAGE_TASK_COUNT: {
      const updatedPage = previousState?.pages?.map((page) =>
        page?.iframe_url === action?.data?.task_page_url
          ? {
              ...page,
              total_tasks: action?.addPage
                ? page?.total_tasks + 1
                : page?.total_tasks - 1,
            }
          : page
      );
      return {
        ...previousState,
        pages: updatedPage,
      };
    }
    default:
      return previousState;
  }
};
export default collaborate;
