import React, { useEffect } from 'react';
// Styling
import { Button, Backdrop } from '@material-ui/core';
import ChromeStore from 'media/svgs/chrome-web-store-icon.svg';
import ChromeStoreFeatured from 'media/svgs/chrome-store-featured-icon.svg';
import {
  isChromeBrowser,
  isChromeExtensionInstalled,
} from 'utils/chromeExtension';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    background: 'rgba(241, 243, 245, .95)',
    alignItems: 'flex-start',
  },
  chromeRestartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 10,
    gap: 10,
    position: 'relative',
    top: '3vh',
  },
  installChrome: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  installChromeCtaWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  storeContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: "center",
    justifyContent: 'center',
  },
  intallCopy: {
    color: '#272D3C',
    whiteSpace: 'nowrap',
  },
  arrowImg: {
    width: 150,
  },
  storeContentWrapper: {
    position: 'relative',
  },
  contentContainer: {
    position: 'absolute',
    right: '-40px',
  },
  siteTitle: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '30px',
  },

  featuredCopy: {
    color: '#1e293b',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 17,
    fontWeight: 500,
    lineHeight: '24px',
  },
  chromeStoreImg: {
    width: '100%',
  },
}));

export default function GotoChromeBackdrop(props) {
  const { open, closeWidgets, close, handleContinueAddSite } = props;

  const classes = useStyles();
  const [extState, setExtState] = React.useState(false);

  useEffect(() => {
    const extInstalled = isChromeExtensionInstalled();
    const chromeBrowser = isChromeBrowser();
    if (!extInstalled && chromeBrowser) {
      setExtState(true);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const extInstalled = isChromeExtensionInstalled();
      const chromeBrowser = isChromeBrowser();
      // Check if the extension is installed and the browser is chrome
      if (extInstalled && chromeBrowser) {
        // Perform your action here
        close();
        closeWidgets(false);
        if (handleContinueAddSite) {
          handleContinueAddSite();
        }
        // You might clear the interval
        clearInterval(intervalId);
      }
    }, 1000); // Check every 1000 milliseconds (1 second)

    // Clear the interval on component unmount to prevent memory leaks
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Depend on value and targetValue to re-run the effect if they change

  const handleOpenDownloadLink = () => {
    // Threshold for small screens, e.g., less than 1200px
    const smallScreenThreshold = 1200;
    let features;

    // Check if the screen width is below the threshold
    if (window.innerWidth < smallScreenThreshold) {
      // For smaller screens, open the window in full screen or set minimum dimensions
      features = 'top=0,left=0,width=100,height=100';
    } else {
      // For larger screens, calculate dimensions as before
      let widthVW = 50; // 50% of the viewport width
      let heightVH = 50; // 50% of the viewport height
      let widthInPixels = window.innerWidth * (widthVW / 100);
      let heightInPixels = window.innerHeight * (heightVH / 100);
      let left = (window.innerWidth - widthInPixels) / 2;
      let top = 25;
      features = `width=${widthInPixels},height=${heightInPixels},top=${top},left=${left}`;
    }

    window.open(
      'https://chromewebstore.google.com/detail/atarim-visual-website-col/ekpmbeilnaahlccpgjchjjoonecikehf',
      '_blank',
      features
    );
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open && extState}>
        <div className="at-cs-chrome-store-card-container">
          <div className="at-cs-chrome-store-featured-card">
            <div className="at-cs-chrome-store-featured-card-footer">
              <img
                src={ChromeStore}
                alt="arrow"
                className={classes.chromeStoreImg}
              />
              <span className="">chrome web store</span>
            </div>
            <div className="at-cs-chrome-store-featured-card-copy">
              <img src={ChromeStoreFeatured} alt="" className="" /> Featured
            </div>
          </div>
          <div className={classes.chromeInstallContainer}>
            <div className={classes.installChrome}>
              <div className={classes.intallCopy}>
                Restart the installation?
              </div>
              <div className={classes.installChromeCtaWrapper}>
                <Button
                  className="at-cs-btn m-l-10 m-r-10 at-cs-chrome-widget-button-close"
                  onClick={() => close()}
                >
                  Not now
                </Button>
                <Button
                  className="at-cs-btn at-cs-chrome-widget-button"
                  onClick={handleOpenDownloadLink}
                >
                  Restart installation
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
