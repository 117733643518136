import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { locizeEditorPlugin } from 'locize';
import { initReactI18next } from 'react-i18next';

export const i18nInstance = i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector);

interface InitializeI18nProps {
  projectId: string;
  apiKey: string;
  isProduction: boolean;
  defaultNS?: string;
  debugLocize?: boolean;
}

export const initializeI18n = async ({
  projectId,
  apiKey,
  isProduction,
  defaultNS = 'common',
  debugLocize = false,
}: InitializeI18nProps) => {
  if (!isProduction && debugLocize) {
    i18nInstance.use(locizeEditorPlugin({ show: true }));
  }

  if (!i18nInstance.isInitialized) {
    await i18nInstance.init({
      fallbackLng: 'en',
      supportedLngs: ['en', 'fr-CA'],
      load: 'currentOnly',
      interpolation: { escapeValue: false },
      // debug: !isProduction,
      backend: {
        projectId,
        apiKey,
        referenceLng: 'en',
      },
      saveMissing: !isProduction,
      saveMissingTo: 'all',
      updateMissing: !isProduction,
      react: {
        useSuspense: true,
        bindI18n: 'languageChanged loaded',
      },
      defaultNS,
      ns: [
        'common',
        'projects',
        'boards',
        'inbox',
        'settings',
        'auth',
        'errors',
        'forms',
        'tooltips',
        'notifications',
        'collaborate',
        'time_entries',
        'workflows',
      ],
    });
  }

  return i18nInstance;
};

export default i18nInstance;
