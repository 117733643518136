// this file contains either constant URLs or function that return URLs

export const getFilterUrl = (
  isAllTasks,
  selectedWebsite = -1,
  emailSelected
) =>
  isAllTasks
    ? '/task/get-all-tasks'
    : emailSelected
      ? `/mailbox/tasks/${selectedWebsite}` // add check for email selected
      : `/site/tasks/${selectedWebsite}`;
