import API from '../api';

export function uploadFormImage(formData) {
  return API.post(`/file-upload`, formData);
}

export function fetchAllForms(selectedWorkspaceId) {
  const queryParams =
    selectedWorkspaceId != null ? `?workspace_id=${selectedWorkspaceId}` : '';
  return API.get(`/forms${queryParams}`);
}

export function fetchSingleForm(body, selectedWorkspaceId) {
  return API.get(
    `/forms/${body.id}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchAllFormTemplates(selectedWorkspaceId) {
  const queryParams =
    selectedWorkspaceId != null ? `?workspace_id=${selectedWorkspaceId}` : '';
  return API.get(`/forms/account/template${queryParams}`);
}

export function createForm(body, selectedWorkspaceId) {
  return API.post(
    `/forms/create${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    body
  );
}

export function createFormTemplate(body) {
  return API.post(`/forms/template/create`, body);
}

export function searchFormFunction(body) {
  return API.post(`/forms/search`, { query: body });
}

export function updateForm(body, selectedWorkspaceId) {
  return API.post(
    `/forms/update${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    body
  );
}

export function updateFormStatus(body) {
  return API.post(`/forms/status/${body.form_id}`, { status: body.status });
}

export function submitForm(body) {
  return API.post(`/forms/response/${body.form_id}`, { response: body.data });
}

export function fetchFormSubmission(body, selectedWorkspaceId) {
  return API.get(
    `/forms/response/${body.form_id}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function destroyForm(body) {
  return API.post(`/forms/delete/${body.form_id}`);
}

export function uploadFormFile(formData, formId) {
  return API.post(`forms/${formId}/file-upload`, formData, {
    'Content-Type': 'multipart/form-data',
  });
}
