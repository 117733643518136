import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { LOGOUT } from 'actions/user';
import React from 'react';
import { theme } from 'theme';

export const APP_NAME = 'Atarim!';

export const rocketId = 'UA-46362210-46';

export const maintenancePollingInterval = 1000 * 60 * 1;

// used in UserReducer, AddUser component
export const InviteStatus = {
  WAITING: 0,
  SENT: 1,
  NOT_SENT: -1,
  SENDING: 2,
};

export const RoleLabelBackend = {
  admin: 'Administrator',
  manager: 'Administrator',
  contributor: 'Contributor',
  collaborator: 'Collaborator',
};

export const RoleLabelFrontend = {
  manager: 'Administrator',
  contributor: 'Contributor',
};

export const AdminRoles = ['manager', 'admin'];

export const RealAdminValue = 'admin';

export const CONSTANT_TASK_LISTING_PAGE_LIMIT = 25;

export const ENTRIES_LISTING_PAGE_LIMIT = 25;

export const BOARD_DRAG_TYPES = {
  TASK_RECORD: 'TaskRecord',
};

export const LogoutObjectForPutEffect = {
  type: LOGOUT,
  message: 'User unauthenticated, please login again!',
  error: true,
};

export const ApiErrorFallbackMessage = 'Something went wrong, try again later!';

export const taskStepsSelectors = [
  'first-step',
  'second-step',
  'third-step',
  'fourth-step',
  'fifth-step',
  'sixth-step',
];

export const taskSteps = [
  {
    selector: `.${taskStepsSelectors[0]}`,
    content: `You can choose your project here, the first project in your dashboard is selected by default.`,
  },
  {
    selector: `.${taskStepsSelectors[1]}`,
    content: `After you've selected a project, you will see all related tasks here with the first being selected. Click "All projects" to see your tasks for all projects within your network.`,
  },
  {
    selector: `.${taskStepsSelectors[2]}`,
    content: React.createElement(
      'div',
      null,
      "Write your comment or reply here. Why don't you give it a go?",
      React.createElement('br', null),
      ' Post your test comment below now!'
    ),
    action: (node: Node) => {
      (node.childNodes[0] as HTMLElement).focus();
    },
    stepInteraction: true,
    position: 'top',
  },
  {
    selector: `.${taskStepsSelectors[3]}`,
    content: `You can start and stop this timer to track how long has been spent on a task. You can also manually edit the time, in case you didn't track it all.`,
  },
  {
    selector: `.${taskStepsSelectors[4]}`,
    content: `Change the task status and urgency here for each task.`,
  },
  {
    selector: `.${taskStepsSelectors[5]}`,
    content: React.createElement(
      React.Fragment,
      null,
      "Click here to go directly to this task on the live website. No need to login, it's automatic.",
      React.createElement('br', null),
      'Cool right? ',
      React.createElement(
        'span',
        {
          role: 'img',
          'aria-label': 'cool' /* U+1F60E */,
        },
        '\uD83D\uDE0E'
      ),
      React.createElement(
        'span',
        {
          role: 'img',
          'aria-label': 'y',
        },
        '\uD83D\uDC4D'
      )
    ),
    stepInteraction: true,
  },
];

export const BREAK_FILTER_COLUMN_TASKS_AT = 'md';
export const BREAK_WEBSITES_LISTINGS_COLUMN_TASKS_AT = 'sm';
export const START_MOBILE_SCREEN = 'xs';
export const TOO_LARGE_SCREEN = 'xl';
export const START_MOBILE_SCREEN_MEDIA_QUERY = theme.breakpoints
  .down(START_MOBILE_SCREEN)
  .split(' ')[1];
export const ABOVE_XS_MEDIA_QUERY = theme.breakpoints
  .up(BREAK_WEBSITES_LISTINGS_COLUMN_TASKS_AT)
  .split(' ')[1];
export const ABOVE_SM_MEDIA_QUERY = theme.breakpoints.up('md').split(' ')[1];
export const BELOW_MD_MEDIA_QUERY = theme.breakpoints.down('md').split(' ')[1];
export const START_TABLET_SCREEN_MEDIA_QUERY = theme.breakpoints
  .down('sm')
  .split(' ')[1];
export const END_TABLET_SCREEN_MEDIA_QUERY = theme.breakpoints
  .up('sm')
  .split(' ')[1];
export const START_MEDIUM_SCREEN_MEDIA_QUERY = theme.breakpoints
  .down('md')
  .split(' ')[1];
export const END_MEDIUM_SCREEN_MEDIA_QUERY = theme.breakpoints
  .up('md')
  .split(' ')[1];
export const START_LARGE_SCREEN_MEDIA_QUERY = theme.breakpoints
  .down('lg')
  .split(' ')[1];
export const END_LARGE_SCREEN_MEDIA_QUERY = theme.breakpoints
  .up('lg')
  .split(' ')[1];

export const DRAWER_WIDTH = 250;

export const statusIconLabel = {
  open: 'Open',
  'in-progress': 'In Progress',
  'pending-review': 'Pending Review',
  complete: 'Complete',
};

export const urgencyIconLabel = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  critical: 'Critical',
};

export const statusAvatarColor = {
  open: '#272D3C',
  'in-progress': '#ffa532',
  'pending-review': '#fcd227',
  complete: '#3ed696',
};

export const urgencyAvatarColor = {
  low: '#3ed696',
  medium: '#fcd227',
  high: '#ffa532',
  critical: '#ff5a48',
};

export const textColor = {
  open: '#fff',
  'in-progress': '#fff',
  'pending-review': '#272D3C',
  complete: '#272D3C',
};

export const statusAttribute = [
  {
    label: 'Open',
    selected: true,
    value: 'open',
  },
  {
    label: 'In Progress',
    selected: false,
    value: 'in-progress',
  },
  {
    label: 'Pending Review',
    selected: false,
    value: 'pending-review',
  },
  {
    label: 'Complete',
    selected: false,
    value: 'complete',
  },
];

export const urgencyAttribute = [
  {
    label: 'Low',
    selected: true,
    value: 'low',
  },
  {
    label: 'Medium',
    selected: false,
    value: 'medium',
  },
  {
    label: 'High',
    selected: false,
    value: 'high',
  },
  {
    label: 'Critical',
    selected: false,
    value: 'critical',
  },
];

export const automationTriggerColor = {
  TRIGGER1: '#7c6df4',
  TRIGGER2: '#7c6df4',
  TRIGGER3: '#279af1',
  TRIGGER4: '#3ed696',
  TRIGGER5: '#ffa532',
  TRIGGER6: '#3ed696',
  TRIGGER7: '#7c6df4',
  TRIGGER8: '#7c6df4',
};
export const automationActionColor = {
  ACTION1: '#7c6df4',
  ACTION2: '#7c6df4',
  ACTION3: '#7c6df4',
  ACTION4: '#e72d67',
  ACTION5: '#7c6df4',
  ACTION6: '#ffa532',
  ACTION7: '#3ed696',
  ACTION8: '#279af1',
  ACTION10: '#279af1',
  ACTION11: '#279af1',
  ACTION12: '#279af1',
  ACTION13: '#279af1',
  ACTION14: '#279af1',
  ACTION15: '#279af1',
  ACTION16: '#279af1',
  ACTION17: '#279af1',
  ACTION18: '#279af1',
  ACTION19: '#279af1',
};

export const itemList = [
  {
    name: "Contact's First Name",
    value: "{{contact.first_name | default: ' '}}",
    tip: 'This is the first name of the sender email address',
  },
  {
    name: 'Email',
    value: "{{contact.email | default: ' '}}",
    tip: 'The email address that is receiving this email',
  },
  {
    name: 'Urgency',
    value: "{{task.task_priority | default: ' '}}",
    tip: 'The urgency of the current task',
  },
  {
    name: 'Status',
    value: "{{task.task_status | default: ' '}}",
    tip: 'The status of the current task',
  },
  {
    name: 'Assignee',
    value: "{{teammate.name | default: ''}}",
    tip: 'The Atairm user who is assigned to this task',
  },
];

export const integrationPartnerList = [
  'whmcs',
  'chrome',
  'hostinger',
  'mainwp',
  'rocket',
  'instawp',
  'convesio',
  'godaddy',
  'dollie',
  'basekit',
];

export const defaultParams = {
  // textSearch: "",
  // notifiedUsers: "",
  taskOrder: '',
  orderBy: '',
  taskType: {},
};
export const filterPayload = {
  internal: {
    apply_filter: true,
    task_types: ['internal'],
  },
  complete: {
    apply_filter: true,
    'task_status[0]': 'complete',
  },
  open: {
    apply_filter: true,
    // "task_status[0]": "open",
    // "task_status[1]": "in-progress",
    // "task_status[2]": "pending-review",
  },
};

/**
 * This is data for the lock feature modal as we can open the same modal for different locked feature like approve page or invite users etc.
 */
// we can also reuse it in other places in app
export const lockModalData = {
  approvepage: {
    featureAvailable: [false, true, true, true],
  },
  invite: {
    featureAvailable: [false, true, true, true],
  },
  internal: {
    featureAvailable: [true, true, true, true],
  },
  editdelete: {
    featureAvailable: [true, true, true, true],
  },
};

export const InviteRoleLabelFrontend = [
  {
    name: 'A project manager or team member',
    type: 'Administrator',
    value: 'admin',
  },
  {
    name: 'A team member or contractor',
    type: 'Contributor',
    value: 'contributor',
  },
  {
    name: 'A stakeholder or client',
    type: 'Collaborator',
    value: 'collaborator',
  },
];

export const InviteRoleLabelFrontendWizard = [
  { name: 'Administrator', value: 'admin' },
  { name: 'Contributor', value: 'contributor' },
];

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#6D5DF3',
  },
}))(LinearProgress);

export const urlBoxFetchImage =
  'https://api.urlbox.io/v1/N2okA12ymiQKeGym/png?url=';

export const taskLimit = 100;

export const enterpriseClients = [9458, 70910];

export const teamSizeOptions = [
  { value: '1' },
  { value: '2-10' },
  { value: '11-50' },
  { value: '51-200' },
  { value: '201-500' },
  { value: '501-1000' },
  { value: '1001-5000' },
  { value: '5001-10000' },
  { value: '10000+' },
];

export const sourceOptions = [
  { name: 'Search Engine', value: 'search' },
  { name: 'Referral from a Colleague', value: 'referral' },
  { name: 'Online Ads', value: 'ad' },
  { name: 'Industry Event or Conference', value: 'event' },
  { name: 'Other', value: 'other' },
];

export const GENERIC_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'zoho.com',
  // Regional variants
  'yahoo.co.uk',
  'hotmail.co.uk',
  'outlook.co.uk',
  'yandex.ru',
  'yahoo.co.jp',
  // Other common domains
  'live.com',
  'me.com',
  'msn.com',
  'googlemail.com',
  'yahoo.fr',
  'yahoo.de',
  'yahoo.it',
  'yahoo.es',
  'yahoo.in',
];
