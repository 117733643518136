import { useCallback, useEffect } from 'react';

// Type definitions for the Gist window object
declare global {
  interface Window {
    gist?: {
      chat: (
        action: 'navigate' | 'hide' | 'show' | 'toggle',
        destination?: 'newConversation',
        message?: string
      ) => void;
    };
  }
}

// Configuration options for the hook
interface GistChatOptions {
  /**
   * Whether to hide the chat widget by default when it loads
   * @default true
   */
  hideByDefault?: boolean;
  /**
   * Message to pre-populate the chat with when opening
   * @default ''
   */
  defaultMessage?: string;
}

// Return type for the hook
interface GistChatControls {
  /**
   * Opens the chat widget with an optional message
   * @param message - Optional message to pre-populate the chat with
   */
  openChat: (message?: string) => void;
  /**
   * Closes the chat widget
   */
  closeChat: () => void;
  /**
   * Toggles the chat widget visibility
   */
  toggleChat: () => void;
  /**
   * Whether the Gist chat is loaded and ready to use
   */
  isReady: boolean;
}

/**
 * A React hook to handle Gist Messenger chat interactions
 * @param options - Configuration options for the chat
 * @returns Object containing chat control methods and status
 */
const useGistChat = ({
  hideByDefault = true,
  defaultMessage = '',
}: GistChatOptions = {}): GistChatControls => {
  // Function to check if Gist is loaded and ready
  const isGistReady = useCallback((): boolean => {
    return typeof window !== 'undefined' && !!window.gist;
  }, []);

  // Function to open a new conversation
  const openChat = useCallback(
    (message: string = defaultMessage): void => {
      if (!isGistReady()) {
        console.warn('Gist chat is not ready yet');
        return;
      }

      if (message) {
        window.gist?.chat('navigate', 'newConversation', message);
      } else {
        window.gist?.chat('navigate', 'newConversation');
      }
    },
    [defaultMessage, isGistReady]
  );

  // Function to close the chat
  const closeChat = useCallback((): void => {
    if (!isGistReady()) {
      console.warn('Gist chat is not ready yet');
      return;
    }

    window.gist?.chat('hide');
  }, [isGistReady]);

  // Function to toggle chat visibility
  const toggleChat = useCallback((): void => {
    if (!isGistReady()) {
      console.warn('Gist chat is not ready yet');
      return;
    }

    window.gist?.chat('toggle');
  }, [isGistReady]);

  useEffect(() => {
    // Handler for when Gist chat becomes ready
    const handleGistReady = (): void => {
      if (hideByDefault) {
        window.gist?.chat('hide');
      }
    };

    // Add event listener for gistChatReady
    document.addEventListener('gistChatReady', handleGistReady);

    // Cleanup
    return () => {
      document.removeEventListener('gistChatReady', handleGistReady);
    };
  }, [hideByDefault]);

  return {
    openChat,
    closeChat,
    toggleChat,
    isReady: isGistReady(),
  };
};

export default useGistChat;
