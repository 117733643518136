import { createStorage, readValue, StorageProperties } from './create-storage';

/**
 * Custom hook for managing session storage
 * @author Karim Bakkali
 * @example
 * // The hook will read value from sessionStorage.getItem('atarim-color-scheme')
 * // If sessionStorage is not available or value at a given key does not exist
 * // 'dark' will be assigned to value variable
 * const [value, setValue, removeValue] = useSessionStorage({
 *   key: 'atarim-color-scheme',
 *   defaultValue: 'dark',
 * });
 *
 * // Set a new value
 * setValue('light');
 *
 * // Update value based on current state
 * setValue((current) => (current === 'dark' ? 'light' : 'dark'));
 *
 * // Use removeValue callback to clean sessionStorage/localStorage.
 * // When value is removed it is reset to defaultValue: 'dark'
 * removeValue();
 */
export function useSessionStorage<T = string>(props: StorageProperties<T>) {
  return createStorage<T>('sessionStorage', 'use-session-storage')(props);
}

export const readSessionStorageValue = readValue('sessionStorage');
