import API from '../api';

export function storeProject(obj) {
  return API.post(`/projects/store`, obj);
}

export function fetchProjects(selectedWorkspaceId) {
  const queryParams =
    selectedWorkspaceId != null ? `?workspace_id=${selectedWorkspaceId}` : '';
  return API.get(`/projects${queryParams}`);
}

export function updateProject(obj) {
  return API.post(`/projects/update`, obj);
}

export function deleteProject(obj) {
  return API.post(`/projects/delete`, obj);
}
