// All Forms
export const GET_ALL_FORMS = 'GET_ALL_FORMS';
export const SUCCESS_GET_ALL_FORMS = 'SUCCESS_GET_ALL_FORMS';
export const ERROR_GET_ALL_FORMS = 'ERROR_GET_ALL_FORMS';
export const TOGGLE_HAS_GOTTEN_ALL_FORMS = 'TOGGLE_HAS_GOTTEN_ALL_FORMS';

// Single Form
export const GET_SINGLE_FORM = 'GET_SINGLE_FORM';
export const SUCCESS_GET_SINGLE_FORM = 'SUCCESS_GET_SINGLE_FORM';
export const ERROR_GET_SINGLE_FORM = 'ERROR_GET_SINGLE_FORM';
export const TOGGLE_HAS_GOTTEN_FORM = 'TOGGLE_HAS_GOTTEN_FORM';

// Form templates
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SUCCESS_SAVE_TEMPLATE = 'SUCCESS_SAVE_TEMPLATE';
export const ERROR_SAVE_TEMPLATE = 'ERROR_SAVE_TEMPLATE';
export const TOGGLE_HAS_CREATED_TEMPLATE = 'TOGGLE_HAS_CREATED_TEMPLATE';

export const GET_ALL_FORM_TEMPLATES = 'GET_ALL_FORM_TEMPLATES';
export const SUCCESS_GET_ALL_FORM_TEMPLATES = 'SUCCESS_GET_ALL_FORM_TEMPLATES';
export const ERROR_GET_ALL_FORM_TEMPLATES = 'ERROR_GET_ALL_FORM_TEMPLATES';

// Create Form
export const SAVE_FORM = 'SAVE_FORM';
export const SUCCESS_SAVE_FORM = 'SUCCESS_SAVE_FORM';
export const ERROR_SAVE_FORM = 'ERROR_SAVE_FORM';
export const TOGGLE_HAS_CREATED_FORM = 'TOGGLE_HAS_CREATED_FORM';

// Update Form
export const UPDATE_FORM = 'UPDATE_FORM';
export const SUCCESS_UPDATE_FORM = 'SUCCESS_UPDATE_FORM';
export const ERROR_UPDATE_FORM = 'ERROR_UPDATE_FORM';
export const TOGGLE_HAS_UPDATED_FORM = 'TOGGLE_HAS_UPDATED_FORM';

// Update Form status
export const UPDATE_FORM_STATUS = 'UPDATE_FORM_STATUS';
export const SUCCESS_UPDATE_FORM_STATUS = 'SUCCESS_UPDATE_FORM_STATUS';
export const ERROR_UPDATE_FORM_STATUS = 'ERROR_UPDATE_FORM_STATUS';
export const TOGGLE_HAS_UPDATED_FORM_STATUS = 'TOGGLE_HAS_UPDATED_FORM_STATUS';

// Delete Form
export const DELETE_FORM = 'DELETE_FORM';
export const SUCCESS_DELETE_FORM = 'SUCCESS_DELETE_FORM';
export const ERROR_DELETE_FORM = 'ERROR_DELETE_FORM';
export const TOGGLE_HAS_DELETED_FORM = 'TOGGLE_HAS_DELETED_FORM';

// Add Form Submission
export const ADD_FORM_SUBMISSION = 'ADD_FORM_SUBMISSION';
export const SUCCESS_ADD_FORM_SUBMISSION = 'SUCCESS_ADD_FORM_SUBMISSION';
export const ERROR_ADD_FORM_SUBMISSION = 'ERROR_ADD_FORM_SUBMISSION';

// Get Form Submission
export const GET_FORM_SUBMISSIONS = 'GET_FORM_SUBMISSIONS';
export const SUCCESS_GET_FORM_SUBMISSIONS = 'SUCCESS_GET_FORM_SUBMISSIONS';
export const ERROR_GET_FORM_SUBMISSIONS = 'ERROR_GET_FORM_SUBMISSIONS';
export const TOGGLE_HAS_GOTTEN_FORM_SUBMISSION =
  'TOGGLE_HAS_GOTTEN_FORM_SUBMISSION';

// Search Form
export const START_SEARCH_FORMS = 'START_SEARCH_FORMS';
export const ERROR_SEARCH_FORMS = 'ERROR_SEARCH_FORMS';
export const SUCCESS_SEARCH_FORMS = 'SUCCESS_SEARCH_FORMS';

export const SET_IS_ADD_FORM_OPEN = 'SET_IS_ADD_FORM_OPEN';
export const SET_IS_EDITING_FORM = 'SET_IS_EDITING_FORM';

export const RESET_DISCOVERY_FLOW = 'RESET_DISCOVERY_FLOW';
