import { NEW_TASK_SELECTED, NEW_WEBSITE_SELECTED } from "actions/tasks";
import { AnimatePresence, motion } from "framer-motion";
import { BarChart, Bell, ChevronRight, CircleHelp, Clock, Megaphone, Rocket, RocketIcon, Trophy, VenetianMask } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { Link, NavLink, useLocation, useSearchParams, } from "react-router-dom";
import {
    Button,
    DropdownMenu,
    Progress,
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarHeader,
    Tooltip
} from "ui/components";
import { SheetTrigger } from "ui/components/Sheet";
import FrillWidget from "./FrillWidget";

import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import useChecklistInjection from "@src/hooks/use-checklist-injection";
import useGistChat from '@src/hooks/use-gist-chat';
import { useGetNotLoggedEntriesQuery } from '@src/store/api/endpoints/time-entries';
import { HANDLE_PLANS_DIALOG } from 'actions/user';
import { useTranslation } from "react-i18next";
import { useProductFruitsApi } from "react-product-fruits";
import { NumberTicker } from 'ui/components/NumberTicker';
import { SidebarGroupContent, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarTrigger, useSidebar } from "ui/components/Sidebar";
import { cn } from 'utils/cn';
import { AdminRoles } from "utils/constants";
import { dayLeftForTrial } from "utils/functions";
import GotoChromeButtonV2 from "../GotoChromeButtonV2";
import TimerCollection from "../TimerCollection";
import Notifications from "./Notifications";
import Resources from './resources';
import { getMenuList } from "./sidenav-content";
import TrialStatus from './trial-status';
import UserSettings from "./user-settings-dropdown";
import WorkspaceDropdown from "./workspace-dropdown";
declare global {
    interface Window {
        productFruits?: {
            api?: any;
        };
    }
}

const list = {
    visible: {
        opacity: 1,
        height: 'auto',
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.05,
        },
    },
    hidden: {
        opacity: 0,
        height: 0,
        transition: {
            when: 'afterChildren',
        },
    },
};

const { application, workflow, help } = getMenuList();
const MotionButton = motion(Button);

function AppSidebar() {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { openChat } = useGistChat();
    const [isChecklistVisible, setIsChecklistVisible] = useState(false);
    const [api, setApi] = useState(null);
    const { checklistRef, progress } = useChecklistInjection({
        checklistId: 2560,
        isChecklistVisible,
        api,
    });
    useProductFruitsApi((receivedApi) => {
        setApi(receivedApi);
    }, []);
    const { userTrialData, userData } = useAppSelector((state) => state.user);

    const {
        user,
        notificationDetails,
        moreUnreadWebRecords,
        moreReadWebRecords,
        muteState,
        readNotifications,
        unreadNotifications,
        readNotificationsOffsetCounter,
        unreadNotificationsOffsetCounter,
        updatingNotification,
        navInCollaborate,
    } = useAppSelector((state: any) => ({
        selectedIndex: state.user.selectedIndex,
        user: state.user.userData,
        token: state.user.token,
        notificationDetails: state.user.notificationDetails,
        moreUnreadWebRecords: state.user.moreUnreadWebRecords,
        moreReadWebRecords: state.user.moreReadWebRecords,
        unreadCount: state.user.unreadCount,
        muteState: state.user.muteState,
        readNotifications: state.user.readNotifications,
        unreadNotifications: state.user.unreadNotifications,
        readNotificationsOffsetCounter: state.user.readNotificationsOffsetCounter,
        unreadNotificationsOffsetCounter:
            state.user.unreadNotificationsOffsetCounter,
        isNotificationsModalOpen: state.user.isNotificationsModalOpen,
        navInCollaborate: state.collaborate.navInCollaborate,
        menuCollapse: state.user.menuCollapse,
        isPlansDialogOpened: state.user.isPlansDialogOpened,
        selectedWorkspace: state.workspace.selectedWorkspace,
        isLoading: state.user.isLoading,
        updatingNotification: state.user.updatingNotification,
        //Timer Entries
        selectedTimerEntry: state.time_entries.selectedTimerEntry,
        currentTimerTaskId: state.time_entries.currentTimerTaskId,
        timerPlay: state.time_entries.timerPlay,
        workspace: state.workspace.workspace,
    }));
    const trialEndedUpgrade = userTrialData.activation_date && dayLeftForTrial(userTrialData.activation_date) <= 17 && user.role === 'admin' && user.trial.status === 'expired';
    const { selectedWorkspace } = useAppSelector((state) => state.workspace);
    const dispatch = useAppDispatch();
    const [openSheet, setOpenSheet] = useState(false);
    const { t } = useTranslation(['common'], { keyPrefix: 'sidebar' });
    const websiteSelected = useCallback(
        (websiteID: string) => {
            const event = new CustomEvent(NEW_WEBSITE_SELECTED, { detail: { websiteId: websiteID } });
            dispatchEvent(event);
        },
        []
    );
    const selectTask = useCallback(
        (taskId: string) => {
            const event = new CustomEvent(NEW_TASK_SELECTED, { detail: { taskId: taskId } });
            dispatchEvent(event);
        },
        []
    );

    const isUserAdmin = () => {
        return AdminRoles.indexOf(selectedWorkspace.role) !== -1;
    };

    const taskParams = useMemo(() => {
        const websiteId = searchParams.get('website') || searchParams.get('item');

        const params = new URLSearchParams();

        if (websiteId) params.append('website', websiteId);

        return params;
    }, [searchParams, location.search]);

    const { open } = useSidebar()

    const handleNavigateToUpgrade = () => {
        if (user.plan.from_free_plan && user.subscription) {
            dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
        } else {
            const upgradeUrl =
                user.plan.upsell_url ||
                `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade?_from=${user.source}`;
            window.open(upgradeUrl);
        }
    };

    const { data: notLoggedEntries } = useGetNotLoggedEntriesQuery(
        selectedWorkspace?.id ?? skipToken
    );


    const isFreePlan = useMemo(() => {
        const plan: string = user?.plan?.title;
        if (!plan) return false;
        if ((plan.toLowerCase().includes('free') || plan.toLowerCase().includes('trial')) && userData.role === 'admin') {
            return true;
        } else {
            return false;
        }
    }, [user.plan.title]);

    return (
        <AnimatePresence mode="wait">
            {!navInCollaborate && location.pathname === '/collaborate' ?
                null
                :
                <motion.div
                    key="sidebar"
                    initial={{ width: 0, x: open ? -250 : -60 }}
                    animate={{
                        width: open ? "250px" : "60px",
                        x: 0
                    }}
                    exit={{
                        width: 0,
                        x: open ? -250 : -60
                    }}
                    transition={{
                        duration: 0.2
                    }}
                >
                    <Sidebar collapsible="icon">
                        <SidebarHeader className={cn('pb-0 flex', open ? 'justify-start items-start' : 'justify-center items-center')}>
                            <AnimatePresence mode="wait">
                                {open ? (
                                    <motion.div
                                        key="full-logo"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0, transition: { duration: 0 } }}
                                        transition={{ duration: 0.2 }}
                                        className="w-fit h-fit"
                                    >
                                        <img
                                            className="w-32 p-2 m-0 h-12 object-contain"
                                            alt="Atarim Logo"
                                            src="assets/Logo-Atarim-Login.svg"
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        key="icon-logo"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className="w-fit h-fit"
                                    >
                                        <img
                                            className="w-10 p-1 m-0 h-12 object-contain"
                                            alt="Atarim Logo"
                                            src="assets/favicon.svg"
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <SidebarTrigger className={cn('bg-transparent hover:bg-transparent h-9 w-9', open && 'right-2 absolute top-5')} />
                        </SidebarHeader>
                        <SidebarContent>
                            <SidebarGroup>
                                <WorkspaceDropdown />
                            </SidebarGroup>
                            <SidebarGroup className="pt-0">
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        {application.map((item) => {

                                            // Modify the NavLink for tasks and boards to include taskParams
                                            const getItemUrl = () => {
                                                if (item.url === '/tasks') {
                                                    return `${item.url}?${taskParams.toString()}`;
                                                }
                                                if (item.url === '/board') {
                                                    const boardParams = new URLSearchParams(taskParams.toString());
                                                    boardParams.append('board', 'status');
                                                    return `${item.url}?${boardParams.toString()}`;
                                                }
                                                return item.url;
                                            };

                                            return (
                                                <Tooltip key={item.title} content={item.title} side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <SidebarMenuItem className="w-full">
                                                        <NavLink to={getItemUrl()}>
                                                            {({ isActive }) => (
                                                                <SidebarMenuButton isActive={isActive} className={cn('relative', open ? 'p-2' : 'p-[10px]')}>
                                                                    <item.icon size={18} />
                                                                    <span className={cn(item.title === 'Inbox' && !open && 'truncate')}>{t(item.title.toLowerCase(), item.title)}</span>
                                                                    {/* TODO: Implement inbox count */}
                                                                    {/* {item.title === 'Inbox' && globalSettings?.mailbox_setting?.is_verified && (
                                                            <>
                                                                {open ? (
                                                                    <div className="flex items-center absolute right-4">
                                                                        <Button tooltip="Refresh task list" variant="ghost" size="sm" className="hover:text-white px-2" leftIcon={<RefreshCw size={16} />}>
                                                                        </Button>
                                                                        <span className="">
                                                                            {project?.tasks_count ?? 0}
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <span className={cn('absolute bg-destructive right-0.5 top-0.5 h-2 w-2 rounded-xl', (project?.tasks_count ?? 0) > 1 ? '' : 'hidden')}></span>
                                                                )}
                                                            </>
                                                        )} */}
                                                                </SidebarMenuButton>
                                                            )}
                                                        </NavLink>
                                                    </SidebarMenuItem>
                                                </Tooltip>
                                            );
                                        })}
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </SidebarGroup>
                            <SidebarGroup >
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        <SidebarMenuItem className="w-full">
                                            <DropdownMenu>
                                                <Tooltip content={`${notLoggedEntries?.length} Active Timers`} side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <DropdownMenu.Trigger asChild>
                                                        <SidebarMenuButton className={cn('hover:text-white', open ? 'p-2' : 'p-[10px]')}>
                                                            <Clock />
                                                            {(notLoggedEntries?.length ?? 0) > 0 && !open && (
                                                                <motion.div
                                                                    initial={{ scale: 0 }}
                                                                    animate={{ scale: [0, 1.2, 1] }}
                                                                    exit={{ scale: 0 }}
                                                                    transition={{ duration: 0.3, spring: 0.5 }}
                                                                    className={cn(' bg-destructive absolute h-2 w-2 rounded-full top-[8px] right-[8px]')}></motion.div>
                                                            )}
                                                            <span className="truncate whitespace-nowrap overflow-hidden">Time</span>

                                                            <span className="ml-auto flex gap-2 items-center">
                                                                {(notLoggedEntries?.length ?? 0) > 0 && (
                                                                    <NumberTicker value={notLoggedEntries?.length ?? 0} />
                                                                )}
                                                                <ChevronRight size={16} />
                                                            </span>

                                                        </SidebarMenuButton>
                                                    </DropdownMenu.Trigger>
                                                </Tooltip>
                                                <DropdownMenu.Content side="right" align="start" className="min-w-[250px] p-3">
                                                    <TimerCollection />
                                                </DropdownMenu.Content>
                                            </DropdownMenu>
                                        </SidebarMenuItem>
                                        {/* TIME ENTRY/WORKFLOW/PEOPLE/ANALYTICS/SETTINGS */}
                                        {workflow.map((item) => {
                                            const isAdminOnly = item.title === 'People' || item.title === 'Settings';

                                            return (!isAdminOnly || isUserAdmin()) ? (
                                                <Tooltip content={item.title} key={item.title} side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <SidebarMenuItem className="w-full">
                                                        <NavLink to={item.url}>
                                                            {({ isActive }) => (
                                                                <SidebarMenuButton isActive={isActive} className={cn(open ? 'p-2' : 'p-[10px]')}>
                                                                    <item.icon size={18} />
                                                                    <span className="truncate w-full">{t(item.title.toLowerCase(), item.title)}</span>
                                                                </SidebarMenuButton>
                                                            )}
                                                        </NavLink>
                                                    </SidebarMenuItem>
                                                </Tooltip>
                                            ) : null;
                                        })}
                                        {/* IMPERSONATE USER */}
                                        {user?.user_permissions?.can_impersonate && (
                                            <SidebarMenuItem className="w-full">
                                                <Tooltip content="Impersonate User" side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <NavLink to="/impersonate-user" >
                                                        {({ isActive }) => (
                                                            <SidebarMenuButton isActive={isActive} className={cn(open ? 'p-2' : 'p-[10px]')}>
                                                                <VenetianMask size={18} />
                                                                <span className="truncate overflow-hidden whitespace-nowrap flex items-center">Impersonate</span>
                                                            </SidebarMenuButton>
                                                        )}
                                                    </NavLink>
                                                </Tooltip>
                                            </SidebarMenuItem>
                                        )}
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </SidebarGroup>
                        </SidebarContent>
                        <SidebarFooter className={cn(open ? 'p-2' : 'px-0')}>
                            <SidebarGroup className='p-0'>
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        {isFreePlan && trialEndedUpgrade && (
                                            <SidebarMenuItem className="w-full">
                                                <Tooltip content="Upgrade Account" side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <SidebarMenuButton variant={"tertiary"} className={cn(open ? 'p-2' : 'p-[10px]',)} onClick={handleNavigateToUpgrade}>
                                                        <RocketIcon size={18} />
                                                        <span className="overflow-hidden whitespace-nowrap flex items-center">Upgrade Account</span>
                                                    </SidebarMenuButton>
                                                </Tooltip>
                                            </SidebarMenuItem>
                                        )}
                                        {isUserAdmin() && (
                                            <SidebarMenuItem className="w-full">
                                                <DropdownMenu open={isChecklistVisible} onOpenChange={setIsChecklistVisible}>
                                                    <Tooltip content="Tour Checklist" side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                        <DropdownMenu.Trigger asChild>
                                                            <SidebarMenuButton className={cn(open ? 'p-2' : 'p-[10px]')}>
                                                                <Trophy size={18} />
                                                                <span className="overflow-hidden whitespace-nowrap flex items-center justify-between w-full gap-2">
                                                                    How to Win
                                                                    {/* {progress > 0 && progress < 100 && <CircularProgress progress={progress} />} */}
                                                                    {progress > 0 && progress < 100 && <Progress className="px-1" size="sm" variant="secondary" progress={progress} />}
                                                                </span>
                                                                <ChevronRight className="ml-auto" />
                                                            </SidebarMenuButton>
                                                        </DropdownMenu.Trigger>
                                                    </Tooltip>
                                                    <DropdownMenu.Content side="right" align="start" className="max-w-[560px] max-h-[60vh] overflow-auto p-0 mb-4">
                                                        <div ref={checklistRef} id="pf-checklist" className="flex flex-col items-end w-full rounded-md "></div>
                                                    </DropdownMenu.Content>
                                                </DropdownMenu>
                                            </SidebarMenuItem>
                                        )}
                                        <SidebarMenuItem className="w-full">
                                            <DropdownMenu>
                                                <Tooltip content="Help" side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <DropdownMenu.Trigger asChild>
                                                        <SidebarMenuButton className={cn(open ? 'p-2' : 'p-[10px]')}>
                                                            <CircleHelp size={18} />
                                                            <span className="overflow-hidden whitespace-nowrap flex items-center">Help</span>
                                                            <ChevronRight className="ml-auto" />
                                                        </SidebarMenuButton>
                                                    </DropdownMenu.Trigger>
                                                </Tooltip>
                                                {/* HELP/RESOURCES FOOTER ELEMENTS */}
                                                <DropdownMenu.Content side="right" align="start" className="min-w-[250px] p-3">
                                                    {help.map((item) => (
                                                        <DropdownMenu.Item asChild key={item.title}>
                                                            {item.title === 'Start a Live Chat' ? (
                                                                <Button
                                                                    variant="ghost"
                                                                    size="sm"
                                                                    className="h-8 w-full gap-2 justify-start font-normal outline-none focus-visible:ring-0"
                                                                    onClick={() => openChat()}
                                                                >
                                                                    <item.icon size={18} />
                                                                    <span className="overflow-hidden whitespace-nowrap flex items-center">{t(item.title.toLowerCase(), item.title)}</span>
                                                                </Button>
                                                            ) : (
                                                                <Link to={item.url} target={item.target} className={item.title === 'Start a Live Chat' ? 'gist-open-chat' : ''}>
                                                                    <item.icon size={18} />
                                                                    <span className="overflow-hidden whitespace-nowrap flex items-center">{t(item.title.toLowerCase(), item.title)}</span>
                                                                </Link>
                                                            )}
                                                        </DropdownMenu.Item>
                                                    ))}
                                                    <DropdownMenu.Item asChild >
                                                        <Button variant="ghost" size="sm" onClick={() => setOpenSheet(true)} className="h-8 w-full gap-2 justify-start font-normal outline-none focus-visible:ring-0" >
                                                            <Megaphone size={18} />
                                                            <span className="overflow-hidden whitespace-nowrap flex items-center ">What's New</span>
                                                        </Button>
                                                    </DropdownMenu.Item>
                                                </DropdownMenu.Content>
                                            </DropdownMenu>
                                            {/* Whats New Sheet */}
                                            <Sheet open={openSheet} onOpenChange={setOpenSheet} >
                                                <SheetContent side={'left'} className={'p-0'}>
                                                    <SheetHeader className={'px-2 py-2'}>
                                                        <SheetTitle>What's New</SheetTitle>
                                                    </SheetHeader>
                                                    <FrillWidget />
                                                </SheetContent>
                                            </Sheet>
                                        </SidebarMenuItem>
                                        <SidebarMenuItem className="w-full">
                                            <DropdownMenu>
                                                <Tooltip content="Resources" side="right" sideOffset={10} className={cn(open ? 'hidden' : '')} portal>
                                                    <DropdownMenu.Trigger asChild>
                                                        <SidebarMenuButton className={cn(open ? 'p-2' : 'p-[10px]')}>
                                                            <BarChart />
                                                            <span className="whitespace-nowrap overflow-hidden">Resources</span>
                                                            <ChevronRight className="ml-auto" />
                                                        </SidebarMenuButton>
                                                    </DropdownMenu.Trigger>
                                                </Tooltip>
                                                <DropdownMenu.Content side="right" align="start" alignOffset={20} className="min-w-[250px] p-3 mb-2">
                                                    <motion.div
                                                        className="flex flex-col gap-2"
                                                        initial="hidden"
                                                        animate="visible"
                                                        exit="hidden"
                                                        variants={list}
                                                    >
                                                        <Resources />
                                                        {isUserAdmin() && (
                                                            <DropdownMenu.Item asChild>
                                                                <MotionButton
                                                                    leftIcon={<Rocket size={18} />}
                                                                    className="mt-2 focus:bg-primary        focus:text-secondary"
                                                                    variant="primary"
                                                                    onClick={handleNavigateToUpgrade}
                                                                    initial={{ opacity: 0, y: 36 }}
                                                                    animate={{ opacity: 1, y: 0 }}
                                                                    exit={{ opacity: 0, y: 36 }}
                                                                    transition={{ duration: 0.2, delay: 0.85 }}
                                                                    size="sm"
                                                                >
                                                                    Increase Resources
                                                                </MotionButton>
                                                            </DropdownMenu.Item>
                                                        )}
                                                    </motion.div>
                                                </DropdownMenu.Content>
                                            </DropdownMenu>
                                        </SidebarMenuItem>

                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </SidebarGroup>
                            <SidebarGroup className="p-0 ">
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        {/* TRIAL WIDGET */}
                                        {open && <TrialStatus />}
                                        {/* CHROME WIDGET */}
                                        {open && <GotoChromeButtonV2 />}
                                        <div className={cn('hover:bg-transparent mt-2 flex flex-row gap-1 items-center w-full ', !open && 'flex-col-reverse')}>
                                            <UserSettings />
                                            {/* Notifications Sheet */}
                                            {user.role !== 'collaborator' && (
                                                <Sheet>
                                                    <SheetTrigger asChild>
                                                        <Button
                                                            variant="ghost"
                                                            size="icon"
                                                            className={cn('h-9 w-9 hover:bg-tertiary hover:text-white relative', open && 'h-10 w-10 min-w-10')}
                                                            tooltip="Notifications"
                                                            tooltipSide="right"
                                                            tooltipAlignOffset={10}
                                                            toolTipPortal
                                                        >
                                                            {unreadNotifications.length > 0 && (
                                                                <motion.div
                                                                    initial={{ scale: 0 }}
                                                                    animate={{ scale: [0, 1.2, 1] }}
                                                                    exit={{ scale: 0 }}
                                                                    transition={{ duration: 0.3, spring: 0.5 }}
                                                                    className={cn('bg-destructive absolute h-2 w-2 rounded-full', open ? 'top-[10px] right-[10px]' : 'top-[8px] right-[8px]')}>
                                                                </motion.div>
                                                            )}
                                                            <Bell size={18} />
                                                        </Button>
                                                    </SheetTrigger>
                                                    <SheetContent side="left" className='flex flex-col p-0 transition-all duration-7 gap-2'>
                                                        <SheetHeader className="px-2 pt-2 md:px-4">
                                                            <SheetTitle>Notifications</SheetTitle>
                                                        </SheetHeader>
                                                        <Notifications
                                                            moreUnreadWebRecords={moreUnreadWebRecords}
                                                            moreReadWebRecords={moreReadWebRecords}
                                                            notificationDetails={notificationDetails}
                                                            muteState={muteState}
                                                            readNotifications={readNotifications}
                                                            unreadNotifications={unreadNotifications}
                                                            readNotificationsOffsetCounter={
                                                                readNotificationsOffsetCounter
                                                            }
                                                            unreadNotificationsOffsetCounter={
                                                                unreadNotificationsOffsetCounter
                                                            }
                                                            updatingNotification={updatingNotification}
                                                            selectTask={selectTask}
                                                            websiteSelected={websiteSelected}
                                                        />
                                                    </SheetContent>
                                                </Sheet>
                                            )}
                                        </div>
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </SidebarGroup>
                        </SidebarFooter >
                    </Sidebar >
                </motion.div>
            }
        </AnimatePresence>
    )

}

export default AppSidebar;


interface CircularProgressProps {
    progress: number;
    size?: number;
    strokeWidth?: number;
}

const CircularProgress = ({ progress, size = 28, strokeWidth = 2 }: CircularProgressProps) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
        <div className="relative inline-flex items-center">
            <svg
                className="transform -rotate-90"
                width={size}
                height={size}
            >
                {/* Background circle */}
                <circle
                    className="text-gray-200"
                    stroke="currentColor"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={radius}
                    cx={size / 2}
                    cy={size / 2}
                />
                {/* Progress circle */}
                <circle
                    className="text-ata-purple transition-all duration-300 ease-in-out"
                    stroke="currentColor"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    r={radius}
                    cx={size / 2}
                    cy={size / 2}
                />
            </svg>
            <span className="absolute inset-0 flex items-center justify-center text-[10px] font-medium">
                {progress}%
            </span>
        </div>
    );
};