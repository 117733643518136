import {
  ADD_GRAPHIC_VERSION,
  ADD_NEW_CUSTOM_SITE,
  ADD_NEW_SITE,
  ADD_PAGE,
  ADD_STAMDARD_FLOW_SITE,
  APPROVE_A_PAGE,
  ATTACH_FILE_UPLOAD,
  COMMENT_EDIT_HISTORY,
  CREATE_ANNOTATED_TASK,
  CREATE_GRAPHIC_TASK,
  CREATE_HOTSPOT,
  CREATE_SITE_TASK,
  CREATE_TASK_COMMENT,
  DELETE_COLLABORATE_COMMENT,
  DELETE_COLLABORATE_TASK,
  DELETE_COMMENT_FILE,
  DELETE_DESIGN,
  DELETE_HOTSPOT,
  DELETE_PAGE,
  DELETE_VERSION,
  EDIT_COLLABORATE_COMMENT,
  ERROR_ADD_GRAPHIC_VERSION,
  ERROR_ADD_NEW_CUSTOM_SITE,
  ERROR_ADD_NEW_SITE,
  ERROR_ADD_PAGE,
  ERROR_APPROVE_PAGE,
  ERROR_ATTRIBUTES_FETCHING,
  ERROR_COMMENT_EDIT_HISTORY,
  ERROR_CREATE_ANNOTATED_TASK,
  ERROR_CREATE_HOTSPOT,
  ERROR_CREATE_SITE_TASK,
  ERROR_CREATE_TASK_COMMENT,
  ERROR_DELETE_COLLABORATE_TASK,
  ERROR_DELETE_COMMENT,
  ERROR_DELETE_COMMENT_FILE,
  ERROR_DELETE_DESIGN,
  ERROR_DELETE_HOTSPOT,
  ERROR_DELETE_VERSION,
  ERROR_EDIT_COMMENT,
  ERROR_FETCH_DUMMY_TASKS,
  ERROR_FETCH_INVITED_COLLAB,
  ERROR_FETCH_NEXT_CURRENT_PAGE_TASK,
  ERROR_FETCH_NEXT_SITE_TASKS,
  ERROR_FETCH_PAGES,
  ERROR_GET_GRAPHIC_VERSIONS,
  ERROR_GET_ITEM_DETAILS,
  ERROR_GET_OPEN_TASK_NUMBER,
  ERROR_GET_SITE_CURRENT_PAGE_TASK,
  ERROR_GET_SITE_TASKS,
  ERROR_REMOVE_INVITED_COLLAB,
  ERROR_RESEND_COLLAB_INVITE,
  ERROR_START_INVITE_COLLAB,
  ERROR_UPDATE_GODADDY_DONE,
  ERROR_UPDATE_GRAPHIC_TITLE,
  ERROR_UPDATE_HOTSPOT,
  ERROR_UPDATE_SITE_URL,
  ERROR_UPGRADE_ACCOUNT,
  ERROR_USER_LOGIN,
  ERROR_USER_REGISTRATION,
  EXTENSION_METRICS,
  FETCH_DUMMY_TASKS,
  FETCH_INVITED_COLLAB,
  FETCH_NEXT_SITE_TASKS,
  FETCH_NEXT_THIS_PAGE_TASK,
  FETCH_PAGES,
  GET_GRAPHIC_CURRENT_GRAPHIC_TASK,
  GET_GRAPHIC_VERSIONS,
  GET_ITEM_DETAILS,
  // SUCCESS_DELETE_COMMENT_FILE,
  GET_OPEN_TASK_NUMBER,
  GET_SITE_TASKS,
  GET_SITE_THIS_PAGE_TASK,
  MAKE_TAKE_SCREENSHOT_FALSE,
  NEW_COLLABORATE_TASK_SELECTED,
  REMOVE_INVITED_COLLAB,
  RESEND_COLLAB_INVITE,
  START_INVITE_COLLAB,
  SUCCESS_ADD_GRAPHIC_VERSION,
  SUCCESS_ADD_NEW_CUSTOM_SITE,
  SUCCESS_ADD_NEW_SITE,
  SUCCESS_ADD_PAGE,
  SUCCESS_APPROVE_PAGE,
  SUCCESS_ATTACH_FILE_UPLOAD,
  SUCCESS_ATTRIBUTES_FETCHING,
  SUCCESS_COMMENT_EDIT_HISTORY,
  SUCCESS_CREATE_ANNOTATED_TASK,
  SUCCESS_CREATE_HOTSPOT,
  SUCCESS_CREATE_SITE_TASK,
  SUCCESS_CREATE_TASK_COMMENT,
  SUCCESS_DELETE_COLLABORATE_TASK,
  SUCCESS_DELETE_COMMENT,
  SUCCESS_DELETE_DESIGN,
  SUCCESS_DELETE_HOTSPOT,
  SUCCESS_DELETE_PAGE,
  SUCCESS_DELETE_VERSION,
  SUCCESS_EDIT_COMMENT,
  SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK,
  SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK,
  SUCCESS_FETCH_ALL_PAGES,
  SUCCESS_FETCH_DUMMY_TASKS,
  SUCCESS_FETCH_INVITED_COLLAB,
  SUCCESS_FETCH_NEXT_SITE_TASKS,
  SUCCESS_GET_GRAPHIC_VERSIONS,
  SUCCESS_GET_ITEM_DETAILS,
  SUCCESS_GET_OPEN_TASK_NUMBER,
  SUCCESS_GET_SITE_TASKS,
  SUCCESS_REMOVE_INVITED_COLLAB,
  SUCCESS_RESEND_COLLAB_INVITE,
  SUCCESS_START_INVITE_COLLAB,
  SUCCESS_UPDATE_GRAPHIC_TITLE,
  SUCCESS_UPDATE_HOTSPOT,
  SUCCESS_UPDATE_SITE_URL,
  SUCCESS_UPDATE_STATUS,
  SUCCESS_UPGRADE_ACCOUNT,
  SUCCESS_USER_LOGIN,
  SUCCESS_USER_REGISTRATION,
  UPDATE_GODADDY_DONE,
  UPDATE_GRAPHIC_TASK_COUNT,
  UPDATE_GRAPHIC_TITLE,
  UPDATE_HOTSPOT,
  UPDATE_PAGE_TASK_COUNT,
  UPDATE_SITE_URL,
  UPDATE_STATUS_ATTRIBUTE,
  UPGRADE_ACCOUNT,
  USER_LOGIN,
  USER_REGISTRATION,
  VERIFY_COLLAB_CODE,
} from 'actions/collaborate';
import { UPDATE_DASHBOARD_IMAGE } from 'actions/dashboard';
import { ERROR_FILE_UPLOAD } from 'actions/tasks';
import { LOGOUT, SUCCESS_USER_AUTHENTICATE } from 'actions/user';
import {
  SET_SELECTED_WORKSPACE,
  SUCCESS_FETCH_WORKSPACES,
} from 'actions/workspace';
import {
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  addApprovePage,
  addGraphicVersion,
  addNewCustomSite,
  addNewSite,
  addPage,
  addStandardFlowSite,
  addTaskComment,
  attachFileUpload,
  commentEditHistory,
  countOpenTask,
  createAnnotatedTask,
  createGraphicTask,
  createHotspot,
  createSiteTask,
  deleteComment,
  deleteCommentFiles,
  deleteDesign,
  deleteHotspot,
  deletePage,
  // postInvitePeople,
  deleteTask,
  deleteVersion,
  fetchCurrentPageTasks,
  fetchDummyTasks,
  fetchNextSiteTasks,
  fetchPages,
  getCollTaskAttributes,
  getGraphicCurrentPageTasks,
  getGraphicVersions,
  getItemDetails,
  getSiteCurrentPageTasks,
  getSiteTasks,
  getinvitedColabUsers,
  inviteCollabs,
  postExtension,
  reinviteCollabUser,
  removeInviteCollabs,
  updataTaskStatus,
  updateGodaddayDone,
  updateGraphicTile,
  updateHotspot,
  updateSiteURL,
  upgradeAccount,
  // postApprovePage,
  userRegistration,
} from '../api/collaborate';
import { editComment } from '../api/tasks';
import { getWorkspaces } from '../api/workspace';
// Utils
import { UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT } from 'actions/workspace';
import { LogoutObjectForPutEffect } from 'utils/constants';
import { getSelectedWorkspace } from 'utils/functions';
import API from '../api';
import { verifyCode } from '../api/user';

function* getItemDetailsWorker(action) {
  const { id, reload, targetGraphic, pageId, proxyMethod } = action;

  const response = yield call(
    getItemDetails,
    id,
    reload,
    pageId,
    proxyMethod
  );

  if (response.status) {
    let filterParams = {
      textSearch: '',
      notifiedUsers: '',
      orderBy: '',
      taskType: {},
    };
    yield put({
      type: SUCCESS_GET_ITEM_DETAILS,
      data: response.result,
      message: response.message || 'Successfully fetched item details',
      targetGraphic: targetGraphic,
    });
    if (response.result.type !== 'graphic') {
      yield put({
        type: GET_SITE_THIS_PAGE_TASK,
        websiteId: response.result.id,
        current_page_url: response.result.iframe_url,
        ...filterParams,
      });
      /* when a new site is added, the API doesn't return it's image. So after adding a site,
          a user should see the project image for the project that they just added */
      const getDashboard = (state) => state.dashboard.dashboard;
      const dashboard = yield select(getDashboard);
      if (
        dashboard.filter((item) => item.id === response.result.id).length === 1
      ) {
        yield put({
          type: UPDATE_DASHBOARD_IMAGE,
          id: response.result.id,
          image: response.result.image,
        });
      }
    }
    if (reload) {
      yield put({
        type: GET_SITE_TASKS,
        id: id,
        ...filterParams,
      });
    }
  } else {
    yield put({
      type: ERROR_GET_ITEM_DETAILS,
      message: response.message || 'Failed to fetch item details',
    });
  }
}

export function* getItemDetailsWatcher() {
  yield takeLatest(GET_ITEM_DETAILS, getItemDetailsWorker);
}

function* getCollaborateTaskAttributes(action) {
  const { taskId } = action;

  const data = yield call(getCollTaskAttributes, taskId);

  if (data.status) {
    yield put({
      type: SUCCESS_ATTRIBUTES_FETCHING,
      attributes: data.result,
      message: data.message || 'Task details fetched!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_ATTRIBUTES_FETCHING,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchTasksCollaborateAttributeSaga() {
  yield takeLatest(NEW_COLLABORATE_TASK_SELECTED, getCollaborateTaskAttributes);
}

function* createSiteTaskWorker(action) {
  const { params } = action;
  const response = yield call(createSiteTask, params);

  if (response.status) {
    const attrData = yield call(getCollTaskAttributes, response.result.task_id);

    if (attrData.status) {
      yield put({
        type: SUCCESS_CREATE_SITE_TASK,
        userId: params.task_config_author_id,
        data: response.result,
        message: response.message || 'Successfully created task',
      });
      yield put({
        type: UPDATE_PAGE_TASK_COUNT,
        data: response.result,
        addPage: true,
      });
      yield put({
        type: SUCCESS_ATTRIBUTES_FETCHING,
        attributes: attrData.result,
      });
    }

    // make the takeScreenshot false so that the iframe doesn't keep on taking the screenshot
    yield put({
      type: MAKE_TAKE_SCREENSHOT_FALSE,
    });
  } else {
    yield put({
      type: ERROR_CREATE_SITE_TASK,
      failedTaskData: params,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* createSiteTaskWatcher() {
  yield takeEvery(CREATE_SITE_TASK, createSiteTaskWorker);
}

function* createGraphicTaskWorker(action) {
  const { params } = action;
  const response = yield call(createGraphicTask, params);

  if (response.status) {
    const attrData = yield call(getCollTaskAttributes, response.result.task_id);
    if (attrData.status) {
      yield put({
        type: SUCCESS_CREATE_SITE_TASK,
        userId: params.task_config_author_id,
        data: response.result,
        message: response.message || 'Successfully created task',
      });
      yield put({
        type: UPDATE_GRAPHIC_TASK_COUNT,
      });
      yield put({
        type: SUCCESS_ATTRIBUTES_FETCHING,
        attributes: attrData.result,
      });
    }

    // make the takeScreenshot false so that the iframe doesn't keep on taking the screenshot
    yield put({
      type: MAKE_TAKE_SCREENSHOT_FALSE,
    });
  } else {
    yield put({
      type: ERROR_CREATE_SITE_TASK,
      failedTaskData: params,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* createGraphicTaskWatcher() {
  yield takeEvery(CREATE_GRAPHIC_TASK, createGraphicTaskWorker);
}

function* createTaskCommentWorker(action) {
  const { params } = action;

  const response = yield call(addTaskComment, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_TASK_COMMENT,
      data: response.result,
      popId: params.task_number,
      message: response.message || 'Comment Added Successfully',
    });
    yield put({
      type: NEW_COLLABORATE_TASK_SELECTED,
      taskId: params.task_id,
    });
  } else {
    yield put({
      type: ERROR_CREATE_TASK_COMMENT,
      failedCommentData: params,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* createTaskCommentWatcher() {
  yield takeEvery(CREATE_TASK_COMMENT, createTaskCommentWorker);
}

function* getSiteTasksWorker(action) {
  const { id, textSearch, notifiedUsers, taskType, orderBy } = action;
  // let searchKey = ""
  // if(textSearch) {
  //     searchKey = textSearch
  // }
  const response = yield call(
    getSiteTasks,
    id,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy
  );
  // alert(id)

  if (response.status) {
    yield put({
      type: SUCCESS_GET_SITE_TASKS,
      data: response.result,
      total_records: response.total_records,
      moreRecord: response.more_records,
      totalRecordsAssignedToMe: response.total_records_assigned_to_me,
      message: response.message || 'Successfully fetched tasks',
    });
  } else {
    yield put({
      type: ERROR_GET_SITE_TASKS,
      message: response.message || 'Failed to fetch tasks',
    });
  }
}

export function* getSiteTasksWatcher() {
  yield takeLatest(GET_SITE_TASKS, getSiteTasksWorker);
}

function* fetchNextSiteTasksWorker(action) {
  const { id, offSet, textSearch, notifiedUsers, taskType, orderBy } = action;
  const response = yield call(
    fetchNextSiteTasks,
    id,
    offSet,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_SITE_TASKS,
      data: response.result,
      total_records: response.total_records,
      moreRecord: response.more_records,
      totalRecordsAssignedToMe: response.total_records_assigned_to_me,
      message: response.message || 'Successfully fetched tasks',
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_SITE_TASKS,
      message: response.message || 'Failed to fetch tasks',
    });
  }
}

export function* fetchNextSiteTasksWatcher() {
  yield takeLatest(FETCH_NEXT_SITE_TASKS, fetchNextSiteTasksWorker);
}

function* addNewSiteWorker(action) {
  const { params } = action;

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(addNewSite, {
    ...params,
    workspace_id: selectedWorkspace?.id,
  });
  if (response.status) {
    yield put({
      type: SUCCESS_ADD_NEW_SITE,
      data: response.result,
      urlSource: response.src,
      fromWizard: params.fromWizard,
      message: response.message || 'Successfully created project',
    });
    yield put({
      type: UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT,
    });
  } else {
    yield put({
      type: ERROR_ADD_NEW_SITE,
      message: response.message || 'Failed to create project',
    });
  }
}

export function* addNewSiteWatcher() {
  yield takeLatest(ADD_NEW_SITE, addNewSiteWorker);
}

function* addStandardFlowSiteWorker(action) {
  const { params } = action;
  const response = yield call(addStandardFlowSite, params);
  if (response.status) {
    yield put({
      type: SUCCESS_ADD_NEW_SITE,
      data: response.result,
      urlSource: response.src,
      message: response.message || 'Successfully created project',
    });
    yield put({
      type: UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT,
    });
  } else {
    yield put({
      type: ERROR_ADD_NEW_SITE,
      message: response.message || 'Failed to create project',
    });
  }
}

export function* addStandardFlowSiteWatcher() {
  yield takeLatest(ADD_STAMDARD_FLOW_SITE, addStandardFlowSiteWorker);
}

function* getsiteThisPageTasks(action) {
  const {
    websiteId,
    current_page_url,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy,
  } = action;
  // let searchKey = ""
  // if(textSearch) {
  //     searchKey = textSearch
  // }
  const response = yield call(
    getSiteCurrentPageTasks,
    websiteId,
    current_page_url,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy
  );
  if (response.status) {
    yield put({
      type: SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK,
      data: response.result,
      currentPageCount: response.total_records,
      moreRecords: response.more_records,
      message: response.message || 'Successfully fetched current page task',
    });
  } else {
    yield put({
      type: ERROR_GET_SITE_CURRENT_PAGE_TASK,
      message: response.message || 'Failed to fetch current page task',
    });
  }
}

export function* getSiteThisPageTaskWatcher() {
  yield takeEvery(GET_SITE_THIS_PAGE_TASK, getsiteThisPageTasks);
}

function* getGraphicThisPageTasks(action) {
  const { graphic_id } = action;
  const response = yield call(getGraphicCurrentPageTasks, graphic_id);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK,
      data: response.result,
      currentPageCount: response.total_records,
      moreRecords: response.more_records,
      message: response.message || 'Successfully fetched current page task',
    });
  } else {
    yield put({
      type: ERROR_GET_SITE_CURRENT_PAGE_TASK,
      message: response.message || 'Failed to fetch current page task',
    });
  }
}

export function* getGraphicThisPageTaskWatcher() {
  yield takeEvery(GET_GRAPHIC_CURRENT_GRAPHIC_TASK, getGraphicThisPageTasks);
}

function* fetchNextThisPageTasks(action) {
  const {
    websiteId,
    current_page_url,
    offSet,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy,
  } = action;
  const response = yield call(
    fetchCurrentPageTasks,
    websiteId,
    current_page_url,
    offSet,
    textSearch,
    notifiedUsers,
    taskType,
    orderBy
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK,
      data: response.result,
      currentPageCount: response.total_records,
      moreRecords: response.more_records,
      message: response.message || 'Successfully fetched current page task',
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_CURRENT_PAGE_TASK,
      message: response.message || 'Failed to fetch current page task',
    });
  }
}

export function* fetchNextThisPageTaskWatcher() {
  yield takeEvery(FETCH_NEXT_THIS_PAGE_TASK, fetchNextThisPageTasks);
}

function* updateTaskStatusWorker(action) {
  const { value } = action;
  const response = yield call(updataTaskStatus, value);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_STATUS,
      data: response.result,
      taskId: value.task_id,
      status: value.value,
      method: value.method,
      selectedUserId: value?.id,
      checked: value?.value?.checked,
      message: response.message || 'Successfully created task',
    });
    const attrStatusData = yield call(getCollTaskAttributes, value.task_id);
    if (attrStatusData.status) {
      yield put({
        type: SUCCESS_ATTRIBUTES_FETCHING,
        attributes: attrStatusData.result,
      });
    }
  } else {
    yield put({
      type: ERROR_CREATE_SITE_TASK,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* updateStatusAttributeWatcher() {
  yield takeEvery(UPDATE_STATUS_ATTRIBUTE, updateTaskStatusWorker);
}

function* uploadFileWorker(params) {
  const data = yield call(attachFileUpload, params);

  if (data.status) {
    yield put({
      type: SUCCESS_ATTACH_FILE_UPLOAD,
      file: data.result,
      message: data.message || 'File uploaded successfully!',
    });
  } else {
    yield put({
      type: ERROR_FILE_UPLOAD,
      message: data.message || 'Error uploading file, try again later',
    });
  }
}

export function* attachUploadFileWatcher() {
  while (true) {
    const { params } = yield take(ATTACH_FILE_UPLOAD);
    yield fork(uploadFileWorker, params);
  }
}

export function* deleteTaskWorker(action) {
  const { params } = action;
  const data = yield call(deleteTask, params);
  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_COLLABORATE_TASK,
      taskId: params.task_id,
      data: data.result,
      message: 'Task deleted successfully!',
      // remainingTasks: data.data.remaining_tasks,
    });
    yield put({
      type: UPDATE_PAGE_TASK_COUNT,
      data: data?.result,
      addPage: false,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_COLLABORATE_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteTaskWatcher() {
  yield takeEvery(DELETE_COLLABORATE_TASK, deleteTaskWorker);
}

// Design deletion saga called from collaboration
export function* deleteDesignWorker(action) {
  const { params } = action;
  const data = yield call(deleteDesign, params);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_DESIGN,
      data: params,
      message: 'desing deleted successfully!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_DESIGN,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteDesignWatcher() {
  yield takeEvery(DELETE_DESIGN, deleteDesignWorker);
}

// version deletion saga called from collaboration
export function* deleteVersionWorker(action) {
  const { params } = action;

  const data = yield call(deleteVersion, params);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_VERSION,
      data: params,
      message: 'Version deleted successfully!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_VERSION,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteVersionWatcher() {
  yield takeLatest(DELETE_VERSION, deleteVersionWorker);
}

export function* deleteCommentWorker(action) {
  const { value } = action;
  const data = yield call(deleteComment, value);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_COMMENT,
      id: data.result.id,
      data: data.result,
      message: data.result.message || 'Task deleted successfully!',
      // remainingTasks: data.data.remaining_tasks,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_COMMENT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteCollaborateCommentWatcher() {
  yield takeEvery(DELETE_COLLABORATE_COMMENT, deleteCommentWorker);
}

export function* approvePageWorker(action) {
  const { params } = action;
  const data = yield call(addApprovePage, params);

  if (data.status) {
    yield put({
      type: SUCCESS_APPROVE_PAGE,
      pageUrl: params.page_url,
      taskComplete: params.set_tasks_complete,
      id: data.result.id,
      message: params?.godaddy
        ? 'Revision round has been marked inside the sidebar, please refresh to see'
        : data.message || 'Approve page successfully!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_APPROVE_PAGE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* approvePageWatcher() {
  yield takeEvery(APPROVE_A_PAGE, approvePageWorker);
}

function* userRegistrationWorker(action) {
  const { params } = action;
  const data = yield call(userRegistration, params);

  if (data.status) {
    yield put({
      type: SUCCESS_USER_REGISTRATION,
      data: data.result,
      siteId: params.site_id,
      message: data.message || 'User registered successfully!',
    });

    yield put({
      type: USER_LOGIN,
      params: {
        email: params.email,
        password: params.password,
        site_id: params.site_id,
        fromRegister: true,
      },
    });

    // for google analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Signup',
      accountType: 'free',
    });
  } else {
    yield put({
      type: ERROR_USER_REGISTRATION,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* userRegistrationWatcher() {
  yield takeLatest(USER_REGISTRATION, userRegistrationWorker);
}

function* updateSiteURLWorker(action) {
  const { params } = action;
  const data = yield call(updateSiteURL, params);

  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_SITE_URL,
      data: data.result,
      message: data.message || 'Site URL updated successfully!',
    });
    yield put({
      type: GET_ITEM_DETAILS,
      id: params.site_id,
      reload: 'old',
    });
  } else {
    yield put({
      type: ERROR_UPDATE_SITE_URL,
    });
  }
}

export function* updateSiteURLWatcher() {
  yield takeLatest(UPDATE_SITE_URL, updateSiteURLWorker);
}

function* upgradeAccountWorker(action) {
  const { navigate } = action;
  const data = yield call(upgradeAccount);

  if (data.status) {
    yield put({
      type: SUCCESS_UPGRADE_ACCOUNT,
      data: data.result,
      message: data.message || 'User registered successfully!',
    });
    navigate('/');
  } else {
    yield put({
      type: ERROR_UPGRADE_ACCOUNT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* upgradeAccountWatcher() {
  yield takeLatest(UPGRADE_ACCOUNT, upgradeAccountWorker);
}

function* editCommentWorker(action) {
  const { params, taskId } = action;

  const data = yield call(editComment, params);

  if (data.status) {
    yield put({
      type: SUCCESS_EDIT_COMMENT,
      message: data.message || 'Comment updated successfully',
      id: data.data.id,
      comment_content: data.data.comments,
      is_edited: data.data.is_edited,
      files: data.data.files,
    });
    if (taskId) {
      // if editing collaborate (websites) comment so fetch attributes
      yield put({ type: NEW_COLLABORATE_TASK_SELECTED, taskId });
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EDIT_COMMENT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

function* updateGraphicTitleWorker(action) {
  const response = yield call(updateGraphicTile, action);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_GRAPHIC_TITLE,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_GRAPHIC_TITLE,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateGraphicTitleWatcher() {
  yield takeLatest(UPDATE_GRAPHIC_TITLE, updateGraphicTitleWorker);
}

function* getGraphicVersionsWorker(action) {
  const { id } = action;
  const response = yield call(getGraphicVersions, id);
  if (response.status) {
    yield put({
      type: SUCCESS_GET_GRAPHIC_VERSIONS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_GET_GRAPHIC_VERSIONS,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* getGraphicVersionsWatcher() {
  yield takeLatest(GET_GRAPHIC_VERSIONS, getGraphicVersionsWorker);
}

export function* editCollaborateCommentWatcher() {
  yield takeLatest(EDIT_COLLABORATE_COMMENT, editCommentWorker);
}

function* createHotspotWorker(action) {
  const { params } = action;
  const response = yield call(createHotspot, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_HOTSPOT,
      data: response.result,
      message: response.message || 'Hotspot created successfully!',
    });
  } else {
    yield put({
      type: ERROR_CREATE_HOTSPOT,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* createHotspotWatcher() {
  yield takeLatest(CREATE_HOTSPOT, createHotspotWorker);
}

function* updateHotspotWorker(action) {
  const response = yield call(updateHotspot, action);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_HOTSPOT,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_HOTSPOT,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateHotspotWatcher() {
  yield takeLatest(UPDATE_HOTSPOT, updateHotspotWorker);
}

function* deleteHotspotWorker(action) {
  const { params } = action;
  const response = yield call(deleteHotspot, params);

  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_HOTSPOT,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_DELETE_HOTSPOT,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteHotspotWatcher() {
  yield takeLatest(DELETE_HOTSPOT, deleteHotspotWorker);
}

function* addGraphicVersionWorker(action) {
  const { data } = action;
  const response = yield call(addGraphicVersion, data);
  if (response.status) {
    yield put({
      type: SUCCESS_ADD_GRAPHIC_VERSION,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_ADD_GRAPHIC_VERSION,
      message: response.message,
    });
  }
}

export function* addGraphicVersionWatcher() {
  yield takeLatest(ADD_GRAPHIC_VERSION, addGraphicVersionWorker);
}

function* updateGodaddyWorker(action) {
  const { params } = action;
  const data = yield call(updateGodaddayDone, params);

  if (data.status) {
    window.location.href = params.website_care_url;
  } else {
    yield put({
      type: ERROR_UPDATE_GODADDY_DONE,
      message: 'Something went wrong, try again later!' || data.message,
    });
  }
}

export function* updateGodaddyWatcher() {
  yield takeLatest(UPDATE_GODADDY_DONE, updateGodaddyWorker);
}

function* addNewCustomSiteWorker(action) {
  const { params } = action;
  const response = yield call(addNewCustomSite, params);

  if (response.status) {
    if (response.email_exists) {
      let token = localStorage.getItem('token');
      if (token) {
        if (response.token_email === response.email) {
          params.navigate(
            `/fetching?_from=${action.params.src}&url=${action.params.url}`
          );
        } else {
          localStorage.removeItem('userLoggedIn');
          localStorage.removeItem('token');
          setTimeout(function () {
            window.location.href = `/login?type=one-click&email=${action.params.email}&_from=${action.params.src}&url=${action.params.url}`;
          }, 2000);
        }
      } else {
        params.navigate(
          `/login?type=one-click&email=${action.params.email}&_from=${action.params.src}&url=${action.params.url}`
        );
      }
    } else {
      yield call(API.setUserLoggedIn);
      yield put({
        type: SUCCESS_ADD_NEW_CUSTOM_SITE,
        data: response.data,
        urlSource: params.src,
        message: response.message || 'Successfully created project',
      });
    }
  } else {
    yield put({
      type: ERROR_ADD_NEW_CUSTOM_SITE,
      message: response.message || 'Failed to create project',
    });
  }
}

export function* addNewCustomSiteWatcher() {
  yield takeLatest(ADD_NEW_CUSTOM_SITE, addNewCustomSiteWorker);
}

function* createAnnotatedTaskWorker(action) {
  const { params } = action;
  const response = yield call(createAnnotatedTask, params);

  if (response.status) {
    const attrData = yield call(getCollTaskAttributes, response.result.task_id);

    if (attrData.status) {
      yield put({
        type: SUCCESS_CREATE_ANNOTATED_TASK,
        data: response.result,
        message: response.message || 'Successfully created task',
      });
      yield put({
        type: SUCCESS_ATTRIBUTES_FETCHING,
        attributes: attrData.result,
      });
    }
  } else {
    yield put({
      type: ERROR_CREATE_ANNOTATED_TASK,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* createAnnotatedTaskWatcher() {
  yield takeLatest(CREATE_ANNOTATED_TASK, createAnnotatedTaskWorker);
}

function* inviteCollabWorker(action) {
  const { params } = action;
  const response = yield call(inviteCollabs, params);

  if (response.status) {
    yield put({
      type: SUCCESS_START_INVITE_COLLAB,
      message: response.result || 'Successfully created task',
    });
    yield put({
      type: FETCH_INVITED_COLLAB,
      siteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_START_INVITE_COLLAB,
      message: response.message || 'Failed to send invitation',
    });
  }
}

export function* inviteCollabWatcher() {
  yield takeLatest(START_INVITE_COLLAB, inviteCollabWorker);
}

function* fetchInvitedCollabUserWorker(action) {
  const { siteId } = action;
  const response = yield call(getinvitedColabUsers, siteId);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_INVITED_COLLAB,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_FETCH_INVITED_COLLAB,
      message: 'Something went wrong, try again later!',
    });
  }
}

export function* fetchInvitedCollabUserWatcher() {
  yield takeLatest(FETCH_INVITED_COLLAB, fetchInvitedCollabUserWorker);
}

function* removeInvitedCollabUserWorker(action) {
  const { params } = action;
  const response = yield call(removeInviteCollabs, params);
  if (response.status) {
    yield put({
      type: SUCCESS_REMOVE_INVITED_COLLAB,
      message: response.result,
      userId: params?.token_id,
    });
  } else {
    yield put({
      type: ERROR_REMOVE_INVITED_COLLAB,
      message: 'Something went wrong, try again later!',
    });
  }
}

export function* removeInvitedCollabUserWatcher() {
  yield takeLatest(REMOVE_INVITED_COLLAB, removeInvitedCollabUserWorker);
}

function* commentEditHistoryWorker(action) {
  const { commentId } = action;
  const response = yield call(commentEditHistory, commentId);

  if (response.status) {
    yield put({
      type: SUCCESS_COMMENT_EDIT_HISTORY,
      data: response.result,
      // message: response.message || "Successfully fetched comment edit history"
    });
  } else {
    yield put({
      type: ERROR_COMMENT_EDIT_HISTORY,
      message:
        response.data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* commentEditHistoryWatcher() {
  yield takeLatest(COMMENT_EDIT_HISTORY, commentEditHistoryWorker);
}

function* reinvitedCollabUserWorker(action) {
  const { params } = action;
  const response = yield call(reinviteCollabUser, params);
  if (response.status) {
    yield put({
      type: SUCCESS_RESEND_COLLAB_INVITE,
      message: response.result,
    });
  } else {
    yield put({
      type: ERROR_RESEND_COLLAB_INVITE,
      message: 'Something went wrong, try again later!',
    });
  }
}

export function* reinvitedCollabUserWatcher() {
  yield takeLatest(RESEND_COLLAB_INVITE, reinvitedCollabUserWorker);
}

function* addPageWorker(action) {
  const { params } = action;
  const response = yield call(addPage, params);

  if (response.status) {
    yield put({
      type: SUCCESS_ADD_PAGE,
      data: response.result,
      message: response.message || 'Successfully created task',
    });
  } else {
    yield put({
      type: ERROR_ADD_PAGE,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* addPageWatcher() {
  yield takeLatest(ADD_PAGE, addPageWorker);
}

function* fetchPagesWorker(action) {
  const { websiteId } = action;
  const response = yield call(fetchPages, websiteId);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_PAGES,
      pages: response.data.pages.page || [],
    });
  } else {
    yield put({
      type: ERROR_FETCH_PAGES,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchPagesWatcher() {
  yield takeLatest(FETCH_PAGES, fetchPagesWorker);
}

function* deletePageWorker(action) {
  const { pageId } = action;
  const response = yield call(deletePage, pageId);
  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_PAGE,
    });
  } else {
    // yield put({
    //     type: ERROR_DELETE_PAGE,
    //     message: response.message || "Something went wrong, try again later!"
    // });
  }
}

export function* deletePageWatcher() {
  yield takeLatest(DELETE_PAGE, deletePageWorker);
}

function* deleteCommentFileWorker(action) {
  const { params } = action;
  const response = yield call(deleteCommentFiles, params);
  if (response.status) {
    // yield put({
    //     type: SUCCESS_DELETE_COMMENT_FILE,
    // })
  } else {
    yield put({
      type: ERROR_DELETE_COMMENT_FILE,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteCommentFileWatcher() {
  yield takeLatest(DELETE_COMMENT_FILE, deleteCommentFileWorker);
}

function* countOpenTaskWorker(action) {
  const { siteId } = action;
  const response = yield call(countOpenTask, siteId);
  if (response.status === 200) {
    yield put({
      type: SUCCESS_GET_OPEN_TASK_NUMBER,
      data: response.result,
      message: response.message || 'Successfully created task',
    });
  } else {
    yield put({
      type: ERROR_GET_OPEN_TASK_NUMBER,
      message: response.message || 'Failed to create task',
    });
  }
}

export function* countOpenTaskWatcher() {
  yield takeLatest(GET_OPEN_TASK_NUMBER, countOpenTaskWorker);
}

function* extensionMetricsWorker() {
  yield call(postExtension);
}

export function* extensionMetricsWatcher() {
  yield takeLatest(EXTENSION_METRICS, extensionMetricsWorker);
}

function* fetchDummyTasksWorker(action) {
  const { siteId } = action;
  const response = yield call(fetchDummyTasks, siteId);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_DUMMY_TASKS,
      data: response.result,
      message: response.message || 'Successfully fetched dummy tasks',
    });
  } else {
    yield put({
      type: ERROR_FETCH_DUMMY_TASKS,
      message: response.message || 'Failed to fetch dummy tasks',
    });
  }
}

export function* fetchDummyTasksWatcher() {
  yield takeLatest(FETCH_DUMMY_TASKS, fetchDummyTasksWorker);
}

function* verifyCodeWorker(action) {
  const { params } = action;
  const response = yield call(verifyCode, params);
  if (response.status) {
    yield call(API.setUserLoggedIn);

    const workspacesResponse = yield call(getWorkspaces);

    if (workspacesResponse.status || workspacesResponse?.data?.length > 0) {
      yield put({
        type: SUCCESS_FETCH_WORKSPACES,
        data: workspacesResponse?.data,
      });
    }

    // log user out because API sent no workspaces
    if (workspacesResponse?.data.length === 0) {
      yield put({
        type: LOGOUT,
        error: true,
        message: 'No workspace found for this user, please contact support!',
      });
      localStorage.removeItem('token');

      return;
    }

    // means the user has only one workspace so save it
    localStorage.setItem(
      'selectedWorkspace',
      JSON.stringify(workspacesResponse?.data[0])
    );
    yield put({
      type: SET_SELECTED_WORKSPACE,
      workspace: workspacesResponse?.data[0],
    });

    yield put({
      type: SUCCESS_USER_AUTHENTICATE,
      token: yield call(API.getAccessTokenFromLocalStorage),
    });

    yield put({
      type: SUCCESS_USER_LOGIN,
      data: response.result,
      message: response.message || 'User logged in successfully!',
    });

    if (params.src) {
      params.history.push(
        `/fetching?_from=${params.src}&url=https://${[params.url]}`
      );
    }
  } else {
    yield put({
      type: ERROR_USER_LOGIN,
      message: response.result || 'Something went wrong, try again later!',
    });
  }
}
export function* verifyCollabCodeWatcher() {
  yield takeLatest(VERIFY_COLLAB_CODE, verifyCodeWorker);
}
