export const FETCH_ALL_BOARD_TASKS = 'FETCH_ALL_BOARD_TASKS';
export const SUCCESS_FETCH_ALL_BOARD_TASKS = 'SUCCESS_FETCH_ALL_BOARD_TASKS';
export const ERROR_FETCH_ALL_BOARD_TASKS = 'ERROR_FETCH_ALL_BOARD_TASKS';

export const NEW_BOARD_WEBSITE_SELECTED = 'NEW_BOARD_WEBSITE_SELECTED';

export const UPDATE_BOARD_TASK = 'UPDATE_BOARD_TASK';
export const SUCCESS_UPDATE_BOARD_TASK = 'SUCCESS_UPDATE_BOARD_TASK';
export const ERROR_UPDATE_BOARD_TASK = 'ERROR_UPDATE_BOARD_TASK';

export const APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE =
  'APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE';

export const SUCCESS_BOARD_FILTER_FETCHING = 'SUCCESS_BOARD_FILTER_FETCHING';
export const ERROR_BOARD_FILTER_FETCHING = 'ERROR_BOARD_FILTER_FETCHING';

export const FETCH_BOARD_URGENCY = 'FETCH_BOARD_URGENCY';

export const UPDATE_TASK_ATTRIBUTES = 'board/UPDATE_TASK_ATTRIBUTES';
export const SUCCESS_UPDATE_TASK_ATTRIBUTES =
  'board/SUCCESS_UPDATE_TASK_ATTRIBUTES';
export const ERROR_UPDATE_TASK_ATTRIBUTES =
  'board/ERROR_UPDATE_TASK_ATTRIBUTES';

export const UPDATE_BOARD_TASK_ATTRIBUTES =
  'board/UPDATE_BOARD_TASK_ATTRIBUTES';

export const EMPTY_MESSAGE = 'EMPTY_MESSAGE';

export const DELETE_TASK = 'board/DELETE_TASK';
export const SUCCESS_DELETE_TASK = 'board/SUCCESS_DELETE_TASK';
export const ERROR_DELETE_TASK = 'board/ERROR_DELETE_TASK';

export const SET_ATTRIBUTES_FOR_BOARD_NEW_TASK =
  'SET_ATTRIBUTES_FOR_BOARD_NEW_TASK';
export const SAVE_NEW_BOARD_GENERAL_TASK = 'SAVE_NEW_BOARD_GENERAL_TASK';
export const SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK =
  'SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK';
export const ERROR_SAVE_NEW_BOARD_GENERAL_TASK =
  'ERROR_SAVE_NEW_BOARD_GENERAL_TASK';

export const MESSAGE_SENT_NEW_BOARD_TASK = 'MESSAGE_SENT_NEW_BOARD_TASK';
export const ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK =
  'ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK';

export const NEW_BOARD_GENERAL_TASK_SAVED_WITH_MESSAGE =
  'NEW_BOARD_GENERAL_TASK_SAVED_WITH_MESSAGE';

export const START_SEARCH_BOARD = 'START_SEARCH_BOARD';
export const SUCCESS_SEARCH_BOARD = 'SUCCESS_SEARCH_BOARD';
export const ERROR_SEARCH_BOARD = 'ERROR_SEARCH_BOARD';

export const START_UPDATE_BOARD_TASK_ORDER = 'START_UPDATE_BOARD_TASK_ORDER';
export const SUCCESS_UPDATE_BOARD_TASK_ORDER =
  'SUCCESS_UPDATE_BOARD_TASK_ORDER';
export const ERROR_UPDATE_BOARD_TASK_ORDER = 'ERROR_UPDATE_BOARD_TASK_ORDER';

// export const FETCH_BOARD_STATUS_TASKS = "FETCH_BOARD_STATUS_TASKS";
export const FETCH_NEXT_BOARD_STATUS_TASKS = 'FETCH_NEXT_BOARD_STATUS_TASKS';
export const ERROR_BOARD_STATUS_TASKS = 'ERROR_BOARD_STATUS_TASKS';
export const FETCH_NEXT_BOARD_EMAIL_TASKS = 'FETCH_NEXT_BOARD_EMAIL_TASKS';

export const RESET_LOAD_MORE = 'RESET_LOAD_MORE';

export const SUCCESS_BOARD_STATUS_OPEN_TASKS =
  'SUCCESS_BOARD_STATUS_OPEN_TASKS';
export const SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS =
  'SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS';
export const SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS =
  'SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS';
export const SUCCESS_BOARD_STATUS_COMPLETE_TASKS =
  'SUCCESS_BOARD_STATUS_COMPLETE_TASKS';

export const SUCCESS_EMAIL_OPEN_TASKS = 'SUCCESS_EMAIL_OPEN_TASKS';
export const SUCCESS_EMAIL_IN_PROGRESS_TASKS =
  'SUCCESS_EMAIL_IN_PROGRESS_TASKS';
export const SUCCESS_EMAIL_PENDING_REVIEW_TASKS =
  'SUCCESS_EMAIL_PENDING_REVIEW_TASKS';
export const SUCCESS_EMAIL_COMPLETE_TASKS = 'SUCCESS_EMAIL_COMPLETE_TASKS';

export const SUCCESS_EMAIL_LOW_TASKS = 'SUCCESS_EMAIL_LOW_TASKS';
export const SUCCESS_EMAIL_MEDIUM_TASKS = 'SUCCESS_EMAIL_MEDIUM_TASKS';
export const SUCCESS_EMAIL_HIGH_TASKS = 'SUCCESS_EMAIL_HIGH_TASKS';
export const SUCCESS_EMAIL_CRITICAL_TASKS = 'SUCCESS_EMAIL_CRITICAL_TASKS';

export const SUCCESS_NEXT_EMAIL_OPEN_TASKS = 'SUCCESS_NEXT_EMAIL_OPEN_TASKS';
export const SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS =
  'SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS';
export const SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS =
  'SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS';
export const SUCCESS_NEXT_EMAIL_COMPLETE_TASKS =
  'SUCCESS_NEXT_EMAIL_COMPLETE_TASKS';

export const SUCCESS_NEXT_EMAIL_LOW_TASKS = 'SUCCESS_NEXT_EMAIL_LOW_TASKS';
export const SUCCESS_NEXT_EMAIL_MEDIUM_TASKS =
  'SUCCESS_NEXT_EMAIL_MEDIUM_TASKS';
export const SUCCESS_NEXT_EMAIL_HIGH_TASKS = 'SUCCESS_NEXT_EMAIL_HIGH_TASKS';
export const SUCCESS_NEXT_EMAIL_CRITICAL_TASKS =
  'SUCCESS_NEXT_EMAIL_CRITICAL_TASKS';

export const SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS =
  'SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS';
export const SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS =
  'SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS';
export const SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS =
  'SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS';
export const SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS =
  'SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS';

export const FETCH_BOARD_URGENCY_TASKS = 'FETCH_BOARD_URGENCY_TASKS';
export const SUCCESS_BOARD_URGENCY_TASKS = 'SUCCESS_BOARD_URGENCY_TASKS';
export const ERROR_BOARD_URGENCY_TASKS = 'ERROR_BOARD_URGENCY_TASKS';

export const SUCCESS_BOARD_URGENCY_MEDIUM_TASKS =
  'SUCCESS_BOARD_URGENCY_MEDIUM_TASKS';
export const SUCCESS_BOARD_URGENCY_HIGH_TASKS =
  'SUCCESS_BOARD_URGENCY_HIGH_TASKS';
export const SUCCESS_BOARD_URGENCY_CRITICAL_TASKS =
  'SUCCESS_BOARD_URGENCY_CRITICAL_TASKS';

export const SUCCESS_BOARD_EMAIL_URGENCY_TASKS =
  'SUCCESS_BOARD_EMAIL_URGENCY_TASKS';
export const SUCCESS_BOARD_EMAIL_URGENCY_MEDIUM_TASKS =
  'SUCCESS_BOARD_EMAIL_URGENCY_MEDIUM_TASKS';
export const SUCCESS_BOARD_EMAIL_URGENCY_HIGH_TASKS =
  'SUCCESS_BOARD_EMAIL_URGENCY_HIGH_TASKS';
export const SUCCESS_BOARD_EMAIL_URGENCY_CRITICAL_TASKS =
  'SUCCESS_BOARD_EMAIL_URGENCY_CRITICAL_TASKS';

export const FETCH_NEXT_BOARD_URGENCY_TASKS = 'FETCH_NEXT_BOARD_URGENCY_TASKS';
export const SUCCESS_BOARD_NEXT_URGENCY_TASKS =
  'SUCCESS_BOARD_NEXT_URGENCY_TASKS';
export const ERROR_BOARD_NEXT_URGENCY_TASKS = 'ERROR_BOARD_NEXT_URGENCY_TASKS';

export const SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS =
  'SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS';
export const SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS =
  'SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS';
export const SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS =
  'SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS';
export const FETCH_BOARD_COLUMN = 'FETCH_BOARD_COLUMN';

export const DELETE_BOARD_COMMENT_FILE = 'DELETE_BOARD_COMMENT_FILE';
export const SUCCESS_DELETE_BOARD_COMMENT_FILE =
  'SUCCESS_DELETE_BOARD_COMMENT_FILE';
export const ERROR_DELETE_BOARD_COMMENT_FILE =
  'ERROR_DELETE_BOARD_COMMENT_FILE';
