export const deleteCards = (state, deletedCards, emailSelected = false) => {
  if (!deletedCards) return state;

  const mapCardToState = {
    open: emailSelected ? 'openEmailTaskList' : 'openTaskList',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskList'
      : 'inProgressTaskList',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskList'
      : 'pendingReviewTaskList',
    complete: emailSelected ? 'completeEmailTaskList' : 'completeTaskList',
    low: emailSelected ? 'emailTaskUrgencyLow' : 'taskUrgencyLow',
    medium: emailSelected ? 'emailTaskUrgencyMedium' : 'taskUrgencyMedium',
    high: emailSelected ? 'emailTaskUrgencyHigh' : 'taskUrgencyHigh',
    critical: emailSelected
      ? 'emailTaskUrgencyCritical'
      : 'taskUrgencycritical',
  };

  deletedCards.forEach((deletedCard) => {
    const { task_id, task_status, task_priority } = deletedCard;
    const statusTaskList = state[mapCardToState[task_status]];
    const urgencyTaskList = state[mapCardToState[task_priority]];
    const updatedStatusTaskList = statusTaskList.filter(
      (task) => task.task_id != task_id
    );
    state[mapCardToState[task_status]] = updatedStatusTaskList;
    const updatedUrgencyTaskList = urgencyTaskList.filter(
      (task) => task.task_id != task_id
    );
    state[mapCardToState[task_priority]] = updatedUrgencyTaskList;
  });
  return state;
};

export const reorderCards = (state, movedCard, emailSelected = false) => {
  const mapCardToState = {
    open: emailSelected ? 'openEmailTaskList' : 'openTaskList',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskList'
      : 'inProgressTaskList',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskList'
      : 'pendingReviewTaskList',
    complete: emailSelected ? 'completeEmailTaskList' : 'completeTaskList',
    low: emailSelected ? 'emailTaskUrgencyLow' : 'taskUrgencyLow',
    medium: emailSelected ? 'emailTaskUrgencyMedium' : 'taskUrgencyMedium',
    high: emailSelected ? 'emailTaskUrgencyHigh' : 'taskUrgencyHigh',
    critical: emailSelected
      ? 'emailTaskUrgencyCritical'
      : 'taskUrgencycritical',
  };

  const mapCardToCount = {
    open: emailSelected ? 'openEmailTaskListCount' : 'openTaskListCount',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskListCount'
      : 'inProgressTaskListCount',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskListCount'
      : 'pendingReviewTaskListCount',
    complete: emailSelected
      ? 'completeEmailTaskListCount'
      : 'completeTaskListCount',
    low: emailSelected ? 'emailTaskUrgencyLowCount' : 'taskUrgencyLowCount',
    medium: emailSelected
      ? 'emailTaskUrgencyMediumCount'
      : 'taskUrgencyMediumCount',
    high: emailSelected ? 'emailTaskUrgencyHighCount' : 'taskUrgencyHighCount',
    critical: emailSelected
      ? 'emailTaskUrgencyCriticalCount'
      : 'taskUrgencycriticalCount',
  };

  const { prevColumn, curColumn, prevIndex, curIndex } = movedCard;

  if (prevIndex === undefined || curIndex === undefined) return state;

  const isStatusColumn = {
    open: true,
    'in-progress': true,
    'pending-review': true,
    complete: true,
  };

  const isPriorityColumn = {
    low: true,
    medium: true,
    high: true,
    critical: true,
  };
  // Remove moved card from previous task list
  const prevTaskList = state[mapCardToState[prevColumn]];
  const movedCardData = prevTaskList[prevIndex];

  /**
   * Sometimes movedCardData comes up as undefined.
   * My guess is that this 'bulkUpdateReorderCards' function is called twice consecutively, probably from 2 different Sagas.
   * But I don't have time to find the source of the double calls, so I am just going to return the old state if I have movedCardData as undefined.
   * If my assumptions are correct, the old state I am returning here should actually be the updated state, since this function has been called before.
   *
   *  */
  if (!movedCardData) return state;

  //Update the movedCardData information
  //If the task is already complete and we are updating the urgency, still leave it as complete
  //Else update the "complete" status
  if (movedCardData.is_complete && isPriorityColumn[curColumn]) {
    movedCardData.is_complete = true;
  } else {
    movedCardData.is_complete = curColumn === 'complete' ? true : false;
  }
  if (isStatusColumn[curColumn]) movedCardData.task_status = curColumn;
  if (isPriorityColumn[curColumn]) movedCardData.task_priority = curColumn;

  //Updating task_position array in movedCardData
  movedCardData.task_position.position = curIndex;
  if (isStatusColumn[curColumn]) {
    movedCardData.task_status = curColumn;
    movedCardData.task_position.is_status = 1;
    movedCardData.task_position.task_status = curColumn;
    movedCardData.task_position.task_priority = null;
  }

  if (isPriorityColumn[curColumn]) {
    movedCardData.task_priority = curColumn;
    movedCardData.task_position.is_status = 0;
    movedCardData.task_position.task_priority = curColumn;
    movedCardData.task_position.task_status = null;
  }
  const prevTaskListBeforeMovedCard = prevTaskList.slice(0, prevIndex);
  const prevTaskListAfterMovedCard = prevTaskList.slice(prevIndex + 1);
  const updatedPrevTaskList = [
    ...prevTaskListBeforeMovedCard,
    ...prevTaskListAfterMovedCard,
  ];
  state[mapCardToState[prevColumn]] = updatedPrevTaskList; //Update previous list in the state
  //Update the count of the board column from which we removed the current card
  state[mapCardToCount[prevColumn]] -= 1;

  // Add moved card to current task list
  const curTaskList = state[mapCardToState[curColumn]];
  const curTaskListBeforeMovedCard = curTaskList.slice(0, curIndex);
  const curTaskListAfterMovedCard = curTaskList.slice(curIndex);
  const updatedCurTaskList = [
    ...curTaskListBeforeMovedCard,
    movedCardData,
    ...curTaskListAfterMovedCard,
  ];
  state[mapCardToState[curColumn]] = updatedCurTaskList; //Update current list in the state
  //Update the count of the board column from which we added the current card
  state[mapCardToCount[curColumn]] += 1;

  return state;
};

/**
 *
 * @param {*} state
 * @param {*} movedCards
 * @param {*} curMethod : The method of the board when we are doing the bulk update i.e. "status" or "priority"
 * @param {*} curColumn : The column we are moving all the bulk updated cards to (i.e. the new column)
 * @returns
 */

export const bulkUpdateReorderCards = (
  state,
  movedCards,
  curMethod,
  curColumn,
  emailSelected = false
) => {
  const tempState = state;

  if (!movedCards || !curColumn || !curMethod) return state;

  const mapCardToState = {
    open: emailSelected ? 'openEmailTaskList' : 'openTaskList',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskList'
      : 'inProgressTaskList',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskList'
      : 'pendingReviewTaskList',
    complete: emailSelected ? 'completeEmailTaskList' : 'completeTaskList',
    low: emailSelected ? 'emailTaskUrgencyLow' : 'taskUrgencyLow',
    medium: emailSelected ? 'emailTaskUrgencyMedium' : 'taskUrgencyMedium',
    high: emailSelected ? 'emailTaskUrgencyHigh' : 'taskUrgencyHigh',
    critical: emailSelected
      ? 'emailTaskUrgencyCritical'
      : 'taskUrgencycritical',
  };

  const mapCardToCount = {
    open: emailSelected ? 'openEmailTaskListCount' : 'openTaskListCount',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskListCount'
      : 'inProgressTaskListCount',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskListCount'
      : 'pendingReviewTaskListCount',
    complete: emailSelected
      ? 'completeEmailTaskListCount'
      : 'completeTaskListCount',
    low: emailSelected ? 'emailTaskUrgencyLowCount' : 'taskUrgencyLowCount',
    medium: emailSelected
      ? 'emailTaskUrgencyMediumCount'
      : 'taskUrgencyMediumCount',
    high: emailSelected ? 'emailTaskUrgencyHighCount' : 'taskUrgencyHighCount',
    critical: emailSelected
      ? 'emailTaskUrgencyCriticalCount'
      : 'taskUrgencycriticalCount',
  };

  movedCards.forEach((movedCard) => {
    // This 'movedCard' object contains the task id, the OLD task status and OLD task priority
    const { task_id, task_status, task_priority } = movedCard;
    let prevColumn;
    prevColumn = curMethod === 'status' ? task_status : task_priority;

    let movedCardData;
    const updatedPrevTaskList = [];
    const prevTaskList = tempState[mapCardToState[prevColumn]];

    // Remove the task we are moving from it's previous task list
    prevTaskList.forEach((prevTask) => {
      if (prevTask.task_id == task_id) {
        movedCardData = prevTask;
      } else {
        updatedPrevTaskList.push(prevTask);
      }
    });
    //Update the board column from which we removed the current card
    tempState[mapCardToState[prevColumn]] = updatedPrevTaskList;
    //Update the count of the board column from which we removed the current card
    tempState[mapCardToCount[prevColumn]] -= 1;

    /**
     * Sometimes movedCardData comes up as undefined.
     * My guess is that this 'bulkUpdateReorderCards' function is called twice consecutively, probably from 2 different Sagas.
     * But I don't have time to find the source of the double calls, so I am just going to return the old state, if I have movedCardData as undefined.
     * If my assumptions are correct, the old state I am returning here should actually be the updated state, since this function has been called before.
     *
     *  */

    if (!movedCardData) return state;

    //If the task is already complete and we are updating the urgency, still leave it as complete
    //Else update the "complete" status
    if (movedCardData.is_complete && curMethod !== 'status') {
      movedCardData.is_complete = true;
    } else {
      movedCardData.is_complete = curColumn === 'complete' ? true : false;
    }

    //Update movedCardData to reflect the new column we are moving it to.
    if (curMethod === 'status') {
      movedCardData.task_status = curColumn;
      movedCardData.task_position.is_status = 1;
      movedCardData.task_position.task_priority = null;
      movedCardData.task_position.task_status = curColumn;
    } else {
      movedCardData.task_priority = curColumn;
      movedCardData.task_position.is_status = 0;
      movedCardData.task_position.task_priority = curColumn;
      movedCardData.task_position.task_status = null;
    }
    // Add to new task list
    const curTaskList = tempState[mapCardToState[curColumn]];
    const updatedCurTaskList = [movedCardData, ...curTaskList];
    //Update the board column from which we removed the current card
    tempState[mapCardToState[curColumn]] = updatedCurTaskList;
    //Update the count of the board column from which we removed the current card
    tempState[mapCardToCount[curColumn]] += 1;

    // If we moved a card(s) on the status board, we need to update the same card(s) on the urgency board, so they both have the same data and vice versa.
    const secondBoardColumn =
      curMethod === 'status'
        ? movedCardData.task_priority
        : movedCardData.task_status;

    const updatedSecondBoardTaskList = [];
    const secondBoardColumnTaskList =
      tempState[mapCardToState[secondBoardColumn]];

    secondBoardColumnTaskList.forEach((secondBoardTask) => {
      let curData = secondBoardTask;

      // Once we find the card in the task list, update the data
      if (curData.task_id == task_id) {
        curData.is_complete = movedCardData.is_complete;

        if (curMethod === 'status') {
          curData.task_status = curColumn;
        } else {
          curData.task_priority = curColumn;
        }
      }
      updatedSecondBoardTaskList.push(curData);
    });
    tempState[mapCardToState[secondBoardColumn]] = updatedSecondBoardTaskList;
  });
  return tempState;
};

const generateRandString = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const bulkUpdateAddTags = (
  state,
  movedCards,
  tag,
  emailSelected = false
) => {
  if (!movedCards || !tag) return state;

  const mapCardToState = {
    open: emailSelected ? 'openEmailTaskList' : 'openTaskList',
    'in-progress': emailSelected
      ? 'inProgressEmailTaskList'
      : 'inProgressTaskList',
    'pending-review': emailSelected
      ? 'pendingReviewEmailTaskList'
      : 'pendingReviewTaskList',
    complete: emailSelected ? 'completeEmailTaskList' : 'completeTaskList',
    low: emailSelected ? 'emailTaskUrgencyLow' : 'taskUrgencyLow',
    medium: emailSelected ? 'emailTaskUrgencyMedium' : 'taskUrgencyMedium',
    high: emailSelected ? 'emailTaskUrgencyHigh' : 'taskUrgencyHigh',
    critical: emailSelected
      ? 'emailTaskUrgencyCritical'
      : 'taskUrgencycritical',
  };

  movedCards.forEach((movedCard) => {
    const statusTaskList = state[mapCardToState[movedCard.task_status]];
    const urgencyTaskList = state[mapCardToState[movedCard.task_priority]];

    let doesTagExist = false;

    for (let curCard of statusTaskList) {
      if (curCard.task_id === movedCard.task_id) {
        //Check if tag does not already exist
        for (let task_tag of curCard.task_tags) {
          if (task_tag.value === tag) {
            doesTagExist = true;
            return;
          }
        }

        //Update the tags for the status board
        if (!doesTagExist) {
          curCard.task_tags.push({
            id: generateRandString(32), //Demo id that will be changed when we call data from database
            value: tag,
          });
        }
      }
    }
    state[mapCardToState[movedCard.task_status]] = statusTaskList; //Updating task list in the state

    for (let curCard of urgencyTaskList) {
      if (curCard.task_id === movedCard.task_id) {
        //Update the tags for the urgency board
        if (!doesTagExist) {
          curCard.task_tags.push({
            id: generateRandString(32), //Demo id that will be changed when we call data from database
            value: tag,
          });
        }
      }
    }

    state[mapCardToState[movedCard.task_priority]] = urgencyTaskList; //Updating task list in the state
  });

  return state;
};

export const updateSelectedMultiTasksData = (
  curData,
  newColumn,
  isStatus = true
) => {
  const updatedData = curData.map((curData) => {
    curData[isStatus ? 'task_status' : 'task_priority'] = newColumn;
    return curData;
  });

  return updatedData;
};
