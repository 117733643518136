export const CREATE_PROJECT = 'CREATE_PROJECT';
export const SUCCESS_CREATE_PROJECT = 'SUCCESS_CREATE_PROJECT';
export const ERROR_CREATE_PROJECT = 'ERROR_CREATE_PROJECT';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const SUCCESS_FETCH_PROJECTS = 'SUCCESS_FETCH_PROJECTS';
export const ERROR_FETCH_PROJECTS = 'ERROR_FETCH_PROJECTS';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const SUCCESS_UPDATE_PROJECT = 'SUCCESS_UPDATE_PROJECT';
export const ERROR_UPDATE_PROJECT = 'ERROR_UPDATE_PROJECT';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SUCCESS_DELETE_PROJECT = 'SUCCESS_DELETE_PROJECT';
export const ERROR_DELETE_PROJECT = 'ERROR_DELETE_PROJECT';

export const EMPTY_PROJECT_ID = 'EMPTY_PROJECT_ID';

export const SET_WEBSITE_IDS = 'SET_WEBSITE_IDS';
