import { useAppDispatch, useAppSelector } from '@src/hooks/redux';
import { NEW_TASK_SELECTED, NEW_WEBSITE_SELECTED } from 'actions/tasks';
import { FETCH_NOT_LOGGED_ENTRIES } from 'actions/time_entries';
import { GET_NOTIFICATIONS } from 'actions/user';
import { Bell } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { Button } from 'ui';
import { Drawer } from 'ui/components';
import Notifications from '../Notifications';

const MobileNotifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    notificationDetails,
    moreUnreadWebRecords,
    moreReadWebRecords,
    muteState,
    readNotifications,
    unreadNotifications,
    notLoggedEntries,
    readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter,
    updatingNotification,
  } = useAppSelector((state) => ({
    notificationDetails: state.user.notificationDetails,
    moreUnreadWebRecords: state.user.moreUnreadWebRecords,
    moreReadWebRecords: state.user.moreReadWebRecords,
    unreadCount: state.user.unreadCount,
    muteState: state.user.muteState,
    readNotifications: state.user.readNotifications,
    unreadNotifications: state.user.unreadNotifications,
    readNotificationsOffsetCounter: state.user.readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter:
      state.user.unreadNotificationsOffsetCounter,
    notLoggedEntries: state.time_entries.notLoggedEntries,
    updatingNotification: state.user.updatingNotification,
  }));
  useEffect(() => {
    dispatch({ type: GET_NOTIFICATIONS });
    if (notLoggedEntries.length <= 0) {
      dispatch({ type: FETCH_NOT_LOGGED_ENTRIES });
    }
  }, [dispatch, notLoggedEntries.length]);

  const selectTask = useCallback(
    (taskId: any) => {
      dispatch({ type: NEW_TASK_SELECTED, taskId });
    },
    [dispatch]
  );
  const websiteSelected = useCallback(
    (websiteID: any) => {
      dispatch({ type: NEW_WEBSITE_SELECTED, websiteId: websiteID });
    },
    [dispatch]
  );

  return (
    <Drawer shouldScaleBackground={true}>
      <Drawer.Trigger asChild className="mx-auto flex self-center">
        <Button className="flex h-full flex-col items-center justify-center gap-0.5 border-none bg-transparent px-0 py-1.5 text-[10px] font-normal text-secondary">
          <Bell size={18} />
          Notifications
        </Button>
      </Drawer.Trigger>
      <Drawer.Content className="h-screen px-4 pb-4">
        <Drawer.Header className="pb-0 pl-0">
          <Drawer.Title className="text-left">Notifications</Drawer.Title>
        </Drawer.Header>
        <Notifications
          moreUnreadWebRecords={moreUnreadWebRecords}
          moreReadWebRecords={moreReadWebRecords}
          notificationDetails={notificationDetails}
          muteState={muteState}
          readNotifications={readNotifications}
          unreadNotifications={unreadNotifications}
          readNotificationsOffsetCounter={readNotificationsOffsetCounter}
          unreadNotificationsOffsetCounter={unreadNotificationsOffsetCounter}
          updatingNotification={updatingNotification}
          selectTask={selectTask}
          websiteSelected={websiteSelected}
        />
      </Drawer.Content>
    </Drawer>
  );
};

export default MobileNotifications;
