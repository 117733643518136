import { cn } from 'utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';
import AnimatedLoader from '../AnimatedLoader';

const overlayVariants = cva(
  'absolute inset-0 z-50 flex flex-col gap-2 justify-center items-center backdrop-blur-[2px]',
  {
    variants: {
      variant: {
        light: 'bg-white bg-opacity-70',
        dark: 'bg-black bg-opacity-50',
      },
    },
    defaultVariants: {
      variant: 'light',
    },
  }
);

type OverlayVariantsProps = VariantProps<typeof overlayVariants>;

interface DataStateOverlayProps
  extends React.HTMLAttributes<HTMLDivElement>,
    OverlayVariantsProps {
  visible: boolean;
  size?: number;
  text?: string;
}

function DataStateOverlay({
  className,
  variant,
  visible,
  size = 60,
  text,
  ...props
}: DataStateOverlayProps) {
  if (!visible) return null;

  return (
    <div
      className={cn(
        overlayVariants({ variant }),
        'transition-opacity duration-500',
        className
      )}
      {...props}
    >
      {text && <div className="text-gray-500">{text}</div>}
      <AnimatedLoader size={size} />
    </div>
  );
}

export { DataStateOverlay };
export type { DataStateOverlayProps };
