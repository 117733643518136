import { useCallback, useEffect, useState } from 'react';
// Components
// Styling
import { Typography, makeStyles } from '@material-ui/core';
import { motion } from 'framer-motion';
import {
  // FiBell,
  FiBellOff,
} from 'react-icons/fi';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, ToggleGroup, ToggleGroupItem } from 'ui/components';
import { cn } from 'utils/cn';
import animationData from '../../../../media/jsons/spinner';
// Utils
import { Check, Eye, Inbox, ListChecks, MessageSquareDot } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHECK_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS_DETAILS,
  GET_READ_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  INCREASE_READ_COUNTER,
  INCREASE_UNREAD_COUNTER,
  MUTE_UNMUTE_NOTIFICATIONS,
  OPEN_CLOSE_NOTIFICATIONS_MODAL,
  UPDATE_USER_NOTIFICATION,
} from '../../../../actions/user';
import { useIntersection } from '../../../../utils/customHooks';
import NotificationTypes from './NotificationTypes';

const makeClasses = makeStyles((theme) => ({
  notifiycardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '14px',
    height: '60px',
    width: '100%',
    [theme.breakpoints.down(834)]: {
      justifyContent: 'space-evenly',
      width: '100%',
    },
  },

  tabChange: {
    opacity: 0,
  },
  notificationCardIcon: {
    cursor: 'pointer',
    marginBottom: '-2px',
    marginLeft: '10px',
  },

  icon: {
    cursor: 'pointer',
  },
  bellPlaceHolder: {
    width: '100%',
    marginTop: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  stagesLoader: {
    width: '100%',
    paddingTop: '30vh',
    height: '100%',
  },
}));
const Notifications = (props) => {
  const {
    readNotifications,
    unreadNotifications,
    moreUnreadWebRecords,
    moreReadWebRecords,
    muteState,
    readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter,
    updatingNotification,
    selectTask,
    websiteSelected,
  } = props;
  const { loadingNotifications } = useSelector((state) => ({
    loadingNotifications: state.user.loadingNotifications,
  }));

  const classes = makeClasses();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isRead, setIsRead] = useState(false);
  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const [removingCard, setIsRemovingCard] = useState(null);

  const [cards, setCards] = useState([]);
  const [tabChange, setTabChange] = useState(false);

  const notificationDetails = (n, index) => {
    if (n.type === 'delete_task') {
      return;
    }
    if (n.type === 'page_approved') {
      if (!updatingNotification && !isRead) {
        setIsRemovingCard(n.id);

        const data = cards.filter((c) => c.id !== n.id);

        setTimeout(() => {
          setIsRemovingCard(null);
          setCards([...data]);
          updateNotification(n.id, true);
        }, 700);
      }
      return;
    }
    if (n.type === 'new_user_registered') {
      navigate('/user');
      updateNotification(n.id, true);
    }
    if (n.site?.id) {
      navigate(`/tasks?website=${n.site.id}&taskid=${n.task.id}`, '_self');
      websiteSelected(n.site.id);
      selectTask(n.task.id);
      updateNotification(n.id, true);
    }
    if (n.inbox?.id) {
      navigate(`/tasks?website=${n.inbox.id}&taskid=${n.task.id}`, '_self');
      websiteSelected(n.inbox.id);
      selectTask(n.task.id);
      updateNotification(n.id, true);
    }
  };

  const handleClick = (e, id, boolean, index) => {
    e.stopPropagation();
    if (!updatingNotification) {
      setIsRemovingCard(id);
      const data = cards.filter((c) => c.id !== id);
      setTimeout(() => {
        setIsRemovingCard(null);
        setCards([...data]);
        updateNotification(id, boolean);
      }, 700);
    }
  };

  const getReadNotifications = (offset) => {
    dispatch({ type: GET_READ_NOTIFICATIONS, offset });
  };

  const getUnreadNotifications = (offset) => {
    dispatch({ type: GET_UNREAD_NOTIFICATIONS, offset });
  };

  const getNotificationDetails = useCallback(
    (params) => {
      dispatch({ type: GET_NOTIFICATIONS_DETAILS, params });
    },
    [dispatch]
  );

  const updateNotification = useCallback(
    (id, markAllAsRead) => {
      dispatch({ type: UPDATE_USER_NOTIFICATION, id, markAllAsRead });
    },
    [dispatch]
  );

  const checkAllRead = useCallback(
    (param) => {
      dispatch({ type: CHECK_ALL_NOTIFICATIONS, param });
    },
    [dispatch]
  );

  const muteUnmuteNotifications = useCallback(
    (param) => {
      dispatch({ type: MUTE_UNMUTE_NOTIFICATIONS, param });
    },
    [dispatch]
  );

  const increaseReadCounter = useCallback(
    (param) => {
      dispatch({ type: INCREASE_READ_COUNTER, param });
    },
    [dispatch]
  );

  const increaseUnreadCounter = useCallback(
    (param) => {
      dispatch({ type: INCREASE_UNREAD_COUNTER, param });
    },
    [dispatch]
  );

  const openCloseNotificationModal = useCallback(
    (param) => {
      dispatch({ type: OPEN_CLOSE_NOTIFICATIONS_MODAL, param });
    },
    [dispatch]
  );

  const tabChangeFunc = (boolean) => {
    setIsRead(boolean);
    setTabChange(true);
    setTimeout(() => {
      setTabChange(false);
    }, 200);
  };

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.1 && moreReadWebRecords && isRead) {
      getReadNotifications(readNotificationsOffsetCounter);
      increaseReadCounter(readNotificationsOffsetCounter + 1);
    }

    if (intersectionRatio >= 0.1 && moreUnreadWebRecords && !isRead) {
      getUnreadNotifications(unreadNotificationsOffsetCounter);
      increaseUnreadCounter(unreadNotificationsOffsetCounter + 1);
    }
  }, [entry]);

  useEffect(() => {
    if (
      !muteState &&
      readNotifications.length == 0 &&
      unreadNotifications.length == 0 &&
      !loadingNotifications
    ) {
      getUnreadNotifications(0);
      getReadNotifications(0);
    }
  }, []);

  useEffect(() => {
    if (isRead) {
      setCards(readNotifications);
    }
    if (!isRead) {
      setCards(unreadNotifications);
    }
  }, [readNotifications, unreadNotifications, isRead]);

  const [activeValue, setActiveValue] = useState('option-1');

  return (
    <>
      <span className="z-20 w-full">
        <div className="flex flex-col justify-between py-2 md:px-4">
          <ToggleGroup
            type="single"
            className="my-0 w-full rounded-xl border-[1px] border-gray-200 p-1"
            value={activeValue} // Set the active value
            onValueChange={(value) => {
              setActiveValue(value); // Update active state
              tabChangeFunc(value === 'option-2');
            }}
          >
            <ToggleGroupItem
              value="option-1"
              onClick={() => tabChangeFunc(false)}
              size="sm"
              variant="ghost"
              className="data-[state=on]:bg-tertiary w-full data-[state=on]:text-white"
            >
              Unread ({unreadNotifications.length})
            </ToggleGroupItem>
            <ToggleGroupItem
              value="option-2"
              onClick={() => tabChangeFunc(true)}
              className="data-[state=on]:bg-tertiary w-full data-[state=on]:text-white"
              size="sm"
              variant="ghost"
            >
              Read ({readNotifications.length})
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
      </span>
      {muteState ? (
        <div className={classes.bellPlaceHolder}>
          <FiBellOff size={120} />
          <p className={classes.muted}>Notifications Muted</p>
        </div>
      ) : (
        <div
          className={
            'flex h-screen w-full flex-col gap-2 overflow-auto px-0.5 pb-1 pt-1 opacity-100 transition-all md:px-4'
          }
        >
          {loadingNotifications && cards.length == 0 ? (
            <div className={classes.stagesLoader}>
              <Lottie
                options={{
                  animationData: animationData,
                }}
                height={100}
                width={100}
              />
            </div>
          ) : !isRead &&
            !loadingNotifications &&
            unreadNotifications.length < 1 ? (
            <motion.div
              initial={{ x: '-100px', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: '-100px', opacity: 0 }}
              className="flex h-full flex-col items-center justify-center gap-2"
            >
              <Inbox size={24} />
              <h2 className="text-base font-medium">You're all caught up</h2>
              <p className="max-w-[280px] text-center text-sm text-gray-400">
                You'll be notified here for any new task activity including
                comments, status changes and priority updates{' '}
              </p>
            </motion.div>
          ) : isRead &&
            !loadingNotifications &&
            readNotifications.length < 1 ? (
            <h2 className="flex h-full items-center justify-center">
              No Read Notifications
            </h2>
          ) : (
            <>
              {cards.map((n, index) => {
                // remove html tags
                const removedTags = n.task.title
                  ? n.task.title.replace(/(<([^>]+)>)/gi, '')
                  : n.description
                    ? n.description
                    : null;
                // remove hyphen from status
                const formattedStatus = n.type_status
                  ? n.type_status.replace(/-/gi, ' ')
                  : null;
                return (
                  <>
                    <div
                      key={index}
                      className={cn(
                        'mb-1',
                        removingCard == n.id ? 'hidden' : ''
                      )}
                    >
                      <div className="hover:outline-tertiary flex flex-col gap-2 rounded-xl p-3 outline outline-1 outline-gray-200 transition-all hover:outline-2">
                        <div className="flex w-full flex-row items-start gap-2">
                          <div className="flex w-full flex-col text-sm">
                            <div className="mb-2 mt-1 flex flex-row items-center justify-between">
                              <span className="text-tertiary flex w-fit flex-row items-center gap-1 truncate rounded-md py-0.5 text-sm font-medium">
                                <NotificationTypes
                                  n={n}
                                  formattedStatus={formattedStatus}
                                />
                              </span>
                              <span className="text-sm text-gray-400">
                                {n.created_at}
                              </span>
                            </div>
                            <span className="font-sm font-base mb-2 flex w-full items-center justify-between">
                              <span className="flex flex-row items-center gap-2">
                                <Avatar
                                  src={n.user?.avatar}
                                  className=""
                                  id={n.user?.normal_id}
                                  title={`${n.user.is_guest ? 'Guest User' : n.user?.name}`}
                                  size="sm"
                                  borderRadius="md"
                                />
                                <span className="max-w-[270px] truncate text-base font-medium capitalize">
                                  {n.user.is_guest
                                    ? 'Guest User'
                                    : n.user?.name}
                                  {n.site?.name && ` - ${n.site.name}`}
                                </span>
                              </span>
                            </span>
                            <span className="line-clamp-2 text-left">
                              {removedTags}
                            </span>
                          </div>
                        </div>
                        <div className="mt-2 flex flex-row justify-between">
                          <Button
                            variant="ghost"
                            size="sm"
                            leftIcon={<Eye size={16} />}
                            loading={updatingNotification}
                            onClick={() => {
                              notificationDetails(n, index);
                            }}
                          >
                            View Task
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="border-primary text-tertiary w-fit self-end text-sm"
                            loading={updatingNotification}
                            leftIcon={
                              n.is_read ? (
                                <MessageSquareDot size={16} />
                              ) : (
                                <Check size={16} />
                              )
                            }
                            onClick={(e) => {
                              if (n.is_read) {
                                handleClick(e, n.id, false, index);
                              } else {
                                handleClick(e, n.id, true, index);
                              }
                            }}
                          >
                            {n.is_read ? 'Mark As Unread' : 'Mark As Read'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {(isRead && moreReadWebRecords) ||
              (!isRead && moreUnreadWebRecords) ? (
                <Typography
                  className="text-tertiary flex h-5 items-center justify-center px-3 text-base"
                  variant="h5"
                  component="h5"
                  ref={setTarget}
                >
                  Loading...
                </Typography>
              ) : null}
            </>
          )}
        </div>
      )}
      {!isRead && unreadNotifications.length > 0 && (
        <motion.div
          initial={{ y: '100px' }}
          animate={{ y: ['100px', '-20px', '0'] }}
          exit={{ y: '100px' }}
          transition={{ duration: 0.2, delay: 0.2, spring: 0.2 }}
          className="w-full bg-white md:px-4 md:py-3"
        >
          <Button
            onClick={() => {
              unreadNotifications.length > 0 &&
                checkAllRead({ mark_as_read: true });
            }}
            variant="outline"
            className="hover:bg-primary hover:text-secondary w-full text-sm"
            leftIcon={<ListChecks size={18} />}
            loading={updatingNotification}
          >
            Mark All As Read
          </Button>
        </motion.div>
      )}
    </>
  );
};

export default Notifications;
