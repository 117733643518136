import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CheckCircle, CircleAlert, Info, Loader, XCircle } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TagManager from 'react-gtm-module';
import 'react-notifications/lib/notifications.css';
import { Toaster, ToasterProps } from 'sonner';
import { theme } from 'theme';
import { SidebarProvider } from 'ui/components/Sidebar/index.js';
import { TooltipProvider } from 'ui/components/Tooltip/index.js';
import './App.css';
import MaintenancePage from './components/Maintenanace.js';
import Layout from './containers/Layout.js';
import { useAppSelector } from './hooks/redux.js';
import {
  ModalProvider
} from './providers/modal-provider.js';
import './style.css';
const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();
queryClient.setQueryDefaults(['authenticate'], {
  staleTime: Infinity,
  gcTime: Infinity,
});

const toasterProps: ToasterProps = {
  toastOptions: {
    classNames: {},
  },
  closeButton: false,
  position: 'top-right',
  icons: {
    success: <CheckCircle size={20} className="text-ata-green" />,
    error: <XCircle size={20} className="text-ata-pink" />,
    warning: <CircleAlert size={20} className="text-ata-orange" />,
    info: <Info size={20} className="text-ata-light-blue" />,
    loading: <Loader size={20} className="text-ata-purple" />,
  },
};



function App() {
  const isInMaintenance = useAppSelector(
    (state) => state.app.isInMaintenance
  );
  const posthogClient = usePostHog();
  posthogClient.init(import.meta.env.VITE_APP_POSTHOG_PROJECT_API_KEY, {
    api_host: import.meta.env.VITE_APP_POSTHOG_API_HOST || 'https://hedgehog.atarimworker.io'
  });
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    posthogClient.set_config({ disable_session_recording: true });
  }
  if (isInMaintenance) {
    return <MaintenancePage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster {...toasterProps} />
      <SidebarProvider>
        <TooltipProvider delayDuration={200}>
          <ModalProvider>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <div
                  className="isolate flex h-screen w-full overflow-hidden"
                  vaul-drawer-wrapper=""
                >
                  <Layout />
                </div>
              </DndProvider>
            </ThemeProvider>
          </ModalProvider>
        </TooltipProvider>
      </SidebarProvider>
    </QueryClientProvider>
  );
}

export default App;
