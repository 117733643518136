import store from '@src/store/index';
import {
  hideMaintenancePage,
  showMaintenancePage,
} from '@src/store/slices/global';
import axios from 'axios';
import { maintenancePollingInterval } from 'utils/constants';

export const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const api = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

async function handleMaintenanceMode() {
  store.dispatch(showMaintenancePage());

  while (true) {
    await new Promise((resolve) =>
      setTimeout(resolve, maintenancePollingInterval)
    );
    try {
      const response = await fetch(`${baseUrl}/up`);
      if (response.status === 200) {
        store.dispatch(hideMaintenancePage());
        return true;
      }
    } catch (error) {
      // keep polling
    }
  }
}

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response?.status === 503) {
      // Handle maintenance mode
      await handleMaintenanceMode();
      // Retry the original request
      return api.request(error.config);
    }

    // extract error message
    if (error.response?.data?.message) {
      error.message = error.response.data.message;
    }

    // extract validation errors
    if (error.response?.data?.errors) {
      // combine all validation errors into a single message
      const errors = Object.values(error.response.data.errors).flat();
      error.errors = {
        message: errors.join(', '),
        ...error.response.data.errors,
      };
    }
    return Promise.reject(error);
  }
);

export default api;
