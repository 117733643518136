import { useEffect, useState } from 'react';
//components
import CheckedIcon from 'media/svgs/check-mark.svg?react';
import { Slider, Tabs, Tooltip } from 'ui/components';
//styling
import {
  // Dialog,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import animationData from 'media/jsons/spinner.json';
import Lottie from 'react-lottie';
import { NotificationManager } from 'react-notifications';
import { Button } from 'ui/components';
// utils
import { motion } from 'framer-motion';
import { MoveRight, Star, X } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog } from 'ui/components';
import { tableData } from 'utils/planTable';

const makeClasses = makeStyles((theme) => ({
  currentPlan: {
    color: '#6D5DF3',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  stagesLoader: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  moreSeats: {
    width: '25%',
    padding: '25px',
    position: 'relative',
    color: '#363D4D',
    borderRadius: '10px !important',
    animation: `$slideAnimateDown 0.2s ease-out forwards`,
    background: '#fff',
    boxShadow: '0px 0px 49.2353px rgba(0, 0, 0, 0.16)',
    overflow: 'hidden',
    fontFamily: theme.text.normalText.fontFamily,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: '100%',
      left: 0,
      height: '5px',
      backgroundColor: '#6D5DF3',
      borderRadius: '25px 25px 0px 0px',
    },
  },
  moreSeatsAnimateUp: {
    width: '25%',
    padding: '25px',
    color: '#363D4D',
    borderRadius: '10px',
    animation: `$slideAnimateUp 0.2s ease-out forwards`,
    background: '#fff',
    boxShadow: '0px 0px 49.2353px rgba(0, 0, 0, 0.16)',
    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '5px',
      backgroundColor: '#6D5DF3',
    },
  },
  '@keyframes slideAnimateDown': {
    '0%': {
      opacity: 0,
      borderRadius: '0px',
      height: '0px',
    },
    '33%': {
      opacity: 0,
      borderRadius: '5px',
      height: '77px',
    },
    '66%': {
      opacity: 0,
      borderRadius: '5px',
      height: '154px',
    },
    '100%': {
      opacity: 1,
      borderRadius: '10px',
      height: '194px',
    },
  },
  '@keyframes slideAnimateUp': {
    '0%': {
      opacity: 1,
      borderRadius: '10px',
      height: '194px',
    },
    '25%': {
      opacity: 0,
      borderRadius: '5px',
      height: '174px',
    },
    '50%': {
      opacity: 0,
      borderRadius: '5px',
      height: '116px',
    },
    '75%': {
      opacity: 0,
      borderRadius: '5px',
      height: '58px',
    },
    '100%': {
      opacity: 0,
      borderRadius: '0px',
      height: '0px',
      padding: '0px 0px',
      display: 'none',
    },
  },

  moreSeatsdesc: {
    marginBottom: '15px',
    fontSize: '16px',
  },

  popoverDiv: {
    padding: '10px',
    marginTop: '-30px',
    marginLeft: '-50px',
    fontFamily: theme.text.normalText.fontFamily,
    position: 'absolute',
    background: '#fcfcfc',
    boxShadow:
      '#33333333 0px 5px 5px -3px, #24242423 0px 8px 10px 1px, #1E1E1E1F 0px 3px 14px 2px',
    zIndex: 100,
    cursor: 'pointer',
    borderRadius: '8px',
    animation: `$popoverAnimate 0.2s ease-out forwards`,
  },
  '@keyframes popoverAnimate': {
    '0%': {
      opacity: 0,
      padding: '8px',
    },
    '25%': {
      opacity: 0.5,
      padding: '8px',
    },
    '50%': {
      opacity: 1,
      padding: '10px',
    },
    '100%': {
      opacity: 1,
      padding: '10px',
    },
  },

  basePrice: {
    color: '#363D4D',
    fontSize: '16px',
    margin: '8px 0px',
  },

  customTooltip: {
    marginTop: '-76px',
    position: 'absolute',
    backgroundColor: '#000',
    padding: '5px 8px',
    borderRadius: '5px',
    fontSize: '12px',
    letterSpacing: '.5px',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginLeft: '-4px',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  tooltipArrow: {
    color: '#000',
    position: 'absolute',
    marginTop: '-50px',
    marginLeft: '-4px',
    userSelect: 'none',
    pointerEvents: 'none',
  },

  headTd: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    padding: '15px 15px 15px 15px',
    verticalAlign: 'middle',
    backgroundColor: '#FFFFFF',
    width: '14%',
  },
  headTdMain: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    padding: '15px 15px 15px 15px',
    verticalAlign: 'middle',
    backgroundColor: '#FFFFFF',
    width: '30%',
  },

  dataItem: {
    verticalAlign: 'middle',
    padding: '10px 15px 10px 15px',
  },
  dataContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '1.3em',
    fontFamily: theme.text.normalText.fontFamily,
    '& svg': {
      strokeWidth: 1,
    },
  },

  tableHead: {
    textAlign: 'left',
    position: 'sticky',
    top: 0,
  },

  featureAvailable: {
    color: '#6D5DF3',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

const PlanUpgrading = (props) => {
  const {
    title,
    description,
    isChangingInterval,
    setIsChangingInterval,
    redirectTo,
    featureAvailable,
  } = props;

  const dispatch = useDispatch();
  const {
    pricingPlans,
    upgradePlansLoading,
    isPlansDialogOpened,
    userData: user,
    hostedPagedata,
    startingCheckout,
    plansSuccessMessage,
    plansErrorMessage,
    planSearch,
  } = useSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const [yearlyPlan, setYearlyPlan] = useState('Yearly');
  const [persons, setPersons] = useState(user?.plan?.users);
  const [defaultPersons, setDefaultPersons] = useState(user?.plan?.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPriceUpdated, setIsPriceUpdated] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState('');
  const [isMoreSeatsVisible, setIsmoreSeatsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [enterpriseFeature, setEnterpriseFeature] = useState({});
  const [coupon, setCoupon] = useState('');
  const [couponData, setCouponData] = useState(null);

  const [defaultTitle] = useState('Cheaper Than a Lagging Project');
  const [featureTitle] = useState(['free', 'Pro', 'Business']);
  const [defaultDescription] = useState(
    'Take control of your workflow with Atarim and stop wasting your time talking to your clients and get to the actual work by collaborating with them instead.'
  );

  const returnPlanPeriodValue = (month, year, biYear) => {
    let cost;
    if (yearlyPlan === 'Yearly') {
      cost = year;
    } else if (yearlyPlan === 'Monthly') {
      cost = month;
    } else {
      cost = biYear;
    }
    return cost;
  };
  const yearlyUsersLimit = pricingPlans.map((p) => p.max_users_yearly);
  const MonthlyUsersLimit = pricingPlans.map((p) => p.max_users_monthly);
  const BiYearlyUsersLimit = pricingPlans.map((p) => p.max_users_biyearly);
  const maxYearlyUsersLimit = Math.max(...yearlyUsersLimit);
  const maxMonthlyUsersLimit = Math.max(...MonthlyUsersLimit);
  const maxBiyearlyUsersLimit = Math.max(...BiYearlyUsersLimit);
  const maxSliderValue = returnPlanPeriodValue(
    maxMonthlyUsersLimit,
    maxYearlyUsersLimit,
    maxBiyearlyUsersLimit
  );

  const addedUsers = user.plan?.users - user.limit?.users;

  const classes = makeClasses({ yearlyPlan });
  const theme = useTheme();

  // we have to call the API on the button on which the dialog opens
  useEffect(() => {
    dispatch({ type: 'UPGRADE_PLANS' });
    // upgradePlans();
  }, []);

  // initiating chargebee
  let cbInstance = window.Chargebee.init({
    site: user?.payment_site ? user?.payment_site : 'atarimbilling',
  });

  const couponValue = searchParams.get('coupon') ?? '';

  function getCoupon(type, plan) {
    // type can be 'monthly' or 'yearly'
    // plan can be 'business' or 'pro'

    if (!couponData) return;
    if (couponData[type] && couponData[type][plan]) {
      return couponData[type][plan];
    } else {
      return null;
    }
  }

  const openCheckoutFunc = (card, clickStart = false, index = 0) => {
    setSelectedPlanId(card.id);

    let dynamicCoupon;
    if (clickStart) {
      dynamicCoupon = getCoupon(
        returnPlanPeriodValue('monthly', 'yearly', 'biyearly'),
        card?.display_name
      );
    }
    const planId = card.plans[returnPlanPeriodValue(1, 0, 2)]?.plan_id;
    const rawId = card.plans[returnPlanPeriodValue(1, 0, 2)]?.id;
    const amount = calculatePrice(card, index);

    const data = {
      plan_id: planId,
      rawId: rawId,
      amount: amount,
      users: persons,
      rank: card.rank,
      cycle: returnPlanPeriodValue('monthly', 'yearly', 'biyearly'),
      coupon: dynamicCoupon || coupon || couponValue,
    };

    setSelectedPlanData(data);
    // fetching the hosted page data
    dispatch({ type: 'OPEN_CHECKOUT', params: data });
    setCoupon('');
  };

  const navigate = useNavigate();

  const gotoPrevPage = () => {
    // if updateIndex is there || from dashboard page
    if (redirectTo !== undefined && redirectTo !== '/dashboard') {
      const redirectUrl = `/${redirectTo}`;
      // (fromPageIndex == 10) is for dashboard on which we currently are so no need
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    // initiate the chargebee modal once the response is there in state
    if (Object.keys(hostedPagedata).length > 0) {
      let isCheckoutSuccessful = false;
      cbInstance.openCheckout({
        hostedPage: async function () {
          return hostedPagedata;
        },
        close: function () {
          // removeHostedPageData();
          dispatch({ type: 'REMOVE_HOSTED_PAGE_DATA' });
          // closing the dialogs if the checkout is done
          if (isCheckoutSuccessful) {
            dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
            // to the page from where came for plan updation
            gotoPrevPage();
          }
        },
        success: function (hostedPageId) {
          // update the variable so to close the dialogs
          isCheckoutSuccessful = true;
          window.dataLayer.push({
            event: 'Checkout_Complete',
          });
          dispatch({
            type: 'SUBSCRIPTION_DETAILS',
            id: hostedPageId,
            rawId: selectedPlanData?.rawId,
            users: selectedPlanData?.users,
          });

          dispatch({ type: 'REMOVE_HOSTED_PAGE_DATA' });
          //Send confirmation to extension but issue is that extension closes the tab
          //Need to make some changes to the extension side before uncommenting this
          // sendMessageToExtension({
          //     type: 'planUpgrade'
          // });
        },
        step: function (value) {
          // value -> which step in checkout
        },
      });
    }
  }, [hostedPagedata]);

  const sliderChange = (e, newValue, index) => {
    if (!isAnimating) {
      setPersons(newValue + 1);

      setDefaultPersons(newValue + 1);
      // for anomating the more seats container
      if (newValue === maxSliderValue) {
        setIsmoreSeatsVisible(true);
      } else {
        if (isMoreSeatsVisible) {
          setIsAnimating(true);
          setTimeout(() => {
            setIsAnimating(false);
            setIsmoreSeatsVisible(false);
          }, 200);
        }
      }
    }
  };

  // The plan on which the user already is
  const currentPlan = pricingPlans.filter(
    (p) => p.rank === user?.plan?.rank
  )[0];

  useEffect(() => {
    const planPeriod = user?.plan?.cycle;
    if (planPeriod) {
      setYearlyPlan(convertToCaseString(planPeriod));
    } else {
      setYearlyPlan('Yearly');
    }
  }, []);
  const calculateMaxUsers = (card) => {
    let maxUsers;
    if (yearlyPlan === 'Yearly') {
      maxUsers = card.max_users_yearly;
    } else if (yearlyPlan === 'Monthly') {
      maxUsers = card.max_users_monthly;
    } else {
      maxUsers = card.max_users_biyearly;
    }
    return maxUsers;
  };

  const calculateBasePrice = (card) => {
    let basePrice;
    if (yearlyPlan === 'Yearly') {
      basePrice = card.base_price_yearly;
    } else if (yearlyPlan === 'Monthly') {
      basePrice = card.base_price_monthly;
    } else {
      basePrice = card.base_price_biyearly;
    }
    return basePrice;
  };

  const calculatePrice = (card, index) => {
    // calculating the price of each plan
    const maxUsers = calculateMaxUsers(card);
    const basePrice = calculateBasePrice(card);
    const userCostYearly =
      (persons <= maxUsers ? persons - 1 : maxUsers - 1) *
      card.plans[0]?.extra_seat_info_yearly;
    const userCostMonthly =
      (persons <= maxUsers ? persons - 1 : maxUsers - 1) *
      card.plans[1]?.extra_seat_info_monthly;
    const userCostBiYearly =
      (persons <= maxUsers ? persons - 1 : maxUsers - 1) *
      card.plans[2]?.extra_seat_info_biyearly;
    const totalCost =
      returnPlanPeriodValue(userCostMonthly, userCostYearly, userCostBiYearly) +
      Math.round(basePrice);
    return totalCost;
  };

  const disabledButton = (card, index) => {
    // Determine max users based on the billing cycle
    const maxUsers =
      yearlyPlan === 'Yearly'
        ? card.max_users_yearly
        : yearlyPlan === 'Biyearly'
          ? card.max_users_biyearly
          : card.max_users_monthly;

    return (
      // Check if the user's current plan matches the selected plan and cycle
      (yearlyPlan === 'Yearly' &&
        user?.plan?.cycle === 'Yearly' &&
        persons === user?.plan?.users &&
        user.subscription &&
        currentPlan?.rank === card?.rank) ||
      (yearlyPlan === 'Monthly' &&
        user?.plan?.cycle === 'Monthly' &&
        persons === user?.plan?.users &&
        user.subscription &&
        currentPlan?.rank === card?.rank) ||
      (yearlyPlan === 'Biyearly' &&
        user?.plan?.cycle === 'Biyearly' &&
        persons === user?.plan?.users &&
        user.subscription &&
        currentPlan?.rank === card?.rank) ||
      // Check if the number of persons exceeds the max users for the selected billing cycle
      persons > maxUsers ||
      // Check if added users exceed the number of persons selected
      addedUsers > persons
    );
    //  this is the conditionnal check that return either true or false
  };

  const getButtonText = (card, index) => {
    if (persons === user?.plan?.users && currentPlan?.rank === card?.rank) {
      if (
        (yearlyPlan && user?.plan?.cycle === 'Yearly') ||
        (!yearlyPlan && user?.plan?.cycle === 'Monthly')
      ) {
        if (!user.subscription) {
          if (startingCheckout && card.id === selectedPlanId) {
            return 'Starting...';
          } else {
            return 'Renew Subscription';
          }
        } else {
          return 'Already On Plan';
        }
      }
    }
    if (startingCheckout && card.id === selectedPlanId) {
      return 'Starting...';
    }
    return 'Start Now';
  };

  useEffect(() => {
    // if the price is updated then inform the user about
    pricingPlans.map((card, i) => {
      if (persons[i] === user?.plan?.users && currentPlan?.rank === card.rank) {
        if (
          (yearlyPlan && user?.plan?.cycle === 'Yearly') ||
          (yearlyPlan && user?.plan?.cycle === 'Monthly') ||
          (yearlyPlan && user?.plan?.cycle === 'Biyearly')
        ) {
          // notifiy user that price is changed
          if (yearlyPlan === 'Yearly') {
            if (
              Number(card.plans[0].selling_price) !==
              Number(card.max_users_yearly) * (card.plans[0].user_limit - 1) +
                Number(card.base_price_yearly)
            ) {
              !isPriceUpdated && setIsPriceUpdated(true);
            }
          } else if (yearlyPlan === 'Monthly') {
            if (
              Number(card.plans[1].selling_price) !==
              Number(card.max_users_monthly) * (card.plans[1].user_limit - 1) +
                Number(card.base_price_monthly)
            ) {
              !isPriceUpdated && setIsPriceUpdated(true);
            }
          } else if (yearlyPlan === 'Biyearly') {
            if (
              Number(card.plans[2].selling_price) !==
              Number(card.max_users_biyearly) * (card.plans[2].user_limit - 1) +
                Number(card.base_price_biyearly)
            ) {
              !isPriceUpdated && setIsPriceUpdated(true);
            }
          }
        }
      }
    });
    pricingPlans.map((each) =>
      each.name === 'Enterprise' ? setEnterpriseFeature(each) : each
    );
  }, [pricingPlans]);

  // displaying the popover
  const handleOpen = (id) => {
    setAnchorEl(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchChange = (e) => {
    setYearlyPlan(e.target.checked);
  };

  useEffect(() => {
    // if the dialog opens up to change interval
    // we want to auto set the inverse of cycle of what user is already on
    if (isChangingInterval) {
      // check if the user has yearly plan or monthly plan and set it inversely
      const planPeriod = user?.plan?.cycle;
      if (planPeriod) {
        setYearlyPlan(convertToCaseString(planPeriod));
      } else {
        setYearlyPlan('Yearly');
      }
    }
  }, []);

  const closePlansDialog = () => {
    if (isChangingInterval) {
      // user Opened dialog from some where to change plans cycle/interval
      setIsChangingInterval(false);
    }
    if (redirectTo) {
      gotoPrevPage();
    }
    dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
  };

  const convertToCaseString = (planPeriod) => {
    const periodCaseString = planPeriod
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return periodCaseString;
  };

  function formatPrice(price) {
    // Check if the number has a fractional part and if it is 0.5
    if (price % 1 === 0) {
      // Whole number, no decimals needed
      return price.toFixed(0);
    } else if ((price * 10) % 10 === 5) {
      // .5 decimal, show only one decimal place
      return price.toFixed(2);
    }
  }

  useEffect(() => {
    if (planSearch) {
      if (planSearch.coupon) {
        // if there is any coupon
        setCoupon(planSearch.coupon);
      }
      // when the user is navigated from the main pricing page to dashboard
      // params stored in dashboard page state
      if (Object.keys(planSearch).length >= 3) {
        planSearch.users && setPersons(Number(planSearch.users));

        planSearch.users && setDefaultPersons(Number(planSearch.users));

        const planPeriod = planSearch.billed;

        if (planPeriod) {
          setYearlyPlan(convertToCaseString(planPeriod));
        } else {
          setYearlyPlan('Yearly');
        }

        // now we want to initiate chargebee modal like as we click on start now button

        const targetCard = pricingPlans.filter(
          (c) => c.name.toLowerCase() === planSearch.plan
        )[0];
        if (!selectedPlanId && targetCard) {
          dispatch({ type: 'CLEAR_PLAN_SEARCH' });
          openCheckoutFunc(targetCard);
        }
      }
    }
  });

  useEffect(() => {
    if (plansErrorMessage) {
      NotificationManager.error(plansErrorMessage, '', 5000);
      dispatch({ type: 'EMPTY_PLANS_MESSAGE' });
    }
    if (plansSuccessMessage) {
      NotificationManager.success(plansSuccessMessage, '', 5000);
      dispatch({ type: 'EMPTY_PLANS_MESSAGE' });
    }
  }, [plansErrorMessage, plansSuccessMessage]);

  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      const couponJson = posthog.getFeatureFlagPayload('Coupons');
      if (couponJson) {
        setCouponData(couponJson);
      }
    }
  }, [posthog]);

  const closeDialog = () => {
    if (user.subscription) {
      dispatch({ type: 'HANDLE_PLANS_DIALOG', params: false });
    }
  };

  return (
    <Dialog
      open={isPlansDialogOpened || !user.subscription}
      modal={false}
      className="z-20 m-auto my-0 w-[750px] rounded-none"
    >
      <Dialog.Portal className="m-auto my-0 w-[750px] rounded-none">
        <Dialog.Overlay className="" />
        <Dialog.Content
          className="isolate z-20 m-auto my-0 h-[100vh] max-w-[100%] overflow-y-auto rounded-none px-2 sm:rounded-none md:p-0"
          showClose={false}
        >
          {upgradePlansLoading ? (
            <div className={classes.stagesLoader}>
              <Lottie
                options={{
                  animationData: animationData,
                }}
                height={150}
                width={150}
              />
            </div>
          ) : (
            <div className="flex w-full flex-col items-center">
              <div className="">
                {user.subscription ? (
                  <X
                    onClick={closePlansDialog}
                    className="absolute right-4 top-4 cursor-pointer"
                  />
                ) : (
                  <span></span>
                )}

                <div className="my-10 flex flex-col items-center gap-3 md:my-20 md:gap-2">
                  {/* Black Friday header banner */}
                  {/* I'm commenting this out because it's not needed anymore and keeping comment incase we need for future black friday */}
                  {/* <div className="bg-tertiary/10 border-tertiary text-tertiary text-semibold sm:before:from-tertiary sm:after:from-tertiary relative rounded-xl border px-6 py-1 sm:before:absolute sm:before:left-[-110px] sm:before:top-1/2 sm:before:h-[1px] sm:before:w-[100px] sm:before:-translate-y-1/2 sm:before:bg-gradient-to-l sm:after:absolute sm:after:right-[-110px] sm:after:top-1/2 sm:after:h-[1px] sm:after:w-[100px] sm:after:-translate-y-1/2 sm:after:bg-gradient-to-r">
                    Black Friday Limited Time Offer
                  </div> */}
                  <h1 className="text-secondary text-center text-4xl font-bold md:text-[52px] md:leading-relaxed">
                    Cheaper Than a Lagging Project
                  </h1>
                  <p className="max-w-[700px] text-center text-base">
                    Thousands of teams and agencies, just like yours, have seen
                    <span className="underline">
                      {' '}
                      positive ROI within their first month / first project
                    </span>{' '}
                    of rolling out Atarim throughout their team and
                    stakeholders.
                  </p>
                  <div className="mt-2 flex flex-col items-center gap-2 md:flex-row">
                    <div className="flex flex-row">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Star
                          size={16}
                          fill="gold"
                          color="gold"
                          strokeWidth={1.5}
                        />
                      ))}
                    </div>
                    <span className="text-sm text-gray-400">
                      196 Reviews | 51k+ teams | 1.4m stakeholders
                    </span>
                  </div>
                </div>
                <div className="relative mt-12 flex flex-col items-center justify-center md:mx-auto md:mt-0 md:w-[389px]">
                  {yearlyPlan === 'Yearly' && (
                    <motion.div
                      initial={{ opacity: 0, top: 0 }}
                      animate={{ opacity: 1, top: '-30px' }}
                      exit={{ opacity: 0, top: 0 }}
                      className="text-tertiary absolute font-bold"
                    >
                      2 months free
                    </motion.div>
                  )}
                  {yearlyPlan === 'Biyearly' && (
                    <motion.div
                      initial={{ opacity: 0, top: 0, right: '10px' }}
                      animate={{ opacity: 1, top: '-30px', right: '10px' }}
                      exit={{ opacity: 0, top: 0, right: '10px' }}
                      className="text-tertiary absolute font-bold"
                    >
                      9 months free
                    </motion.div>
                  )}
                  <Tabs
                    defaultValue={yearlyPlan || 'Yearly'}
                    className="mb-4 w-full max-w-[390px] rounded-xl"
                    onValueChange={(e) => {
                      setYearlyPlan(e);
                    }}
                  >
                    <Tabs.List>
                      <Tabs.Trigger value="Monthly">
                        Billed Monthly
                      </Tabs.Trigger>
                      <Tabs.Trigger value="Yearly">Billed Yearly</Tabs.Trigger>
                      {/* I'm commenting this out because it's not needed anymore and keeping comment incase we need for future black friday */}
                      {/* <Tabs.Trigger value="Biyearly">
                        Billed 2 Years
                      </Tabs.Trigger> */}
                    </Tabs.List>
                  </Tabs>
                  {/* {isMoreSeatsVisible && (
                    <div
                      className={
                        'animate-blurred-fade-in flex w-full max-w-[390px] flex-col gap-4 rounded-xl border border-gray-200 bg-white p-6'
                      }
                    >
                      <h2 className="text-secondary text-xl font-bold">
                        Need {maxSliderValue + '+'} Team Seats?
                      </h2>
                      <p className="">
                        Talk to use for a comprehensive soliution that meets all
                        of your enterprise needs.
                      </p>
                      <div className="">
                        <Button
                          variant="primary"
                          size="sm"
                          className="w-full text-sm"
                          rightIcon={<MoveRight size="18" />}
                          onClick={() =>
                            window.open('https://getgist.me/vito/schedule')
                          }
                        >
                          Book a Call
                        </Button>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="grid w-full max-w-[1200px] grow justify-center gap-4 pb-2 md:mt-4 md:grid-cols-3">
                  {pricingPlans
                    .filter((eachPlan) => eachPlan.name !== 'Enterprise')
                    .map((card, index) => {
                      return (
                        <div
                          key={index}
                          className="flex w-full max-w-[390px] flex-col justify-start gap-6 rounded-2xl border border-gray-200 bg-white p-6"
                        >
                          <div className="flex flex-col gap-3">
                            <div className="relative flex items-center gap-2">
                              <div
                                className={`absolute right-0 top-0 flex hidden flex-col justify-end gap-1`}
                              >
                                <span className="bg-tertiary w-fit items-center self-end rounded-lg px-2 py-1 text-center text-xl font-bold text-white">
                                  -$
                                  {formatPrice(
                                    calculatePrice(card, index) *
                                      returnPlanPeriodValue(1, 0.5, 0.375)
                                  )}
                                  {/* -50% */}
                                </span>
                                <span className="text-tertiary flex justify-end">
                                  Usually ${calculatePrice(card, index)}
                                </span>
                              </div>
                              <h2 className="text-secondary text-2xl font-bold">
                                {card?.display_name}
                              </h2>
                              <div>
                                {featureAvailable &&
                                  featureAvailable[index] && (
                                    <span className={classes.featureAvailable}>
                                      Feature Available
                                    </span>
                                  )}
                                {card?.plans[returnPlanPeriodValue(1, 0, 2)]
                                  ?.current_plan && (
                                  <span className="text-tertiary font-bold">
                                    {user.subscription
                                      ? 'Current Plan'
                                      : 'Expired Plan'}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-row items-end">
                              <span className="text-secondary text-5xl font-bold">
                                $
                                {formatPrice(
                                  calculatePrice(card, index) *
                                    returnPlanPeriodValue(1, 1, 1)
                                )}
                              </span>
                              {`/${returnPlanPeriodValue('monthly', 'yearly', '2 years')}`}
                            </div>

                            <p className="text-gray-400">
                              {returnPlanPeriodValue(
                                `+ $${formatPrice(card.plans[1]?.extra_seat_info_monthly / 1)}/month per extra seat`,
                                `+ $${formatPrice(card.plans[0]?.extra_seat_info_yearly / 2)}/year per extra seat (Usually $${card.plans[0]?.extra_seat_info_yearly})`,
                                `+ $${formatPrice(card.plans[2]?.extra_seat_info_biyearly * 0.625)}/month per extra seat (Usually $${card.plans[2]?.extra_seat_info_biyearly})`
                              )}
                            </p>
                            {
                              returnPlanPeriodValue(
                                card?.plans[1],
                                card?.plans[0],
                                card?.plans[2]
                              )?.extra_seat_info
                            }

                            <p className="">{card?.description}</p>
                          </div>
                          <div>
                            {anchorEl === card?.id && addedUsers > persons && (
                              <div
                                onMouseEnter={() => handleOpen(card?.id)}
                                onMouseLeave={handleClose}
                                className={classes.popoverDiv}
                                onClick={() => {
                                  navigate(`/users?userId=${user.id}`);
                                  dispatch({
                                    type: 'HANDLE_PLANS_DIALOG',
                                    params: false,
                                  });
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  You cannot buy this plan because you already
                                  have
                                  <b> {addedUsers} </b>
                                  users. <br /> If you want to mange users Click
                                  here.
                                </Typography>
                              </div>
                            )}

                            <div className="mb-4">
                              <div className="flex justify-between">
                                <span className="text-secondary text-sm font-bold">
                                  Number of Seats:
                                </span>
                                <Tooltip
                                  side="bottom"
                                  align="end"
                                  content="Applies to team members and regular contractors only. Stakeholders, clients and guests are unlimited."
                                >
                                  <span className="bg-tertiary rounded-lg px-2 text-sm text-white">
                                    {persons === 1
                                      ? 'Just Me'
                                      : persons > 15
                                        ? '15+ seats'
                                        : `${persons} seats`}
                                  </span>
                                </Tooltip>
                              </div>
                              <Slider
                                min={0}
                                max={maxSliderValue}
                                step={1}
                                className="py-2"
                                defaultValue={[persons - 1]}
                                value={[persons - 1]}
                                onValueChange={(value) =>
                                  sliderChange(null, value[0], index)
                                }
                                aria-label="Number of Team Members"
                                id={index}
                              />
                            </div>
                            <Button
                              onMouseEnter={() => handleOpen(card?.id)}
                              onMouseLeave={handleClose}
                              variant="primary"
                              disabled={disabledButton(card, index)}
                              className="w-full text-sm"
                              rightIcon={<MoveRight size="18" />}
                              onClick={() => {
                                if (!disabledButton(card, index)) {
                                  openCheckoutFunc(card, true);
                                }
                              }}
                            >
                              {getButtonText(card, index)}
                            </Button>
                          </div>
                          <div>
                            <h3 className="text-secondary text-lg font-bold">
                              Everything on{' '}
                              <span className="capitalize">
                                {featureTitle[index]}
                              </span>{' '}
                              +
                            </h3>
                            {card?.features?.checked?.map((each) => (
                              <p className="mt-2 flex gap-2 text-base">
                                <CheckedIcon className="mt-1" />
                                <span>{each}</span>
                              </p>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  <div className="flex max-w-[390px] flex-col justify-start gap-6 rounded-2xl border border-gray-200 bg-white p-6">
                    <div className="flex flex-col gap-3">
                      <div className="">
                        <h2 className="text-secondary text-2xl font-bold">
                          Enterprise
                        </h2>
                        {featureAvailable && featureAvailable[3] && (
                          <Typography className={classes.featureAvailable}>
                            Feature Available
                          </Typography>
                        )}
                      </div>
                      <span className="text-secondary text-5xl font-bold">
                        Custom
                      </span>
                      <p className="text-gray-400">
                        For large orgs & Hosting providers
                      </p>
                      <p className="">{enterpriseFeature?.description}</p>
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        rightIcon={<MoveRight size="18" />}
                        className="w-full text-sm"
                        onClick={() =>
                          window.open(
                            'https://atarim.io/book-a-call/?utm_campaign=book-call&utm_content=enterprise-pricing&utm_source=app.atarim.io'
                          )
                        }
                      >
                        Book a call
                      </Button>
                    </div>
                    <div>
                      <h3 className="text-secondary text-lg font-bold">
                        Everything on Business +
                      </h3>
                      {enterpriseFeature?.features?.checked.map((each, i) => (
                        <p key={i} className="mt-2 flex gap-2 text-base">
                          <CheckedIcon className="mt-1" />
                          <span>{each}</span>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="text-secondary pt-4 md:p-4 md:pt-[8vh]">
                  <h2 className="mx-auto mb-8 w-full text-center text-2xl font-semibold leading-[1.1em] md:text-[46px] lg:max-w-[900px]">
                    Speed up creative projects with fast feedback and a better
                    workflow.
                  </h2>
                  <table className="md:max-w[1400px] block max-w-[calc(100vw-30px)] overflow-x-scroll">
                    <thead className={classes.tableHead}>
                      <tr>
                        <th
                          dataSort="0"
                          className={classes.headTdMain}
                          scope="col"
                        >
                          <span className={classes.headContent}>
                            Feature Comparison
                          </span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Free</span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Pro</span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>Business</span>
                        </th>
                        <th dataSort="0" className={classes.headTd} scope="col">
                          <span className={classes.headContent}>
                            Enterprise
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((each, i) => (
                        <tr className={i % 2 === 0 ? 'bg-tertiary/5' : ''}>
                          {each.data.map(
                            (item, key) =>
                              key !== 2 && (
                                <td className={classes.dataItem}>
                                  <span className={classes.dataContent}>
                                    {item}
                                  </span>
                                </td>
                              )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {isPriceUpdated && (
                  <div className="mb-4 flex flex-row items-center justify-center gap-4 rounded-2xl border border-gray-100 p-4 text-center shadow-2xl">
                    <span>
                      You will be charged according to your previous
                      subscription until you purchase a new pricing plan.
                    </span>
                    <X
                      onClick={() => setIsPriceUpdated(false)}
                      size={18}
                      className="text-destructive cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  );
};

export default PlanUpgrading;
