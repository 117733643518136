import { useAppSelector } from '@src/hooks/redux';
import { motion } from 'framer-motion';
import { Progress } from 'ui';


function Resources() {
    const listItem = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -80 },
    };
    const {
        user
    } = useAppSelector((state) => ({
        user: state.user.userData,
    }));

    return (
        <>
            {/* PROJECTS */}
            <motion.div className="w-full" variants={listItem}>
                <div className="mb-1 flex justify-between text-sm">
                    <span>Projects</span>
                    <span>
                        {user.plan.websites > 0
                            ? `${user.plan.websites - user.limit.websites
                            }/${user.plan.websites}`
                            : user.plan.websites === -1
                                ? `${user.limit.websites}/Unlimited`
                                : `Maxed Out`}
                    </span>
                </div>
                <Progress size="sm" variant="secondary"
                    progress={
                        user.plan.websites === -1
                            ? 5
                            : ((user.plan.websites - user.limit.websites) /
                                user.plan.websites) *
                            100
                    }
                    className="" />
            </motion.div>
            {/* STORAGE */}
            <motion.div className="w-full" variants={listItem}>
                <div className="mb-1 flex justify-between text-sm">
                    <span>Storage</span>
                    <span>
                        {user.limit.storage_limit > 0
                            ? `${(
                                user.limit.storage_used_bytes / 1073741824
                            ).toFixed(2)}/${user.limit.storage_limit}GB`
                            : `${user.limit.storage_limit}GB`}
                    </span>
                </div>
                <Progress size="sm" variant="secondary"
                    progress={
                        (user.limit.storage_used_bytes /
                            1073741824 /
                            user.limit.storage_limit) *
                        100
                    }
                    className="" />
            </motion.div>
            {/* TEAM MEMBERS  */}
            <motion.div className="w-full" variants={listItem}>
                <div className="mb-1 flex justify-between text-sm">
                    <span>Team Members</span>
                    <span>
                        {user.plan.users > 0
                            ? `${user.plan.users - user.limit.users}/${user.plan.users
                            }`
                            : user.plan.users === -1
                                ? `${user.limit.users}/Unlimited`
                                : `Maxed Out`}
                    </span>
                </div>
                <Progress size="sm" variant="secondary"
                    progress={
                        ((user.plan.users - user.limit.users) /
                            user.plan.users) *
                        100
                    }
                    className="" />
            </motion.div>
            {/* WORKSPACES */}
            <motion.div className="w-full" variants={listItem}>
                <div className="mb-1 flex justify-between text-sm">
                    <span>Workspaces</span>
                    <span>
                        {user.plan.workspace > 0
                            ? `${user.plan.workspace -
                            user.limit.workspaces
                            }/${user.plan.workspace}`
                            : user.plan.workspace === -1
                                ? `${user.limit.workspaces}/Unlimited`
                                : `Maxed Out`}
                    </span>
                </div>
                <Progress size="sm" variant="secondary"
                    progress={
                        ((user.plan.workspace -
                            user.limit.workspaces) /
                            user.plan.workspace) *
                        100
                    } className="" />
            </motion.div>
        </>
    );
}
export default Resources;