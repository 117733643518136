import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShareProjectModalTab } from 'ui/components/ShareModal';

type AppState = {
  isInMaintenance: boolean;
  shareModalOpen: boolean | string;
  shareModalTab?: ShareProjectModalTab;
};

const initialState: AppState = {
  isInMaintenance: false,
  shareModalOpen: false,
  shareModalTab: 'team',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showMaintenancePage: (state) => {
      state.isInMaintenance = true;
    },
    hideMaintenancePage: (state) => {
      state.isInMaintenance = false;
    },
    setShareModalOpen: (state, action: PayloadAction<boolean | string>) => {
      if (action.payload === '') {
        state.shareModalOpen = false;
      } else {
        state.shareModalOpen = action.payload;
      }
    },
    setShareModalTab: (state, action: PayloadAction<ShareProjectModalTab>) => {
      state.shareModalTab = action.payload;
    },
  },
});

export const {
  showMaintenancePage,
  hideMaintenancePage,
  setShareModalOpen,
  setShareModalTab,
} = appSlice.actions;
export default appSlice.reducer;
