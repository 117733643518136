import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'utils/cn';

const inputVariants = cva(
  'bg-white ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 font-normal w-full rounded-md border border-input px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 invalid:[&:not(:placeholder-shown):not(:focus)]:border-destructive ',
  {
    variants: {
      variant: {
        primary: '',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
  VariantProps<typeof inputVariants> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = 'Input';

export { Input, inputVariants };

