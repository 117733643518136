// Saga Utils
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
// Actions
import {
  ADD_NEW_ENTRY,
  CLICKUP_CREATE_TIME_ENTRIES,
  CLICKUP_CREATE_TIME_ENTRY,
  CLICKUP_GET_TASKS,
  CUSTOM_DATE_FILTER,
  ERROR_ADD_NEW_ENTRY,
  ERROR_CLICKUP_CREATE_TIME_ENTRIES,
  ERROR_CLICKUP_CREATE_TIME_ENTRY,
  ERROR_CLICKUP_GET_TASKS,
  ERROR_DELETE_ENTRY,
  ERROR_FETCH_ALL_ENTRIES,
  ERROR_FETCH_NEXT_ENTRIES,
  ERROR_FETCH_NOT_LOGGED_ENTRIES,
  ERROR_TEAMWORK_CREATE_TIME_ENTRIES,
  ERROR_TEAMWORK_CREATE_TIME_ENTRY,
  ERROR_UPDATE_ENTRY,
  FETCH_ALL_ENTRIES,
  FETCH_NEXT_ENTRIES,
  FETCH_NOT_LOGGED_ENTRIES,
  NEXT_CUSTOM_DATE_FILTER,
  START_DELETE_ENTRY,
  START_FILTERING_ENTRIES,
  START_NEXT_FILTERING_ENTRIES,
  START_UPDATE_ENTRY,
  SUCCESS_ADD_NEW_ENTRY,
  SUCCESS_CLICKUP_CREATE_TIME_ENTRIES,
  SUCCESS_CLICKUP_CREATE_TIME_ENTRY,
  SUCCESS_CLICKUP_GET_TASKS,
  SUCCESS_DELETE_ENTRY,
  SUCCESS_FETCH_ALL_ENTRIES,
  SUCCESS_FETCH_NEXT_ENTRIES,
  SUCCESS_FETCH_NOT_LOGGED_ENTRIES,
  SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES,
  SUCCESS_TEAMWORK_CREATE_TIME_ENTRY,
  SUCCESS_UPDATE_ENTRY,
  TASK_FILTER,
  TEAMWORK_CREATE_TIME_ENTRIES,
  TEAMWORK_CREATE_TIME_ENTRY,
  // PAUSE_TIMER_FLOATING,
  UPDATE_NOT_LOGGED,
} from 'actions/time_entries';
import { LOGOUT } from 'actions/user';
// APIs
import {
  clickupCreateTimeEntries,
  clickupCreateTimeEntry,
  clickupGetTasks,
  deleteEntry,
  fetchAllEntries,
  fetchNextEntries,
  fetchNotLoggedEntries,
  storeEntry,
  teamWorkCreateTimeEntries,
  teamWorkCreateTimeEntry,
  updateEntry,
} from '../api/time_entries';
// Utils
import { LogoutObjectForPutEffect } from 'utils/constants';
import {
  getEntriesCustomFilterObject,
  getEntriesFilterObject,
  getSelectedWorkspace,
} from 'utils/functions';

function* fetchAllEntriesWorker(screen) {
  let timeScreen = '';
  if (screen !== undefined) {
    timeScreen = screen;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    timeScreen,
    undefined,
    undefined,
    undefined,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      filtering: false,
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchAllEntriesWatcher() {
  while (true) {
    const { screen } = yield take(FETCH_ALL_ENTRIES);
    yield fork(fetchAllEntriesWorker, screen);
    yield take([SUCCESS_FETCH_ALL_ENTRIES, LOGOUT, ERROR_FETCH_ALL_ENTRIES]);
  }
}

function* fetchNextEntriesWorker(offSet, screen) {
  let timeScreen = '';
  if (screen !== undefined) {
    timeScreen = screen;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchNextEntries,
    timeScreen,
    offSet,
    undefined,
    undefined,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchNextEntriesWatcher() {
  while (true) {
    const { offSet, screen } = yield take(FETCH_NEXT_ENTRIES);
    yield fork(fetchNextEntriesWorker, offSet, screen);
    yield take([SUCCESS_FETCH_NEXT_ENTRIES, LOGOUT, ERROR_FETCH_NEXT_ENTRIES]);
  }
}

function* filteringWorker(action) {
  const filters = yield call(getEntriesFilterObject);
  let timeScreen = '';
  if (action.screen !== undefined) {
    timeScreen = action.screen;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    timeScreen,
    undefined,
    undefined,
    filters,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      filtering: true,
      // moreRecords: data.more_records,
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* filteringWatcher() {
  yield takeLatest(START_FILTERING_ENTRIES, filteringWorker);
}

function* fetchNextFilteringWorker(action) {
  const filters = yield call(getEntriesFilterObject);
  let timeScreen = '';
  if (action.screen !== undefined) {
    timeScreen = action.screen;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    timeScreen,
    action.offSet,
    undefined,
    filters,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      filtering: true,
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}
export function* fetchNextFilteringWatcher() {
  yield takeLatest(START_NEXT_FILTERING_ENTRIES, fetchNextFilteringWorker);
}

function* addNewEntryWorker(entryObj) {
  const data = yield call(storeEntry, entryObj);

  if (data.status) {
    yield put({
      type: SUCCESS_ADD_NEW_ENTRY,
      // message: data.message || "Added new entry"
    });
    yield put({
      type: FETCH_ALL_ENTRIES,
    });
    yield put({
      type: FETCH_NOT_LOGGED_ENTRIES,
    });
  } else {
    yield put({
      type: ERROR_ADD_NEW_ENTRY,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* addNewEntryWatcher() {
  while (true) {
    const { entryObj } = yield take(ADD_NEW_ENTRY);
    yield fork(addNewEntryWorker, entryObj);
  }
}

function* customDateFilterWorker(dateObj, screen) {
  let timeScreen = '';
  if (screen !== undefined) {
    timeScreen = screen;
  }

  const getDateObj = yield call(getEntriesCustomFilterObject);
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    timeScreen,
    undefined,
    undefined,
    getDateObj,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      filtering: 'custom',
      // moreRecords: data.more_records,
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* customDateFilterWatcher() {
  while (true) {
    const { dateObj, screen } = yield take(CUSTOM_DATE_FILTER);
    yield fork(customDateFilterWorker, dateObj, screen);
  }
}

function* nextCustomDateFilterWorker(action) {
  const dateObj = yield call(getEntriesCustomFilterObject);
  let timeScreen = '';
  if (action.screen !== undefined) {
    timeScreen = action.screen;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    timeScreen,
    action.offset,
    undefined,
    dateObj,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
      more_web_records: data.more_web_records,
      filtering: 'custom',
      // totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}
export function* nextCustomDateFilteringWatcher() {
  yield takeLatest(NEXT_CUSTOM_DATE_FILTER, nextCustomDateFilterWorker);
}

function* taskFilterWorker(taskObj) {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchAllEntries,
    undefined,
    undefined,
    undefined,
    taskObj,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_ENTRIES,
      allEntries: data.data,
      filters: data.filter_data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* taskFilterWatcher() {
  while (true) {
    const { taskid } = yield take(TASK_FILTER);
    yield fork(taskFilterWorker, { task: taskid });
  }
}

function* editEntryWorker(entryObj, taskLog) {
  // const siteId = entryObj.site_id;
  delete entryObj.site_id;
  const data = yield call(updateEntry, entryObj);

  if (data.status) {
    // Make time entry reactive when edited (No need to refetch time entries when edited) The commented
    // lines from 346 can be removed now
    if (!taskLog) {
      yield put({
        type: SUCCESS_UPDATE_ENTRY,
        entryObjId: entryObj.id,
        data: data.data,
        selectedDate: entryObj.dateNew,
        message: data.message || 'Successfully updated the entry',
      });
    }

    // once the update is done, get new data from the backend
    // if (!taskLog) {
    //     yield put({
    //         type: START_FILTERING_ENTRIES,
    //         filterObj:
    //         {
    //             filterKey: "sites",
    //             filterValue: {
    //                 value: siteId,
    //                 checked: true
    //             }
    //         }
    //     });
    // }

    // yield put({
    //     type: FETCH_ALL_ENTRIES
    // });
    yield put({
      type: FETCH_NOT_LOGGED_ENTRIES,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_ENTRY,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* editEntryWatcher() {
  while (true) {
    const { entryObj, taskLog } = yield take(START_UPDATE_ENTRY);
    yield fork(editEntryWorker, entryObj, taskLog);
  }
}

function* deleteEntryWorker(id) {
  const data = yield call(deleteEntry, id);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_ENTRY,
      message: data.message || 'Successfully deleted the entry',
    });
    yield put({
      type: FETCH_ALL_ENTRIES,
    });
    yield put({
      type: FETCH_NOT_LOGGED_ENTRIES,
    });
  } else {
    yield put({
      type: ERROR_DELETE_ENTRY,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* deleteEntryWatcher() {
  while (true) {
    const { id } = yield take(START_DELETE_ENTRY);
    yield fork(deleteEntryWorker, id);
  }
}

function* notLoggedEntriesWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(fetchNotLoggedEntries, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NOT_LOGGED_ENTRIES,
      notLoggedEntries: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_NOT_LOGGED_ENTRIES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* notLoggedEntriesWatcher() {
  yield takeLatest(FETCH_NOT_LOGGED_ENTRIES, notLoggedEntriesWorker);
}

// export function* timerspentTimeWatcher() {
//     while(true) {
// const entryObj = yield take(PAUSE_TIMER_FLOATING);
// const taskLog = yield take(PAUSE_TIMER_FLOATING);
// yield fork(editEntryWorker, entryObj.entryObj, taskLog.taskLog);
//     }
// }

function* updateNotLoggedWorker(entryObj) {
  yield call(updateEntry, entryObj);
}

export function* updateNotLoggedWatcher() {
  while (true) {
    const { entryObj } = yield take(UPDATE_NOT_LOGGED);
    yield fork(updateNotLoggedWorker, entryObj);
  }
}

function* clickupCreateTimeEntryWorker(action) {
  const { obj } = action;
  const data = yield call(clickupCreateTimeEntry, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_CLICKUP_CREATE_TIME_ENTRY,
      message: data.message || 'Successfully deployed the entry',
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_CREATE_TIME_ENTRY,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* clickupCreateTimeEntryWatcher() {
  yield takeLatest(CLICKUP_CREATE_TIME_ENTRY, clickupCreateTimeEntryWorker);
}

function* clickupCreateTimeEntriesWorker(action) {
  const { obj } = action;

  const data = yield call(clickupCreateTimeEntries, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_CLICKUP_CREATE_TIME_ENTRIES,
      message: data.message || 'Successfully deployed the entries',
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_CREATE_TIME_ENTRIES,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* clickCreateTimeEntriesWatcher() {
  yield takeLatest(CLICKUP_CREATE_TIME_ENTRIES, clickupCreateTimeEntriesWorker);
}

function* clickupGetTasksWorker(action) {
  const { obj } = action;

  const data = yield call(clickupGetTasks, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_CLICKUP_GET_TASKS,
      tasks: data.data,
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_GET_TASKS,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* clickupGetTasksWatcher() {
  yield takeLatest(CLICKUP_GET_TASKS, clickupGetTasksWorker);
}

function* teamWorkCreateTimeEntryWorker(action) {
  const { obj } = action;
  const data = yield call(teamWorkCreateTimeEntry, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_TEAMWORK_CREATE_TIME_ENTRY,
      message: data.message || 'Successfully deployed the entry',
    });
  } else {
    yield put({
      type: ERROR_TEAMWORK_CREATE_TIME_ENTRY,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* teamWorkCreateTimeEntryWatcher() {
  yield takeLatest(TEAMWORK_CREATE_TIME_ENTRY, teamWorkCreateTimeEntryWorker);
}

function* teamWorkCreateTimeEntriesWorker(action) {
  const { obj } = action;
  const data = yield call(teamWorkCreateTimeEntries, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES,
      message: data.message || 'Successfully deployed the entry',
    });
  } else {
    yield put({
      type: ERROR_TEAMWORK_CREATE_TIME_ENTRIES,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* teamWorkCreateTimeEntriesWatcher() {
  yield takeLatest(
    TEAMWORK_CREATE_TIME_ENTRIES,
    teamWorkCreateTimeEntriesWorker
  );
}
