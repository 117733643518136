import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@src/store';

export interface CollaborateState {
  siteId: string;
  signupOptions: string;
}

const initialState: CollaborateState = {
  siteId: '',
  signupOptions: '',
};

const collaborateSlice = createSlice({
  initialState,
  name: 'collaborate_new',
  reducers: {
    setSiteId: (state, action: PayloadAction<string>) => {
      state.siteId = action.payload;
    },
    setSignupOptions: (state, action: PayloadAction<string>) => {
      state.signupOptions = action.payload;
    },
  },
});

export const { setSiteId, setSignupOptions } = collaborateSlice.actions;
export const getSiteId = (state: RootState) => state.collaborate_new.siteId;
export default collaborateSlice.reducer;
