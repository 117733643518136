export const FETCH_ALL_ENTRIES = 'FETCH_ALL_ENTRIES';
export const SUCCESS_FETCH_ALL_ENTRIES = 'SUCCESS_FETCH_ALL_ENTRIES';
export const ERROR_FETCH_ALL_ENTRIES = 'ERROR_FETCH_ALL_ENTRIES';

export const FETCH_NEXT_ENTRIES = 'FETCH_NEXT_ENTRIES';
export const SUCCESS_FETCH_NEXT_ENTRIES = 'SUCCESS_FETCH_NEXT_ENTRIES';
export const ERROR_FETCH_NEXT_ENTRIES = 'ERROR_FETCH_NEXT_ENTRIES';

export const START_FILTERING_ENTRIES = 'START_FILTERING_ENTRIES';

export const ADD_NEW_ENTRY = 'ADD_NEW_ENTRY';
export const SUCCESS_ADD_NEW_ENTRY = 'SUCCESS_ADD_NEW_ENTRY';
export const ERROR_ADD_NEW_ENTRY = 'ERROR_ADD_NEW_ENTRY';

export const CUSTOM_DATE_FILTER = 'CUSTOM_DATE_FILTER';

export const NEXT_CUSTOM_DATE_FILTER = 'NEXT_CUSTOM_DATE_FILTER';

export const START_UPDATE_ENTRY = 'START_UPDATE_ENTRY';
export const SUCCESS_UPDATE_ENTRY = 'SUCCESS_UPDATE_ENTRY';
export const ERROR_UPDATE_ENTRY = 'ERROR_UPDATE_ENTRY';

export const FETCH_NOT_LOGGED_ENTRIES = 'FETCH_NOT_LOGGED_ENTRIES';
export const SUCCESS_FETCH_NOT_LOGGED_ENTRIES =
  'SUCCESS_FETCH_NOT_LOGGED_ENTRIES';
export const ERROR_FETCH_NOT_LOGGED_ENTRIES = 'ERROR_FETCH_NOT_LOGGED_ENTRIES';

export const SELECT_TIMER_ENTRY = 'SELECT_TIMER_ENTRY';

export const START_DELETE_ENTRY = 'START_DELETE_ENTRY';
export const SUCCESS_DELETE_ENTRY = 'SUCCESS_DELETE_ENTRY';
export const ERROR_DELETE_ENTRY = 'ERROR_DELETE_ENTRY';

export const SET_CURRENT_TIMER_TASK_ID = 'SET_CURRENT_TIMER_TASK_ID';

// export const PAUSE_TIMER_FLOATING = "PAUSE_TIMER_FLOATING";

export const SET_TIMER_PLAY = 'SET_TIMER_PLAY';

export const UPDATE_NOT_LOGGED = 'UPDATE_NOT_LOGGED';

export const TASK_FILTER = 'TASK_FILTER';

export const CLICKUP_CREATE_TIME_ENTRY = 'CLICKUP_CREATE_TIME_ENTRY';
export const SUCCESS_CLICKUP_CREATE_TIME_ENTRY =
  'SUCCESS_CLICKUP_CREATE_TIME_ENTRY';
export const ERROR_CLICKUP_CREATE_TIME_ENTRY =
  'ERROR_CLICKUP_CREATE_TIME_ENTRY';

export const TEAMWORK_CREATE_TIME_ENTRY = 'TEAMWORK_CREATE_TIME_ENTRY';
export const SUCCESS_TEAMWORK_CREATE_TIME_ENTRY =
  'SUCCESS_TEAMWORK_CREATE_TIME_ENTRY';
export const ERROR_TEAMWORK_CREATE_TIME_ENTRY =
  'ERROR_TEAMWORK_CREATE_TIME_ENTRY';

export const TEAMWORK_CREATE_TIME_ENTRIES = 'TEAMWORK_CREATE_TIME_ENTRIES';
export const SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES =
  'SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES';
export const ERROR_TEAMWORK_CREATE_TIME_ENTRIES =
  'ERROR_TEAMWORK_CREATE_TIME_ENTRIES';

export const CLICKUP_CREATE_TIME_ENTRIES = 'CLICKUP_CREATE_TIME_ENTRIES';
export const SUCCESS_CLICKUP_CREATE_TIME_ENTRIES =
  'SUCCESS_CLICKUP_CREATE_TIME_ENTRIES';
export const ERROR_CLICKUP_CREATE_TIME_ENTRIES =
  'ERROR_CLICKUP_CREATE_TIME_ENTRIES';

export const CLICKUP_GET_TASKS = 'CLICKUP_GET_TASKS';
export const SUCCESS_CLICKUP_GET_TASKS = 'SUCCESS_CLICKUP_GET_TASKS';
export const ERROR_CLICKUP_GET_TASKS = 'ERROR_CLICKUP_GET_TASKS';

export const EMPTY_TIME_ENTRY_MESSAGE = 'EMPTY_TIME_ENTRY_MESSAGE';

export const START_NEXT_FILTERING_ENTRIES = 'START_NEXT_FILTERING_ENTRIES';
export const SUCCESS_NEXT_FILTERING_ENTRIES = 'SUCCESS_NEXT_FILTERING_ENTRIES';
export const ERROR_NEXT_FILTERING_ENTRIES = 'ERROR_NEXT_FILTERING_ENTRIES';
