import * as React from 'react';

export function useMediaQuery(query: string) {
  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }

    const result = matchMedia(query);
    result.addEventListener('change', onChange);
    setValue(result.matches);

    return () => result.removeEventListener('change', onChange);
  }, [query]);

  return value;
}
export function useResponsive() {
  const isLargeDesktop = useMediaQuery('(min-width: 1200px)');
  const isDesktop = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1199.98px)'
  );
  const isTablet = useMediaQuery(
    '(min-width: 768px) and (max-width: 1023.98px)'
  );
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  return { isLargeDesktop, isDesktop, isTablet, isMobile };
}
