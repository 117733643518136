import {
  START_FETCH_DASHBOARD_DATA,
  SUCCESS_FETCH_DASHBOARD_DATA,
  ERROR_FETCH_DASHBOARD_DATA,
  FETCH_CONTRIBUTORS_OF_SITE,
  FETCH_CONTRIBUTORS_OF_SITE_SUCCESS,
  FETCH_CONTRIBUTORS_OF_SITE_ERROR,
  UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS,
  UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR,
  EMPTY_MESSAGE_DASHBOARD,
  REDIRECT_TO_TASKS,
  FETCH_NEXT_WEB_LIST,
  SUCCESS_FETCH_NEXT_WEB_LIST,
  ERROR_FETCH_NEXT_WEB_LIST,
  SUCCESS_SOFT_DELETE_SITE,
  ERROR_SOFT_DELETE_SITE,
  START_FETCH_ARCHIVED_SITES,
  SUCCESS_FETCH_ARCHIVED_SITES,
  ERROR_FETCH_ARCHIVED_SITES,
  SUCCESS_ACTIVATE_ARCHIVED_SITE,
  ERROR_ACTIVATE_ARCHIVED_SITE,
  START_SYNC_SITE_IMAGE,
  SUCCESS_SYNC_SITE_IMAGE,
  ERROR_SYNC_SITE_IMAGE,
  ADD_GRAPHIC_SITE,
  SUCCESS_ADD_GRAPHIC_SITE,
  ERROR_ADD_GRAPHIC_SITE,
  FETCH_SITE_SETTINGS,
  SUCCESS_FETCH_SITE_SETTINGS,
  ERROR_FETCH_SITE_SETTINGS,
  UPDATE_SITE_SETTINGS,
  SAVE_SITE_SETTINGS,
  SUCCESS_SAVE_SITE_SETTINGS,
  ERROR_SAVE_SITE_SETTINGS,
  FETCH_SITES_BASED_ON_PROJECTS,
  SUCCESS_FETCH_SITES_BASED_ON_PROJECTS,
  ERROR_FETCH_SITES_BASED_ON_PROJECTS,
  FETCH_NEXT_PROJECT_SITES,
  SUCCESS_FETCH_NEXT_PROJECT_SITES,
  ERROR_FETCH_NEXT_PROJECT_SITES,
  SET_NEXT_PROJECT_SITE_ID,
  COLLAPSE_PROJECT_SITES,
  FETCH_MILESTONES,
  SUCCESS_FETCH_MILESTONES,
  ERROR_FETCH_MILESTONES,
  ADD_MILESTONE,
  SUCCESS_ADD_MILESTONE,
  ERROR_ADD_MILESTONE,
  UPDATE_MILESTONE_DEATILS_IN_REDUCER,
  DELETE_MILESTONE,
  SUCCESS_DELETE_MILESTONE,
  ERROR_DELETE_MILESTONE,
  SUCCESS_UPDATE_MILESTONE_DETAILS,
  RESET_ADDED_MILESTONE,
  SUCCESS_MERGE_WEBSITES,
  ERROR_MERGE_WEBSITES,
  MERGE_WEBSITES,
  SUCCESS_CREATE_ROCKET_SITE,
  ERROR_CREATE_ROCKET_SITE,
  CREATE_ROCKET_SITE,
  SUCCESS_FETCH_ROCKET_LOCATION,
  SUCCESS_FETCH_SOURCE_WEBSITE,
  SUCCESS_DESTINATION_WEBSITES,
  UPDATE_SINGLE_ITEM_FOLDER_NAME,
  FETCH_SOURCE_WEBSITE,
  SUCCESS_NEXT_ARCHIVED_SITES,
  ERROR_NEXT_ARCHIVED_SITES,
  START_NEXT_ARCHIVED_SITES,
  CREATE_INSTAWP_SITE,
  SUCCESS_CREATE_INSTAWP_SITE,
  ERROR_CREATE_INSTAWP_SITE,
  SEARCH_ARCHIVED,
  ERROR_SEARCH_ARCHIVED,
  SUCCESS_SEARCH_ARCHIVED,
  EMPTY_ARCHIVED_SITES,
  SUCCESS_FETCH_USER_SITES_DATA,
  SUCCESS_FETCH_NEXT_USER_SITES_DATA,
  SUCCESS_FETCH_NEXT_SOURCE_WEBSITE,
  SUCCESS_NEXT_DESTINATION_WEBSITES,
  FETCH_NEXT_SOURCE_WEBSITE,
  FETCH_NEXT_DESTINATION_WEBSITES,
  SUCCESS_REMOVE_COLLABORATE_SITE,
  FETCH_ALL_PROJECTS_FOR_EXPORT,
  SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT,
  ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT,
  RESET_HAS_GOTTEN_PROJECT_EXPORT_DATA,
  UPDATE_DASHBOARD_IMAGE,
  CLOSE_MERGE_WEBSITES,
  SUCCESS_DELETE_SITE,
  ERROR_DELETE_SITE,
} from 'actions/dashboard';
import {
  SUCCESS_DELETE_TASK,
  SUCCESS_SAVE_NEW_GENERAL_TASK,
  SUCCESS_UPDATE_TASK_ATTRIBUTES,
  NEW_WEBSITE_SELECTED,
  LEAVING_TASKS_PAGE,
  FETCH_ALL_TASKS,
  START_SEARCH,
} from 'actions/tasks';
import { NEW_EMAIL_SELECTED } from 'actions/email';
import { SUCCESS_DELETE_PROJECT } from 'actions/projects';
import { SUCCESS_DELETE_TASK as SUCCESS_DELETE_TASK_BOARD } from 'actions/board';
import { SUCCESS_ADD_NEW_SITE } from 'actions/collaborate';
import { LOGOUT } from 'actions/user';

const initState = {
  isLoading: false,
  isError: false,
  isFetchingContributors: false,
  dashboard: [],
  contributors: [],
  errorMsg: '',
  updatingContributorStatusErrorMessage: '',
  updatingContributorStatusSuccessMessage: '',
  selectedWebsite: '',
  selectedFeedTask: '',
  selectedFeedWebsite: '',
  successDeleteSiteMessage: '',
  errorDeleteSiteMessage: '',
  errorFetchingNextWebList: '',
  isFetchingNextWebList: false,
  websiteList: [],
  moreWebRecords: false,
  archivedSitesData: [],
  loadingArchived: false,
  successActivateSiteMessage: '',
  errorActivateSiteMessage: '',
  syncData: [],
  syncInProgress: false,
  successSyncSiteMessage: '',
  errorSyncSiteMessage: '',
  imageIsLoading: false,
  uploadingGraphicItem: false,
  errorGraphicItem: '',
  siteSettings: [],
  successSiteSettings: '',
  errorSiteSettings: '',
  detailsStatus: null,
  projectBasedSites: [],
  loadingSiteSettings: false,
  milestones: [],
  milestonesAreLoading: false,
  addMilestoneLoading: false,
  successAddMilestone: '',
  errorAddMilestone: '',
  successDeleteMilestone: '',
  errorDeleteMilestone: '',
  milestoneAdded: false,
  successMergeWebsite: '',
  errorMergeWebsite: '',
  isMergingWebsite: false,
  createRocketSiteLoading: false,
  successRoctetSite: '',
  errorRocketSite: '',
  rocketSiteCreated: {},
  rocketLocation: [],
  mergeSourceSite: [],
  mergeDestinationSite: [],
  loadingSitesBasedOnProjects: false,
  loadingNextProjectSites: false,
  nextProjectSitesId: null,
  isFetchingSource: false,
  moreArchivedRecords: true,
  createInstawpSiteLoading: false,
  successInstawpSite: '',
  errorInstawpSite: '',
  searchingArchived: false,
  sitesOfUser: [],
  selectedSiteForStagesModal: '',
  selectedSiteForSettingsModal: '',
  projectExportData: [],
  projectExportDataError: '',
  hasGottenProjectExportData: false,
  siteMerged: false,
};

const dashboard = (previousState = initState, action) => {
  switch (action.type) {
    case START_FETCH_DASHBOARD_DATA:
      return {
        ...previousState,
        isLoading: true,
      };
    case FETCH_ALL_TASKS:
      return {
        ...previousState,
        selectedWebsite: '',
        selectedFeedWebsite: '',
        selectedFeedTask: '',
      };
    case SUCCESS_FETCH_DASHBOARD_DATA:
      return {
        ...previousState,
        isLoading: false,
        isError: false,
        dashboard: action.data,
        moreWebRecords: action.moreWebRecords,
      };
    case ERROR_FETCH_DASHBOARD_DATA:
      return {
        ...previousState,
        isLoading: false,
        isError: true,
        dashboard: [],
        errorMsg: action.error,
      };
    case SUCCESS_DELETE_TASK:
    case SUCCESS_DELETE_TASK_BOARD:
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((d) => {
          if (d.id === action.websiteId) {
            d.tasks_count = action.remainingTasks;
          }
          return d;
        }),
      };
    case SUCCESS_SAVE_NEW_GENERAL_TASK:
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.websiteId
            ? { ...d, tasks_count: d.tasks_count + 1 }
            : d
        ),
      };
    case FETCH_CONTRIBUTORS_OF_SITE:
      return {
        ...previousState,
        isFetchingContributors: true,
        contributors: [],
      };
    case FETCH_CONTRIBUTORS_OF_SITE_SUCCESS:
      return {
        ...previousState,
        isFetchingContributors: false,
        contributors: action.contributors,
      };
    case FETCH_CONTRIBUTORS_OF_SITE_ERROR:
      return {
        ...previousState,
        isFetchingContributors: false,
        contributors: [],
      };
    case UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS:
      return {
        ...previousState,
        contributors: previousState.contributors.map((c) =>
          c.id === action.userId ? { ...c, selected: action.selected } : c
        ),
        updatingContributorStatusErrorMessage: '',
        updatingContributorStatusSuccessMessage: action.message,
        dashboard: previousState.dashboard.map((d) => {
          if (d.id === action.websiteId) {
            return {
              ...d,
              users: action.selected
                ? d.users.concat(
                    previousState.contributors.filter(
                      (u) => u.id === action.userId
                    )
                  )
                : d.users.filter((u) => u.id !== action.userId),
              total_users_count: action.selected
                ? d.total_users_count + 1
                : d.total_users_count - 1,
            };
          }
          return d;
        }),
      };
    case UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR:
      return {
        ...previousState,
        updatingContributorStatusErrorMessage: action.message,
        updatingContributorStatusSuccessMessage: '',
      };
    case SUCCESS_UPDATE_TASK_ATTRIBUTES:
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((d) =>
          d.id === previousState.selectedWebsite
            ? { ...d, tasks_count: action.params.tasksCount }
            : d
        ),
      };
    case REDIRECT_TO_TASKS:
      return {
        ...previousState,
        selectedWebsite: action.websiteId,
        selectedFeedWebsite: action.websiteId,
        selectedFeedTask: action.task_id,
      };
    case NEW_WEBSITE_SELECTED:
      return {
        ...previousState,
        selectedWebsite: action.websiteId,
        selectedFeedWebsite: '',
        selectedFeedTask: '',
      };
    case LEAVING_TASKS_PAGE:
      return {
        ...previousState,
        selectedWebsite: '',
        selectedFeedWebsite: '',
      };
    case EMPTY_MESSAGE_DASHBOARD:
      return {
        ...previousState,
        [action.key]: '',
      };
    case START_SEARCH:
      return {
        ...previousState,
        selectedWebsite: '',
        selectedFeedWebsite: '',
        selectedFeedTask: '',
      };
    case FETCH_NEXT_WEB_LIST:
      return {
        ...previousState,
        isFetchingNextWebList: true,
        errorFetchingNextWebList: '',
      };
    case SUCCESS_FETCH_NEXT_WEB_LIST:
      const data = previousState.dashboard.concat(action.data);
      const prop = 'id';
      const newWebsiteList = data.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
      });

      return {
        ...previousState,
        isFetchingNextWebList: false,
        errorFetchingNextWebList: '',
        dashboard: newWebsiteList,
        moreWebRecords: action.moreWebRecords, // this is done to protect from calling next page API infinitely
        // which happens when another logged in user creates a new task
      };
    case ERROR_FETCH_NEXT_WEB_LIST:
      return {
        ...previousState,
        isFetchingNextWebList: false,
        errorFetchingNextWebList: action.message,
      };
    case SUCCESS_SOFT_DELETE_SITE:
      return {
        ...previousState,
        successDeleteSiteMessage: action.message,
        dashboard: previousState.dashboard.filter(
          (d) => !action.websiteId.includes(d.id)
        ),
      };
    case ERROR_SOFT_DELETE_SITE:
      return {
        ...previousState,
        errorDeleteSiteMessage: action.message,
      };

    case START_NEXT_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: true,
      };
    case SUCCESS_NEXT_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: false,
        moreArchivedRecords: action.data.length % 20 === 0 ? true : false,
        archivedSitesData: action.data,
      };
    case ERROR_NEXT_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: false,
      };
    case SEARCH_ARCHIVED:
      return {
        ...previousState,
        loadingArchived: true,
        searchingArchived: true,
      };
    case SUCCESS_SEARCH_ARCHIVED:
      return {
        ...previousState,
        loadingArchived: false,
        moreArchivedRecords: action.data.length % 20 === 0 ? true : false,
        archivedSitesData: action.data,
        searchingArchived: false,
      };
    case ERROR_SEARCH_ARCHIVED:
      return {
        ...previousState,
        loadingArchived: false,
        searchingArchived: false,
      };
    case START_FETCH_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: true,
        archivedSitesData: [],
      };
    case SUCCESS_FETCH_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: false,
        archivedSitesData: action.data,
      };
    case ERROR_FETCH_ARCHIVED_SITES:
      return {
        ...previousState,
        loadingArchived: false,
        archivedSitesData: [],
      };
    case SUCCESS_ACTIVATE_ARCHIVED_SITE:
      return {
        ...previousState,
        successActivateSiteMessage: action.message,
        archivedSitesData: previousState.archivedSitesData.filter(
          (s) => s.id !== action.siteId
        ),
      };
    case ERROR_ACTIVATE_ARCHIVED_SITE:
      return {
        ...previousState,
        errorActivateSiteMessage: action.message,
      };
    case START_SYNC_SITE_IMAGE:
      return {
        ...previousState,
        imageIsLoading: true,
        imageSyncSuccessMessage: '',
        imageSyncErrorMessage: '',
      };
    case SUCCESS_SYNC_SITE_IMAGE:
      return {
        ...previousState,
        imageIsLoading: false,
        imageSyncSuccessMessage: action.message,
        imageSyncErrorMessage: '',
      };
    case ERROR_SYNC_SITE_IMAGE:
      return {
        ...previousState,
        imageIsLoading: false,
        imageSyncSuccessMessage: '',
        imageSyncErrorMessage: action.message,
      };
    case NEW_EMAIL_SELECTED:
      return {
        ...previousState,
        selectedWebsite: action.id,
        selectedFeedWebsite: '',
        selectedFeedTask: '',
      };
    case ADD_GRAPHIC_SITE:
      return {
        ...previousState,
        uploadingGraphicItem: true,
      };
    case SUCCESS_ADD_GRAPHIC_SITE:
      return {
        ...previousState,
        uploadingGraphicItem: false,
        errorGraphicItem: '',
      };
    case ERROR_ADD_GRAPHIC_SITE:
      return {
        ...previousState,
        uploadingGraphicItem: false,
        errorGraphicItem: action.message,
      };
    case FETCH_SITE_SETTINGS:
      return {
        ...previousState,
        loadingSiteSettings: true,
      };
    case SUCCESS_FETCH_SITE_SETTINGS:
      return {
        ...previousState,
        siteSettings: action.data,
        loadingSiteSettings: false,
        selectedSiteForSettingsModal: action.websiteId,
      };
    case ERROR_FETCH_SITE_SETTINGS:
      return {
        ...previousState,
        loadingSiteSettings: false,
      };
    case UPDATE_SITE_SETTINGS:
      const { key, value, settings } = action;

      if (settings) {
        if (settings === 'notifications') {
          const obj = previousState.siteSettings.notifications.map((s) =>
            s.key === key ? { ...s, value: value } : s
          );
          return {
            ...previousState,
            siteSettings: {
              ...previousState.siteSettings,
              notifications: obj,
            },
          };
        } else {
          return {
            ...previousState,
            siteSettings: {
              ...previousState.siteSettings,
              [settings]: {
                ...previousState.siteSettings[settings],
                [key]: value,
              },
            },
          };
        }
      } else {
        return {
          ...previousState,
          siteSettings: {
            ...previousState.siteSettings,
            [key]: value,
          },
        };
      }
    case SAVE_SITE_SETTINGS:
      return {
        ...previousState,
        successSiteSettings: '',
        errorSiteSettings: '',
      };
    case SUCCESS_SAVE_SITE_SETTINGS:
      let currentProjectId = action.firstProjectId;
      let siteObj = {};
      let updatedProjectId = action.updatedProjectId;
      let newProjectData = [];
      if (action?.folderName) {
        let tempData = previousState.projectBasedSites.map((project) =>
          project.project_id === currentProjectId
            ? {
                ...project,
                websites: project.websites.filter(
                  (each) => each.id !== action.siteId
                ),
              }
            : project
        );
        previousState.projectBasedSites.map((project) =>
          project.project_id === currentProjectId
            ? (siteObj = project.websites.find(
                (each) => each.id === action.siteId
              ))
            : siteObj
        );
        newProjectData = tempData.map((project) =>
          project.normal_id === updatedProjectId
            ? { ...project, websites: project.websites.concat(siteObj) }
            : project
        );
      } else {
        newProjectData = previousState.projectBasedSites;
      }

      return {
        ...previousState,
        projectBasedSites: newProjectData,
        successSiteSettings: action.message,
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.siteId ? { ...d, name: action.siteName } : d
        ),
        errorSiteSettings: '',
      };
    case ERROR_SAVE_SITE_SETTINGS:
      return {
        ...previousState,
        successSiteSettings: '',
        errorSiteSettings: action.message,
      };
    case FETCH_SITES_BASED_ON_PROJECTS:
      return {
        ...previousState,
        loadingSitesBasedOnProjects: true,
      };
    case SUCCESS_FETCH_SITES_BASED_ON_PROJECTS:
      return {
        ...previousState,
        projectBasedSites: action.data,
        loadingSitesBasedOnProjects: false,
      };
    case ERROR_FETCH_SITES_BASED_ON_PROJECTS:
      return {
        ...previousState,
        projectBasedSites: [],
        loadingSitesBasedOnProjects: false,
      };
    case FETCH_NEXT_PROJECT_SITES:
      return {
        ...previousState,
        loadingNextProjectSites: true,
      };
    case SUCCESS_FETCH_NEXT_PROJECT_SITES:
      const websitesObj = action.data[0];

      return {
        ...previousState,
        projectBasedSites: previousState.projectBasedSites.map((project) =>
          project.project_id === websitesObj.project_id
            ? {
                ...project,
                websites: project.websites.concat(websitesObj.websites),
                more_web_records: websitesObj.more_web_records,
              }
            : project
        ),
        loadingNextProjectSites: false,
        nextProjectSitesId: null,
      };
    case ERROR_FETCH_NEXT_PROJECT_SITES:
      return {
        ...previousState,
        loadingNextProjectSites: false,
        nextProjectSitesId: null,
      };
    case SET_NEXT_PROJECT_SITE_ID:
      return {
        ...previousState,
        nextProjectSitesId: action.projectId,
      };
    case COLLAPSE_PROJECT_SITES:
      const obj = previousState.projectBasedSites.map((p) =>
        p.project_name === action.projectName
          ? {
              ...p,
              collapsed: !p.collapsed,
            }
          : p
      );

      return {
        ...previousState,
        projectBasedSites: obj,
      };
    case FETCH_MILESTONES:
      return {
        ...previousState,
        milestonesAreLoading: true,
        milestones: [],
      };
    case SUCCESS_FETCH_MILESTONES:
      return {
        ...previousState,
        milestonesAreLoading: false,
        milestones: action.data,
        selectedSiteForStagesModal: action.siteId,
      };
    case ERROR_FETCH_MILESTONES:
      return {
        ...previousState,
        milestonesAreLoading: false,
        milestones: [],
      };
    case ADD_MILESTONE:
      return {
        ...previousState,
        addMilestoneLoading: true,
        milestoneAdded: false,
      };
    case SUCCESS_ADD_MILESTONE:
      return {
        ...previousState,
        addMilestoneLoading: false,
        successAddMilestone: action.message,
        errorAddMilestone: '',
        milestoneAdded: true,
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.data.site_id ? { ...d, milestone: action.data } : d
        ),
      };
    case RESET_ADDED_MILESTONE:
      return {
        ...previousState,
        milestoneAdded: false,
      };
    case ERROR_ADD_MILESTONE:
      return {
        ...previousState,
        addMilestoneLoading: false,
        errorAddMilestone: action.message,
        successAddMilestone: '',
      };
    case UPDATE_MILESTONE_DEATILS_IN_REDUCER:
      const alteredMilesontes = previousState.milestones.map((m) =>
        m.milestone_id === action.params.id
          ? { ...m, [action.params.key]: action.params.value }
          : m
      );

      return {
        ...previousState,
        milestones: alteredMilesontes,
      };
    case DELETE_MILESTONE:
      return {
        ...previousState,
        successDeleteMilestone: '',
        errorDeleteMilestone: '',
      };
    case SUCCESS_DELETE_MILESTONE:
      return {
        ...previousState,
        milestones: previousState.milestones.filter(
          (m) => m.milestone_id !== action.id
        ),
        successDeleteMilestone: action.message,
        errorDeleteMilestone: '',
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.site_id
            ? d.milestone.milestone_id === action.id
              ? { ...d, milestone: null }
              : d
            : d
        ),
      };
    case ERROR_DELETE_MILESTONE:
      return {
        ...previousState,
        successDeleteMilestone: '',
        errorDeleteMilestone: action.error,
      };
    case SUCCESS_UPDATE_MILESTONE_DETAILS:
      const updatedMilestones = previousState.milestones.map((m) => {
        if (m.milestone_id === action.content.milestone_id) {
          m.days_left = action.content.days_left;
          const date = new Date(action.due_date_at);
          m.date = date.toLocaleString('default', { day: '2-digit' });
          m.month = date.toLocaleString('default', { month: 'short' });
          m.day = date.toLocaleString('default', { weekday: 'long' });
          return m;
        } else {
          return m;
        }
      });
      return {
        ...previousState,
        milestones: updatedMilestones,
      };
    case MERGE_WEBSITES:
      return {
        ...previousState,
        successMergeWebsite: '',
        errorMergeWebsite: '',
        isMergingWebsite: true,
        siteMerged: false,
      };
    case SUCCESS_MERGE_WEBSITES:
      return {
        ...previousState,
        successMergeWebsite: action.message,
        errorMergeWebsite: '',
        isMergingWebsite: false,
        siteMerged: true,
      };
    case ERROR_MERGE_WEBSITES:
      return {
        ...previousState,
        successMergeWebsite: '',
        errorMergeWebsite: action.error,
        isMergingWebsite: false,
        siteMerged: false,
      };
    case CLOSE_MERGE_WEBSITES:
      return {
        ...previousState,
        successMergeWebsite: '',
        errorMergeWebsite: '',
        isMergingWebsite: false,
        siteMerged: false,
      };
    case CREATE_ROCKET_SITE:
      return {
        ...previousState,
        createRocketSiteLoading: true,
        successRoctetSite: '',
        errorRocketSite: '',
        rocketSiteCreated: {},
      };
    case SUCCESS_CREATE_ROCKET_SITE:
      return {
        ...previousState,
        createRocketSiteLoading: false,
        successRoctetSite: action.message,
        errorRocketSite: '',
        rocketSiteCreated: action.status,
      };
    case ERROR_CREATE_ROCKET_SITE:
      return {
        ...previousState,
        createRocketSiteLoading: false,
        successRoctetSite: '',
        errorRocketSite: action.error,
        rocketSiteCreated: {},
      };
    case SUCCESS_FETCH_ROCKET_LOCATION:
      return {
        ...previousState,
        rocketLocation: action.data,
      };

    case FETCH_SOURCE_WEBSITE:
      return {
        ...previousState,
        isFetchingSource: true,
      };
    case SUCCESS_FETCH_SOURCE_WEBSITE:
      return {
        ...previousState,
        mergeSourceSite: action.data,
        isFetchingSource: false,
        moreSourceSites: action.moreSource,
      };
    case FETCH_NEXT_SOURCE_WEBSITE:
      return {
        ...previousState,
        mergeSourceSite: previousState?.mergeSourceSite.concat({
          id: 'loadMore',
          name: 'more',
        }),
        isFetchingSource: false,
        moreSourceSites: action.moreSource,
      };
    case SUCCESS_FETCH_NEXT_SOURCE_WEBSITE:
      let newSourceSite = previousState?.mergeSourceSite.filter(
        (each) => each.id !== 'loadMore'
      );
      return {
        ...previousState,
        mergeSourceSite: newSourceSite.concat(action.data),
        isFetchingSource: false,
        moreSourceSites: action.moreSource,
      };
    case SUCCESS_DESTINATION_WEBSITES:
      return {
        ...previousState,
        mergeDestinationSite: action.data,
        moreDestinationSite: action.moreDestination,
      };
    case FETCH_NEXT_DESTINATION_WEBSITES:
      return {
        ...previousState,
        mergeDestinationSite: previousState.mergeDestinationSite.concat({
          id: 'loadMore',
          name: 'more',
        }),
        moreDestinationSite: action.moreDestination,
      };
    case SUCCESS_NEXT_DESTINATION_WEBSITES:
      let newDestinationSite = previousState?.mergeDestinationSite.filter(
        (each) => each.id !== 'loadMore'
      );
      return {
        ...previousState,
        mergeDestinationSite: newDestinationSite.concat(action.data),
        moreDestinationSite: action.moreDestination,
      };
    case UPDATE_SINGLE_ITEM_FOLDER_NAME:
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.siteId ? { ...d, project_name: action.folderName } : d
        ),
      };
    case SUCCESS_DELETE_PROJECT:
      const filtredProjectSites = previousState.projectBasedSites.filter(
        (p) => p.normal_id !== action.projectId
      );
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((dash) =>
          dash.project_id === action.projectId
            ? {
                ...dash,
                project_id: null,
                project_name: null,
                project_websites_ids: [],
                project_users: [],
              }
            : dash
        ),
        projectBasedSites: filtredProjectSites,
      };
    case CREATE_INSTAWP_SITE:
      return {
        ...previousState,
        createInstawpSiteLoading: true,
        successInstawpSite: '',
        errorRocketSite: '',
      };
    case SUCCESS_CREATE_INSTAWP_SITE:
      return {
        ...previousState,
        createInstawpSiteLoading: false,
        successInstawpSite: action.message,
        errorInstawpSite: '',
      };
    case ERROR_CREATE_INSTAWP_SITE:
      return {
        ...previousState,
        createInstawpSiteLoading: false,
        successInstawpSite: '',
        errorInstawpSite: action.error,
      };
    case EMPTY_ARCHIVED_SITES:
      return {
        ...previousState,
        archivedSitesData: [],
      };
    case SUCCESS_FETCH_USER_SITES_DATA:
      return {
        ...previousState,
        sitesOfUser: action.data,
        isLoading: false,
        isError: false,
        moreWebRecords: action.moreWebRecords,
      };
    case SUCCESS_FETCH_NEXT_USER_SITES_DATA:
      const userSiteList = previousState.sitesOfUser.concat(action.data);

      return {
        ...previousState,
        sitesOfUser: userSiteList,
        moreWebRecords: action.moreWebRecords,
        isFetchingNextWebList: false,
      };
    case SUCCESS_ADD_NEW_SITE:
      let newDashboard = [];
      if (
        previousState.dashboard.length > 0 &&
        previousState.dashboard[0]?.type === 'inbox'
      ) {
        newDashboard = [
          ...previousState.dashboard.slice(0, 1),
          action.data,
          ...previousState.dashboard.slice(1),
        ];
      } else {
        newDashboard = previousState.dashboard.concat(action.data);
      }

      return {
        ...previousState,
        dashboard: newDashboard,
      };
    case SUCCESS_REMOVE_COLLABORATE_SITE:
      return {
        ...previousState,
        successDeleteSiteMessage: 'Project removed successfully',
        dashboard: previousState.dashboard.filter(
          (s) => s.id !== action.siteId
        ),
      };
    case FETCH_ALL_PROJECTS_FOR_EXPORT:
      return {
        ...previousState,
        hasGottenProjectExportData: false,
      };
    case SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT:
      return {
        ...previousState,
        projectExportData: action.data,
        hasGottenProjectExportData: true,
      };
    case ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT:
      return {
        ...previousState,
        projectExportDataError: action.error,
        hasGottenProjectExportData: true,
      };
    case RESET_HAS_GOTTEN_PROJECT_EXPORT_DATA:
      return {
        ...previousState,
        hasGottenProjectExportData: false,
        projectExportData: [],
      };
    case UPDATE_DASHBOARD_IMAGE:
      return {
        ...previousState,
        dashboard: previousState.dashboard.map((d) =>
          d.id === action.id
            ? { ...d, image: action.image, favicon: action.image }
            : d
        ),
      };
    case SUCCESS_DELETE_SITE:
      return {
        ...previousState,
        successDeleteSiteMessage: action.message,
        dashboard: previousState.dashboard.filter(
          (d) => !action.websiteId.includes(d.id)
        ),
      };
    case ERROR_DELETE_SITE:
      return {
        ...previousState,
        errorDeleteSiteMessage: action.message,
      };
    case LOGOUT:
      return initState;
    default:
      return previousState;
  }
};

export default dashboard;
