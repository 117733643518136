import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InboxState {
  pendingAttachments?: FileList;
}

const initialState: InboxState = {
  pendingAttachments: undefined,
};

const inboxSlice = createSlice({
  name: 'inbox_new',
  initialState,
  reducers: {
    setPendingAttachments: (state, action: PayloadAction<FileList>) => {
      state.pendingAttachments = action.payload;
    },
    clearPendingAttachments: (state) => {
      state.pendingAttachments = undefined;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setPendingAttachments,
  clearPendingAttachments,
} = inboxSlice.actions;

export default inboxSlice.reducer;
