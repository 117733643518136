import { forwardRef, useMemo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { cn } from 'utils/cn';
import { Input, Popover } from '..';
import Button, { ButtonProps } from '../Button';
import { useForwardedRef } from '../use-forwarded-ref';

interface ColorPickerProps {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    container?: HTMLElement | ShadowRoot | null;
    portal?: boolean;
}

const ColorPicker = forwardRef<
    HTMLInputElement,
    Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps
>(
    (
        { disabled, value, onChange, onBlur, name, className, children, container = document.body, portal = true, ...props },
        forwardedRef
    ) => {
        const ref = useForwardedRef(forwardedRef);
        const [open, setOpen] = useState(false);

        const parsedValue = useMemo(() => {
            return value || '#FFFFFF';
        }, [value]);

        return (
            <Popover modal={false} onOpenChange={setOpen} open={open}>
                <Popover.Trigger asChild disabled={disabled} onBlur={onBlur}>
                    <Button
                        {...props}
                        className={cn(className)}
                        name={name}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        {children}
                    </Button>
                </Popover.Trigger>
                <Popover.Content className='w-full flex flex-col gap-2 rounded-xl p-2 shadow-lg' portal={portal} container={container}>

                    <HexColorPicker color={parsedValue} onChange={onChange} />
                    <Input
                        maxLength={7}
                        onChange={(e) => {
                            onChange(e?.currentTarget?.value);
                        }}
                        ref={ref}
                        value={parsedValue}
                    />
                </Popover.Content>
            </Popover>
        );
    }
);
ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };

