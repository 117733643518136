import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'utils/cn';
import Tooltip from '../Tooltip';

const buttonVariants = cva(
  'inline-flex items-center duration-300 justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 relative',
  {
    variants: {
      variant: {
        primary:
          'bg-primary text-primary-foreground hover:brightness-90 border border-primary focus:brightness-90',
        muted:
          'bg-primary/10 text-primary-foreground hover:bg-primary border border-primary',
        secondary:
          'bg-white border border-colour-secondary-border text-secondary-foreground hover:bg-tertiary/[0.06]',
        destructive:
          'bg-destructive text-destructive-foreground text-white hover:bg-destructive/90',
        'muted-destructive':
          'bg-destructive/10 text-destructive-foreground hover:bg-destructive border border-destructive',
        'destructive-outline':
          'text-destructive hover:bg-destructive hover:text-white border border-destructive',
        outline:
          'border text-primary-foreground border-input bg-background hover:bg-accent hover:text-accent-foreground',
        ghost:
          'hover:bg-accent hover:text-accent-foreground hover:bg-tertiary/[0.06]',
        link: 'px-0 relative after:absolute font-base text-tertiary after:bg-tertiary after:bottom-0 after:h-[1px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300',
      },
      size: {
        sm: 'h-9 rounded-lg px-3 text-sm gap-x-1 leading-tight',
        md: 'h-10 rounded-lg px-4 py-2 text-base gap-x-2',
        lg: 'h-11 rounded-lg px-8 py-3 text-lg gap-x-3',
        xl: 'h-12 rounded-lg px-12 py-4 text-xl gap-x-4',
        icon: 'h-9 w-9 p-2 rounded-lg overflow-hidden text-secondary max-w-9 max-h-9 min-w-9 min-h-9',
        link: 'px-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  loadingWithText?: boolean;
  tooltip?: string | React.ReactNode;
  tooltipSideOffset?: number;
  tooltipAlignOffset?: number;
  tooltipClassName?: string;
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
  tooltipAlign?: 'start' | 'center' | 'end';
  tooltipArrow?: boolean;
  toolTipPortal?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    variant,
    size,
    leftIcon,
    rightIcon,
    loading,
    loadingWithText = false,
    asChild = false,
    tooltip,
    tooltipClassName,
    tooltipSide,
    tooltipAlign,
    tooltipSideOffset,
    tooltipAlignOffset,
    tooltipArrow,
    toolTipPortal = false,
    ...props
  },
  ref
) {
  const Comp = asChild ? Slot : 'button';

  const loader = (
    <span className="loader animate-rotate-360 border-primary-foreground repeat-infinite h-4 w-4 rounded-full border-2 border-t-transparent duration-700" />
  );

  const buttonContent = (
    <>
      {loading && !loadingWithText && (
        <span className="absolute flex items-center justify-center">
          {loader}
        </span>
      )}
      {!loading && leftIcon}
      {props.children}
      {loading && loadingWithText && (
        <span className="ml-2 inline-flex items-center">{loader}</span>
      )}
      {!loading && rightIcon}
    </>
  );

  const buttonElement = (
    <Comp
      className={cn(
        loading && !loadingWithText ? 'text-opacity-0' : '',
        'disabled:cursor-not-allowed',
        buttonVariants({ variant, size, className }),
      )}
      ref={ref}
      {...props}
      disabled={loading || props.disabled}
    >
      {buttonContent}
    </Comp>
  );

  if (tooltip) {
    return (
      <Tooltip
        content={tooltip}
        className={tooltipClassName}
        side={tooltipSide}
        arrow={tooltipArrow}
        portal={toolTipPortal}
        sideOffset={tooltipSideOffset}
        align={tooltipAlign}
        alignOffset={tooltipAlignOffset}
      >
        {buttonElement}
      </Tooltip>
    );
  }

  return buttonElement;
});

Button.displayName = 'Button';

export { buttonVariants };
export default Button;
