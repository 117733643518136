import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';
import { cn } from 'utils/cn';

const badgeVariants = cva(
  'inline-flex items-center font-medium transition-colors rounded-md px-1.5 py-0.5 text-xs border-transparent',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-secondary',
        secondary: 'bg-secondary text-white',
        warning: 'bg-ata-orange text-secondary',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        tertiary: 'bg-tertiary text-white',
        ghost: 'bg-transparent text-foreground',
        destructive: 'bg-destructive text-white',
        icon: 'gap-1.5',
      },
      size: {
        xs: 'px-1 h-4 text-[10px]',
        sm: 'px-1.5',
        md: 'px-2 py-1',
      },
      background: {
        primary: 'bg-primary color-secondary',
        secondary: 'bg-secondary text-white',
        tertiary: 'bg-tertiary text-white',
        destructive: 'bg-destructive text-white',
        warning: 'bg-ata-orange text-secondary',
      },
    },
    defaultVariants: {
      size: 'sm',
      variant: 'primary',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

function Badge({
  className,
  variant,
  leftIcon,
  rightIcon,
  background,
  size,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size, background }), className)}
      {...props}
    >
      {leftIcon && <>{leftIcon}</>}
      {props.children}
      {rightIcon && <>{rightIcon}</>}
    </div>
  );
}

export default Badge;
export { badgeVariants };

