import React, { useEffect } from 'react';
// components
import GotoChromeBackdrop from './GotoChromeBackdrop';
// style
import AtarimChrome from 'media/svgs/atarim-chrome-icon.svg';
import { Button } from 'ui';
// Utils
import { useSessionStorage } from '@src/hooks/use-storage/use-session-storage';
import { FIXME } from '@src/types';
import { AnimatePresence, motion } from 'framer-motion';
import { X } from 'lucide-react';
import {
  isChromeBrowser,
  isChromeExtensionInstalled,
} from 'utils/chromeExtension';
import { cn } from 'utils/cn';
import useInviteChecklist from '../hooks/use-invite-team-checklist';

const GotoChromeButtonV2 = (props: FIXME) => {
  const { menuCollapse } = props;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [extState, setExtState] = React.useState(false);
  const extInstalled = isChromeExtensionInstalled();
  const chromeBrowser = isChromeBrowser();
  const { markItemAsDone } = useInviteChecklist();
  // Threshold for small screens, e.g., less than 1200px
  const smallScreenThreshold = 1200;
  let features;
  useEffect(() => {
    if (!extInstalled && chromeBrowser) {
      setExtState(true);
    }
  }, [extInstalled, chromeBrowser]);

  if (window.innerWidth < smallScreenThreshold) {
    // For smaller screens, open the window in full screen or set minimum dimensions
    features = 'top=0,left=0,width=100,height=100';
  } else {
    // For larger screens, calculate dimensions as before
    let widthVW = 50; // 50% of the viewport width
    let heightVH = 50; // 50% of the viewport height
    let widthInPixels = window.innerWidth * (widthVW / 100);
    let heightInPixels = window.innerHeight * (heightVH / 100);
    let left = (window.innerWidth - widthInPixels) / 2;
    let top = 25;

    features = `width=${widthInPixels},height=${heightInPixels},top=${top},left=${left}`;
  }

  const [value, setValue] = useSessionStorage({
    key: 'chrome-extension-dismiss',
    defaultValue: 'show',
  });

  const toggleValue = () => {
    setValue((current: 'show' | 'hide') => (current === 'show' ? 'hide' : 'show'));
  };

  useEffect(() => {
    if (!extInstalled) return;
    markItemAsDone('chromeextensioninstalled', false);
  }, [extInstalled, markItemAsDone]);

  const handleOpenDownloadLink = () => {
    setOpenBackdrop(!openBackdrop);
    window.open(
      'https://chromewebstore.google.com/detail/atarim-visual-website-col/ekpmbeilnaahlccpgjchjjoonecikehf',
      '_blank',
      features
    );
  };
  return (
    <><AnimatePresence>
      {extState && value === 'show' && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0, marginTop: 8 }}
          exit={{
            opacity: 0,
            height: 0,
            marginTop: 0,
            transition: {
              duration: 0.15,
              type: 'tween',
              delay: 0
            }
          }}
          transition={{
            delay: 0.05,
            duration: 0.25,
            type: 'spring',
            stiffness: 240,
            damping: 15,
          }}
          className={cn(
            'text-secondary bg-gradient-purple relative flex w-full flex-col rounded-xl p-3 font-medium',
            menuCollapse ? 'collapsed' : ''
          )}
        >
          {!menuCollapse && (
            <div
              className={cn(
                'mb-2 flex flex-col justify-center whitespace-nowrap text-center leading-tight text-white',
                menuCollapse ? 'collapsed' : ''
              )}
            >
              <span className="text-md">Instantly Collaborate on</span>
              <span className="text-md">
                <span className="font-bold italic">ANY</span> Website using
                Chrome
              </span>
            </div>
          )}
          <Button
            className={cn(
              'flex w-full gap-2 text-sm',
              menuCollapse ? 'collapsed' : ''
            )}
            onClick={handleOpenDownloadLink}
            variant="outline"
            size="sm"
          >
            <img
              alt="atarim chrome icon "
              src={AtarimChrome}
              className={cn('h-4 w-4', menuCollapse && '', 'mx-0')}
            />
            {!menuCollapse && <span>Install The Extension</span>}
          </Button>
          <Button
            tooltip="Dismiss"
            toolTipPortal
            variant="ghost"
            className="absolute right-2 top-2 mx-auto text-white after:bg-white hover:text-white"
            size="link"
            onClick={toggleValue}
          >
            <X size={16} />
          </Button>
          {openBackdrop && (
            <GotoChromeBackdrop
              open={openBackdrop}
              close={() => setOpenBackdrop(false)}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
    </>
  );
};

export default GotoChromeButtonV2;
