import { useAppDispatch, useAppSelector } from '@src/hooks/redux';
import { useModal } from '@src/providers/modal-provider';
import {
  LoggedTimeEntry,
  usePlayTimeEntryMutation,
  useUpdateTimeEntryMutation,
} from '@src/store/api/endpoints/time-entries';
import {
  startTimer,
  stopTimer,
  Timer,
  updateElapsedTime,
} from '@src/store/slices/time-entries';
import { ClockArrowUp, Pause, Play } from 'lucide-react';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { Button } from 'ui';
import { cn } from 'utils/cn';
import { calculateElapsedTime } from 'utils/date';

type TimerItemProps = {
  entry: LoggedTimeEntry;
  isSelected: boolean;
  onSelect: () => void;
};

export default function TimerItem({
  entry,
  isSelected,
  onSelect,
}: TimerItemProps) {
  const logTimerModal = useModal('log_time');
  const dispatch = useAppDispatch();
  const timers = useAppSelector((state) => state.time_entries_new.timers);
  const [updateTimeEntry, { isLoading: isUpdatingTime }] =
    useUpdateTimeEntryMutation();

  const [playTimeEntry, { isLoading: isPlayingTime }] =
    usePlayTimeEntryMutation();

  useEffect(() => {
    const existingTimer = timers.find(
      (timer) => timer.taskId === entry.task_id
    );

    if (!existingTimer || !existingTimer.timeId) {
      dispatch(
        startTimer({
          taskId: entry.task_id,
          initialElapsedTime: entry?.running ? calculateElapsedTime(entry?.started_at ?? '', entry?.spent_time) : parseInt(entry?.spent_time),
          isRunning: entry.running === 1,
          timeId: entry.id,
        })
      );
    }
  }, [entry, dispatch, timers]);

  useEffect(() => {
    const interval = setInterval(() => {
      const timer = timers.find((t) => t.taskId === entry.task_id);
      if (timer?.isRunning && timer?.timeId) {
        dispatch(updateElapsedTime(timer.timeId));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timers, dispatch, entry.task_id]);

  const handleStopTimer = async (currentTimer: Timer) => {
    if (currentTimer?.isRunning && currentTimer?.timeId) {
      dispatch(stopTimer(currentTimer.timeId));

      await updateTimeEntry({
        task_id: entry.task_id,
        user_id: entry.user_id,
        billable: 0,
        id: entry.id,
        running: false,
        logged: !!entry.logged,
        spent_time: currentTimer.elapsedTime || 0,
        start_time: entry.start_time,
        started_at_spent_time: false,
      }).catch((error) => {
        toast.error('Failed to update time entry');
        console.error('Failed to update time entry:', error);
      });
    }
  };

  const handleStartTimer = async (currentTimer: Timer | undefined) => {
    dispatch(
      startTimer({
        taskId: entry.task_id,
        initialElapsedTime:
          currentTimer?.elapsedTime || parseInt(entry.spent_time),
        isRunning: true,
        timeId: entry.id,
      })
    );

    await playTimeEntry({
      task_id: entry.task_id,
      user_id: entry.user_id,
      billable: 0,
      id: entry.id,
      running: true,
      logged: !!entry.logged,

      spent_time: currentTimer?.elapsedTime || 0,
      start_time: entry.start_time,
      started_at_spent_time: false,
    }).catch((error) => {
      toast.error('Failed to update time entry');
      console.error('Failed to update time entry:', error);
    });
  };

  const handleToggleTimer = async () => {
    const currentTimer = timers.find((timer) => timer.taskId === entry.task_id);

    if (currentTimer?.isRunning) {
      await handleStopTimer(currentTimer);
    } else {
      await handleStartTimer(currentTimer);
    }
  };

  const removeTags = (str: string) => {
    if (str === null || str === '') return false;
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const currentTimer = timers.find((timer) => timer.taskId === entry.task_id);
  const elapsedTime = currentTimer?.elapsedTime || parseInt(entry.spent_time);
  const hours = Math.floor(elapsedTime / 3600);
  const minutes = Math.floor((elapsedTime % 3600) / 60);
  const seconds = Math.floor(elapsedTime % 60);

  const padZero = (num: number) => num.toString().padStart(2, '0');

  const formattedElapsedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 rounded-md border border-[#EFF2F6] p-1.5',
        isSelected ? 'bg-accent' : ''
      )}
    >
      <div
        onClick={onSelect}
        className="flex min-w-[170px] max-w-[170px] flex-col justify-around overflow-hidden text-ellipsis whitespace-nowrap hover:cursor-pointer"
      >
        <span className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-[#272D3C]">
          {removeTags(entry.task_name)}
        </span>

        <div className="text-secondary flex items-center">
          <div className="at-cs-timer-logged-title">Total Time Logged:</div>
          <div className="text-sm font-bold">{formattedElapsedTime}</div>
        </div>
      </div>
      <div className="text-secondary flex justify-between gap-2">
        {currentTimer?.isRunning ? (
          <Button
            variant="outline"
            size="icon"
            tooltip="Pause timer"
            loading={isUpdatingTime}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleTimer();
            }}
          >
            <Pause />
          </Button>
        ) : (
          <Button
            variant="outline"
            size="icon"
            loading={isUpdatingTime}
            tooltip="Start timer"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleToggleTimer();
            }}
          >
            <Play />
          </Button>
        )}
        <Button
          size="icon"
          tooltip="Log Time"
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (entry.running && currentTimer) {
              await handleStopTimer(currentTimer);
            }
            logTimerModal.open({
              props: { timerId: entry.id },
            });
          }}
        >
          <ClockArrowUp />
        </Button>
      </div>
    </div>
  );
}
