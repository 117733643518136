import { skipToken } from '@reduxjs/toolkit/query';
import { useAppSelector } from '@src/hooks/redux';
import useDebounce from '@src/hooks/useDebouncedValue';
import {
  LoggedTimeEntry,
  UpdateTimeEntryRequest,
  useDeleteTimeEntryMutation,
  useGetNotLoggedEntriesQuery,
  useUpdateTimeEntryMutation,
} from '@src/store/api/endpoints/time-entries';
import { AnimatePresence, motion } from 'framer-motion';
import { MoveRight, Save, Trash2 } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { Button, Checkbox, DropdownMenu, Label, Textarea } from 'ui';
import { DataStateOverlay } from 'ui/components/DataLoadOverlay';
import TimerItem from './TimerItem';

export default function TimerCollection() {
  const [selectedTimerId, setSelectedTimerId] = useState<number | null>(null);
  const { selectedWorkspace } = useAppSelector((state) => state.workspace);
  const { data: notLoggedEntries, isLoading } = useGetNotLoggedEntriesQuery(
    selectedWorkspace?.id ?? skipToken
  );
  const timeEntry = notLoggedEntries?.find(
    (entry) => entry.id === selectedTimerId
  );

  return (
    <div className="relative flex w-full flex-col gap-2 text-secondary">
      <DataStateOverlay
        className="flex items-center justify-center"
        size={16}
        visible={isLoading}
        text="Loading time entries..."
      />
      <div className="flex w-full items-center gap-1 text-sm font-medium">
        Active Timers
        <span className="bg-tertiary rounded-lg px-1.5 py-1 text-xs font-medium leading-none text-white">
          {notLoggedEntries?.length}
        </span>
      </div>
      <DropdownMenu.Group className="flex max-h-[200px] flex-col gap-2 overflow-y-auto">
        {notLoggedEntries?.map((entry) => (
          <TimerItem
            key={entry.id}
            entry={entry}
            isSelected={entry.id === selectedTimerId}
            onSelect={() =>
              setSelectedTimerId(entry.id === selectedTimerId ? null : entry.id)
            }
          />
        ))}
      </DropdownMenu.Group>
      <AnimatePresence>
        {timeEntry && <TimerOptions timeEntry={timeEntry} />}
      </AnimatePresence>

      <DropdownMenu.Item asChild>
        <Link to="/time-entries" className='focus:bg-white !p-0 m-0'>
          <Button size="sm" className='text-sm w-full hover:bg-accent active:bg-accent focus:bg-accent' variant="outline" rightIcon={<MoveRight size={18} />} >
            View All Time Entries
          </Button>
        </Link>
      </DropdownMenu.Item>
    </div>
  );
}

function TimerOptions({ timeEntry }: { timeEntry: LoggedTimeEntry }) {
  const [description, setDescription] = useState<string>(timeEntry.description);
  const [billable, setBillable] = useState(!!timeEntry.billable);
  const debouncedDescription = useDebounce(description, 1000);
  const [deleteTimeEntry, { isLoading: isDeleting }] =
    useDeleteTimeEntryMutation();
  const [updateTimeEntry, { isLoading: isUpdatingTime }] =
    useUpdateTimeEntryMutation();

  const updateTimeEntryData = useCallback(
    (updatedData: Partial<UpdateTimeEntryRequest>) => {
      return {
        id: timeEntry.id,
        task_id: timeEntry.task_id,
        user_id: timeEntry.user_id,
        running: !!timeEntry.running,
        start_time: timeEntry.start_time,
        spent_time: timeEntry.spent_time,
        logged: !!timeEntry.logged,
        started_at_spent_time: false,
        description,
        billable: billable ? 1 : 0,
        ...updatedData,
      } as UpdateTimeEntryRequest;
    },
    [timeEntry]
  );

  useEffect(() => {
    if (debouncedDescription !== timeEntry.description && updateTimeEntry) {
      updateTimeEntry(
        updateTimeEntryData({ description: debouncedDescription })
      );
    }
  }, [
    debouncedDescription,
    timeEntry.description,
    updateTimeEntry,
    updateTimeEntryData,
  ]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteTimeEntry(timeEntry.id.toString());
      toast.success('Time entry deleted successfully');
    } catch (error) {
      toast.error('Failed to delete time entry');
    }
  }, [deleteTimeEntry, timeEntry.id]);

  const updateBillable = useCallback(
    async (value: boolean) => {
      await updateTimeEntry(updateTimeEntryData({ billable: value ? 1 : 0 }));
    },
    [updateTimeEntry, updateTimeEntryData]
  );

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
    },
    []
  );

  const handleBillableChange = useCallback(
    (checked: boolean) => {
      setBillable(checked);
      updateBillable(checked);
    },
    [updateBillable]
  );

  return (

    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.2 }}
      className="flex flex-col gap-2">
      <div className="relative">
        <Textarea
          onChange={handleDescriptionChange}
          value={description}
          placeholder="Describe what you are working on..."
          className="resize-none"
        />
        {isUpdatingTime && (
          <span className="absolute bottom-1 right-2 flex flex-row gap-1 rounded-sm bg-white p-1 text-xs font-normal text-gray-500">
            <Save size={14} /> Saving
          </span>
        )}
      </div>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Label htmlFor="billable" className="text-sm font-medium">
            Billable
          </Label>
          <Checkbox
            id="billable"
            checked={billable}
            color="primary"
            onCheckedChange={handleBillableChange}
          />
        </div>
        <Button
          loading={isDeleting}
          variant="destructive"
          size="sm"
          onClick={handleDelete}
        >
          <Trash2 size={16} />
          Delete
        </Button>
      </div>
    </motion.div>

  );
}
