import { skipToken } from '@reduxjs/toolkit/query';
import { useFetchTaskAttributesQuery } from '@src/store/api/endpoints/task';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isChromeExtensionInstalled } from 'utils/chromeExtension';

interface UseChecklistInjectionProps {
  checklistId: number;
  isChecklistVisible: boolean;
  api: any;
}

const TASK_PATHS = ['/tasks', '/board'];

const useChecklistInjection = ({
  checklistId,
  isChecklistVisible,
  api,
}: UseChecklistInjectionProps) => {
  const checklistRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<MutationObserver | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [progress, setProgress] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const taskId = searchParams.get('taskid') ?? undefined;
  const {
    currentData: attributes,
    isLoading,
    isFetching,
    isError,
    error,
  } = useFetchTaskAttributesQuery(taskId ?? skipToken);

  const extInstalled = isChromeExtensionInstalled();

  // Separate effect for progress tracking
  useEffect(() => {
    if (!api) return;
    const fetchChecklistProgress = async () => {
      try {
        const checklists = api.checklists.getChecklists();
        const checklist = checklists.find((c: any) => c.id === checklistId);

        if (checklist && checklist.items) {
          const totalSteps = checklist.items.length;
          const completedSteps = checklist.items.filter(
            (item: any) => item.state === 'done'
          ).length;

          const progressPercentage = totalSteps
            ? Math.round((completedSteps / totalSteps) * 100)
            : 0;

          setProgress(progressPercentage);
        }
      } catch (err) {
        console.error('Error fetching checklist progress:', err);
      }
    };

    // Set up progress tracking regardless of visibility
    const itemCompletedListener = (id: string, internalId: string) => {
      fetchChecklistProgress();
    };

    api.checklists.listen('item-completed', itemCompletedListener);

    // Initial progress fetch
    fetchChecklistProgress();

    // Set up interval for progress updates
    progressIntervalRef.current = setInterval(() => {
      fetchChecklistProgress();
    }, 1000);

    return () => {
      // No need for removeListener - the listener will be automatically cleaned up
      // when the component unmounts according to ProductFruits docs
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [api, checklistId]);

  const markItemAsDone = async (itemId: string, expand: boolean) => {
    if (!api) return;
    try {
      await api.checklists.markItemAsDone(itemId, expand);
    } catch (error) {
      console.error('Error marking item as done:', error);
    }
  };

  const isItemDone = (itemId: string) => {
    if (!api) return;
    try {
      const checklists = api.checklists.getChecklists();
      const checklist = checklists.find((c: any) => c.id === checklistId);

      if (checklist && checklist.items) {
        const item = checklist.items.find(
          (item: any) => item?.internalId === itemId
        );
        return item?.state === 'done' || false;
      }
      return false;
    } catch (error) {
      console.error('Error checking if item is done:', error);
      return false;
    }
  };

  // Separate effect for checklist injection
  useEffect(() => {
    if (!api || !isChecklistVisible) return;

    const injectChecklist = () => {
      if (checklistRef.current && api?.checklists?.injectToElement) {
        try {
          api.checklists.injectToElement(checklistId, checklistRef.current, {});
        } catch (error) {
          console.error('Error injecting checklist:', error);
        }
      }
    };

    const setupObserver = () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      observerRef.current = new MutationObserver((_, observer) => {
        const element = document.getElementById('pf-checklist');
        if (element) {
          injectChecklist();
          observer.disconnect();
        }
      });

      observerRef.current.observe(document.body, {
        childList: true,
        subtree: true,
      });
    };

    const element = document.getElementById('pf-checklist');
    if (element) {
      injectChecklist();
    } else {
      setupObserver();
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [checklistId, isChecklistVisible, api]);

  useEffect(() => {
    if (location.pathname === '/collaborate') {
      markItemAsDone('accessfirstproject', false);
    } else if (
      TASK_PATHS.includes(location.pathname) &&
      attributes?.task_status.some(
        (s) => s.value === 'complete' && s.selected
      ) &&
      !isItemDone('completetask')
    ) {
      console.log(isItemDone('completetask'));
      markItemAsDone('completetask', false);
    } else if (location.search.includes('workspace')) {
      markItemAsDone('workspacesettings', false);
    }
  }, [location.pathname, attributes, location.search]);

  // Separate effect for chrome extension
  useEffect(() => {
    if (!extInstalled || !api) return;
    markItemAsDone('chromeextensioninstalled', false);
  }, [extInstalled]);

  return { checklistRef, progress, markItemAsDone };
};

export default useChecklistInjection;
